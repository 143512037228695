export class ClipboardUtils {
    static async copy(text: string): Promise<void> {
        await navigator.clipboard.writeText(text);
    }

    /**
     * カスタムフォーマットをサポートしているブラウザでは、カスタムフォーマットを使用してクリップボードにコピーします。そうでないブラウザでは、JSONをテキストとしてコピーします。
     */
    static async copyWithCustomFormat(params: { text?: string; json: string }): Promise<void> {
        const isSupportCustomFormat = ClipboardItem.supports?.('web text/plain') ?? false; // SafariではClipboardItem.supportsメソッド自体が無い

        const clipboardItem = isSupportCustomFormat
            ? new ClipboardItem({
                  'web text/plain': new Blob([params.json], { type: 'text/plain' }),
                  ...(params.text ? { 'text/plain': new Blob([params.text], { type: 'text/plain' }) } : {}),
              })
            : new ClipboardItem({
                  'text/plain': new Blob([params.json], { type: 'text/plain' }),
              });

        await navigator.clipboard.write([clipboardItem]);
    }

    /**
     * カスタムフォーマットのMIMEタイプを使用してクリップボードからJSONを読み込みます。出来ない場合は通常のテキストを読み込みます。
     */
    static async readCustomFormatJSON(): Promise<string> {
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
            if (clipboardItem.types.includes('web text/plain')) {
                return (await clipboardItem.getType('web text/plain')).text();
            }
        }

        // カスタムフォーマットのデータが無かったり、カスタムフォーマットがサポートされていないブラウザでは、通常のテキストを読み込む
        return await navigator.clipboard.readText();
    }
}
