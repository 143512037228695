import { useEffect, useState } from 'react';
import { StickyZoneStyle } from '../../domain';
import { StickyZoneMenuContent } from './StickyZoneMenuContent';
import { StickyZoneMenuId } from './StickyZoneMenuId';
import { Point } from '@view-model/models/common/basic';
import { MenuContainer } from '@model-framework/menu';
import { MenuSize } from '@view-model/models/sticky/ui';
import { ThemeColor } from '@view-model/models/common/color';
import { FontSize } from '@model-framework/text';
import { GroupId } from '@schema-common/base';
import { useGroupAnalysisSettingAvailability } from '@group/hooks/useGroupAnalysisSettingAvailability';

type Props = {
    groupId: GroupId;
    currentStyle: StickyZoneStyle;
    offset: { x: number; y: number };
    textEditing: boolean;
    url: string | null;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onToggleZoneDescription(): void;
    onDeleteZone(): void;
    onGroupSelectedZone(): void;
    onSaveHyperLink(url: string | null): void;
    onZoneToView(): void;
    onAnalysisSelected(): void;
};

export const StickyZoneMenu: React.FC<Props> = ({
    groupId,
    currentStyle,
    offset,
    textEditing,
    url,
    onFontSizeSelected,
    onThemeColorSelected,
    onToggleZoneDescription,
    onDeleteZone,
    onGroupSelectedZone,
    onSaveHyperLink,
    onZoneToView,
    onAnalysisSelected,
}: Props) => {
    const [openedMenu, setOpenedMenu] = useState<StickyZoneMenuId | null>(null);

    useEffect(() => {
        if (textEditing) {
            setOpenedMenu(null);
        }
    }, [textEditing]);

    // 事前にフィルタしておかないとブランクのメニューができてしまうのでここでフィルタする
    const showAnalysisSelect = useGroupAnalysisSettingAvailability(groupId);
    const availableMenuIds = Object.values(StickyZoneMenuId).filter(
        (menuId) => showAnalysisSelect || menuId !== StickyZoneMenuId.Analysis
    );

    const menuSize = { width: MenuSize * availableMenuIds.length, height: MenuSize };
    const position = new Point(offset.x, offset.y);

    return (
        <MenuContainer position={position} size={menuSize} positionOrigin="left-bottom">
            {availableMenuIds.map((menuId, i) => (
                <g key={menuId} transform={`translate(${MenuSize * i}, 0)`}>
                    <StickyZoneMenuContent
                        menuId={menuId}
                        menuSize={MenuSize}
                        currentStyle={currentStyle}
                        isOpen={openedMenu === menuId}
                        url={url}
                        onMenuOpen={(menuId) => setOpenedMenu(menuId)}
                        onMenuClose={() => setOpenedMenu(null)}
                        onFontSizeSelected={onFontSizeSelected}
                        onToggleZoneDescription={onToggleZoneDescription}
                        onThemeColorSelected={onThemeColorSelected}
                        onSaveHyperLink={onSaveHyperLink}
                        onDeleteZone={onDeleteZone}
                        onGroupSelectedZone={onGroupSelectedZone}
                        onZoneToView={onZoneToView}
                        onAnalysisSelected={onAnalysisSelected}
                    />
                </g>
            ))}
        </MenuContainer>
    );
};
