import { Random } from '@framework/Random';
import { Timestamp } from '@framework/Timestamp';
import { GroupJSON, GroupJSONOnWrite } from '@schema-app/group/entities/{groupId}/GroupJSON';
import { GroupName } from './GroupName';
import { GroupSetting } from './GroupSetting';
import { GroupId } from '@schema-common/base';
export class GroupEntity {
    constructor(
        public readonly id: GroupId,
        public readonly name: GroupName,
        public readonly createdAt: Timestamp,
        public readonly updatedAt: Timestamp,
        public readonly setting: GroupSetting,
        public readonly imageUrl: string | null,
        public readonly trashedAt: Timestamp | null,
        public readonly trashedBy: string | null
    ) {}

    dump(): GroupJSONOnWrite {
        const { id, name, createdAt, updatedAt, setting, imageUrl, trashedAt, trashedBy } = this;
        return {
            id,
            name: name.dump(),
            createdAt: createdAt.toUnixTimestamp(),
            updatedAt: updatedAt.toUnixTimestamp(),
            setting: setting.dump(),
            imageUrl: imageUrl,
            trashedAt: trashedAt ? trashedAt.toUnixTimestamp() : null,
            trashedBy: trashedBy,
        };
    }

    static load(dump: GroupJSON): GroupEntity {
        return new this(
            dump.id,
            GroupName.load(dump.name),
            new Timestamp(dump.createdAt),
            new Timestamp(dump.updatedAt),
            GroupSetting.load(dump.setting),
            dump.imageUrl || null,
            dump.trashedAt ? new Timestamp(dump.trashedAt) : null,
            dump.trashedBy || null
        );
    }

    static buildNew(name: string, createdAt: Timestamp): GroupEntity {
        const id = Random.generateRandomID(15);
        const groupName = new GroupName(name);

        return new this(id, groupName, createdAt, createdAt, GroupSetting.buildNew(), null, null, null);
    }

    compareTo(other: GroupEntity): number {
        return this.name.compareTo(other.name);
    }

    static compare(a: GroupEntity, b: GroupEntity): number {
        return a.compareTo(b);
    }

    static compareById(a: GroupEntity, b: GroupEntity): number {
        return a.id.localeCompare(b.id);
    }
}

export const isGroupEntity = (value: unknown): value is GroupEntity => value instanceof GroupEntity;
