import { LinkColor, LinkLineStyle, LinkMarkStyle } from '@model-framework/link';
import { FontSize } from '@model-framework/text';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { ThemeColor } from '@view-model/models/common/color';
import { MenuSize } from '@view-model/models/sticky/ui';
import { useState } from 'react';
import { MultiSelectionMenuId } from './MultiSelectionMenuId';
import { MultiSelectionMenuItem } from './MultiSelectionMenuItem';
import { MenuContainer } from '@model-framework/menu';
import { AlignType } from './components';
import { useIsDebugMode } from '../../../../../framework/utils';

type MenuGroup = MultiSelectionMenuId[];

/**
 * 選択状態に応じて複数選択メニューのアイテムを決める
 */
const selectMenuGroups = ({
    isNodeSelected,
    isMultipleNodeSelected,
    isLinkSelected,
    isZoneSelected,
    isTwoZonesSelected,
}: {
    isNodeSelected: boolean;
    isMultipleNodeSelected: boolean;
    isLinkSelected: boolean;
    isZoneSelected: boolean;
    isTwoZonesSelected: boolean;
}): MenuGroup[] => {
    const {
        FontSize,
        ThemeColor,
        GroupStickyElements,
        LinkLineStyle,
        LinkMarkStyle,
        ReverseLink,
        LinkColor,
        RemoveMultiSelectedLinks,
        HorizontalAlignMenu,
        VerticalAlignMenu,
        ZoneComparisonMenu,
    } = MultiSelectionMenuId;

    return [
        // 複数選択メニューグループ1（付箋・ゾーン系）
        isNodeSelected || isZoneSelected ? [FontSize, ThemeColor, GroupStickyElements] : [],
        // 複数選択メニューグループ2（付箋複数選択系）
        isMultipleNodeSelected && !isZoneSelected ? [HorizontalAlignMenu, VerticalAlignMenu] : [],
        // 複数選択メニューグループ3（ゾーン比較系）
        isTwoZonesSelected ? [ZoneComparisonMenu] : [],
        // 複数選択メニューグループ4（リンク系）
        isLinkSelected ? [LinkLineStyle, LinkMarkStyle, ReverseLink, LinkColor, RemoveMultiSelectedLinks] : [],
    ];
};

type MenuPosition = {
    menuId: MultiSelectionMenuId;
    position: Point;
};

type MenuLayout = {
    menuPositions: MenuPosition[]; // 各メニューアイテムのポジション
    width: number; // メニュー全体の幅
};

type BorderRectLayout = {
    position: Point; // メニューの枠線の位置
    borderRectWidth: number; // メニューの枠線の幅
};

/**
 * メニューの位置を決める。
 * グループ同士の間はマージンをあける。
 * @param menuGroups
 */
const layoutMenu = (menuGroups: MenuGroup[]): MenuLayout => {
    return menuGroups.reduce(
        ({ menuPositions, width }, group) => {
            // 空のグループは無視
            if (group.length == 0)
                return {
                    menuPositions,
                    width,
                };

            // 最初のグループはマージンなし
            const margin = width == 0 ? 0 : MenuSize * 0.5;

            group.forEach((menuId, i) => {
                menuPositions.push({ menuId, position: new Point(width + i * MenuSize + margin, 0) });
            });

            return {
                menuPositions,
                width: width + group.length * MenuSize + margin,
            };
        },
        {
            menuPositions: [],
            width: 0,
        } as MenuLayout
    );
};

/**
 * メニューグループの枠線の大きさと位置を決める。
 * グループ同士の間はマージンをあける。
 * @param menuGroups
 */
const getBorderLayouts = (menuGroups: MenuGroup[]): BorderRectLayout[] => {
    const borderRectLayouts = menuGroups
        .map((group, index) => {
            const rectWidth = MenuSize * group.length;
            // 2つ目以降のグループは前のグループの幅とマージンの分だけずらす
            // ただし、現在のindexのグループより前の各グループの長さが全て0の時(リンクのみ選択時など)は、マージン分はずらすことはしない
            const positionX =
                index == 0 || menuGroups.slice(0, index).every((grp) => grp.length <= 0)
                    ? 0
                    : // 現在のindexのグループより前の各グループに含まれるメニューの総数 * MenuSizeと、
                      // 現在のindexより前のグループのうちメニューを１つ以上あるグループの数 * MenuSize/2だけずらしたX座標
                      MenuSize *
                          menuGroups.slice(0, index).reduce((sum, grp) => {
                              return sum + grp.length;
                          }, 0) +
                      MenuSize * 0.5 * menuGroups.slice(0, index).filter((grp) => grp.length > 0).length;
            return { borderRectWidth: rectWidth, position: new Point(positionX, 0) };
        })
        .filter(({ borderRectWidth }) => borderRectWidth > 0);
    return borderRectLayouts;
};

type Props = {
    selectionRect: Rect;
    isNodeSelected: boolean;
    isMultipleNodeSelected: boolean;
    isLinkSelected: boolean;
    isZoneSelected: boolean;
    isTwoZonesSelected: boolean;
    currentFontSize?: FontSize;
    currentThemeColor?: ThemeColor;
    currentLinkLineStyle?: LinkLineStyle;
    currentLinkMarkStyle?: LinkMarkStyle;
    currentLinkColor?: LinkColor;
    onFontSizeSelected(fontSize: FontSize): void;
    onThemeColorSelected(themeColor: ThemeColor): void;
    onGroupSelectedElements(): void;
    onLinkLineStyleSelected(lineStyle: LinkLineStyle): void;
    onLinkMarkStyleSelected(markStyle: LinkMarkStyle): void;
    onReverseLinks(): void;
    onRemoveLinks(): void;
    onLinkColorSelected(color: LinkColor): void;
    onAlignSelected(alignType: AlignType, interval: number): void;
    onTwoZonesComparisonSelected(): void;
};

export const MultiSelectionMenu: React.FC<Props> = ({
    selectionRect,
    isNodeSelected,
    isMultipleNodeSelected,
    isLinkSelected,
    isZoneSelected,
    isTwoZonesSelected,
    currentFontSize,
    currentThemeColor,
    currentLinkLineStyle,
    currentLinkMarkStyle,
    currentLinkColor,
    onFontSizeSelected,
    onThemeColorSelected,
    onGroupSelectedElements,
    onLinkLineStyleSelected,
    onLinkMarkStyleSelected,
    onReverseLinks,
    onRemoveLinks,
    onLinkColorSelected,
    onAlignSelected,
    onTwoZonesComparisonSelected,
}: Props) => {
    const [openedMenuId, setOpenedMenuId] = useState<MultiSelectionMenuId | null>(null);

    const isDebugMode = useIsDebugMode('ai_verification');

    const menuGroups = selectMenuGroups({
        isNodeSelected,
        isMultipleNodeSelected,
        isLinkSelected,
        isZoneSelected,
        isTwoZonesSelected: isDebugMode && isTwoZonesSelected,
    });
    if (menuGroups.length === 0) return null;

    const { menuPositions, width } = layoutMenu(menuGroups);
    const borderLayouts = getBorderLayouts(menuGroups);

    const position = selectionRect.topCenter();
    const menuSize = new Size(width, MenuSize);

    return (
        <MenuContainer position={position} size={menuSize} positionOrigin={'center-bottom'} withoutFrameRect>
            {borderLayouts.map(({ position, borderRectWidth }) => {
                return (
                    <rect
                        transform={position.toSVGTranslate()}
                        key={position.x}
                        width={borderRectWidth}
                        height={MenuSize}
                        rx={2}
                        ry={2}
                        fill="white"
                        stroke="#e0e0e0"
                        strokeWidth={4}
                    />
                );
            })}

            {menuPositions.map(({ menuId, position }) => {
                return (
                    <g key={menuId} transform={position.toSVGTranslate()}>
                        <MultiSelectionMenuItem
                            menuId={menuId}
                            isOpen={openedMenuId === menuId}
                            currentFontSize={currentFontSize}
                            currentThemeColor={currentThemeColor}
                            currentLinkLineStyle={currentLinkLineStyle}
                            currentLinkMarkStyle={currentLinkMarkStyle}
                            currentLinkColor={currentLinkColor}
                            onMenuOpen={() => setOpenedMenuId(menuId)}
                            onMenuClose={() => setOpenedMenuId(null)}
                            onFontSizeSelected={onFontSizeSelected}
                            onThemeColorSelected={onThemeColorSelected}
                            onGroupSelectedNodes={onGroupSelectedElements}
                            onLinkLineStyleSelected={onLinkLineStyleSelected}
                            onLinkMarkStyleSelected={onLinkMarkStyleSelected}
                            onReverseLinks={onReverseLinks}
                            onRemoveLinks={onRemoveLinks}
                            onLinkColorSelected={onLinkColorSelected}
                            onAlignSelected={onAlignSelected}
                            onTwoZonesComparisonSelected={onTwoZonesComparisonSelected}
                            isZoneOnly={isZoneSelected && !isNodeSelected}
                        />
                    </g>
                );
            })}
        </MenuContainer>
    );
};
