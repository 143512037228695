import { useCallback, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { useCurrentUserEntity } from '@user/UserEntity';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';
import { useHistory } from 'react-router-dom';
import { UserPagePathBuilder } from '../UserPagePathBuilder';
import { GroupId, WorkspaceId } from '@schema-common/base';
import { Loading } from '@framework/ui/atoms';
import { createOnboardingViewModel } from '../GroupMemberInvitationPage/AuthenticatedMenu/createOnboardingViewModel';
import { UserKey } from '@user/domain';
import { useActionLogSender } from '@framework/action-log';
import { useCurrentSessionId } from '@framework/auth/AuthContext';
import { GroupIpRestrictionCheckRequest } from '@group/AccessControl';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';

type Props = {
    groupCount: number;
    handleBackToGroupSelect: () => void;
};

export const CreateGroup: React.FC<Props> = ({ groupCount, handleBackToGroupSelect }: Props) => {
    const [loading, setLoading] = useState(false);
    const [groupName, setGroupName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const history = useHistory();
    const userEntity = useCurrentUserEntity();
    const actionLogSender = useActionLogSender();

    const currentSessionId = useCurrentSessionId();

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupName(event.target.value);
    }, []);

    const handleSubmit = useCallback(async () => {
        // グループ作成実行
        if (!userEntity) {
            setErrorMessage('ユーザ情報取得に失敗しました');
            return;
        }

        const onSuccessCreateGroup = async (groupId: GroupId) => {
            // IPアドレスアクセス制御用データが準備できるまで待機
            await GroupIpRestrictionCheckRequest.waitAllow(groupId, currentSessionId);

            const workspaceId = await WorkspaceOperation.createPersonal(groupId, userEntity.id);
            if (workspaceId) {
                onSuccessCreateWorkspace(groupId, workspaceId);
            } else {
                setLoading(false);
                setErrorMessage('ワークスペース作成に失敗しました');
            }
        };

        const onErrorCreateGroup = () => {
            setLoading(false);
            setErrorMessage('グループ作成に失敗しました');
        };

        const onSuccessCreateWorkspace = async (groupId: GroupId, workspaceId: WorkspaceId) => {
            // オンボーディング用ビューモデルを作成する
            const onboardingViewModelId = await createOnboardingViewModel(
                groupId,
                UserKey.buildFromId(userEntity.id),
                workspaceId,
                actionLogSender
            );

            if (onboardingViewModelId) {
                // ビューモデル作成に成功した場合
                history.push(UserPagePathBuilder.viewModelPage(onboardingViewModelId));
            } else {
                history.push(UserPagePathBuilder.groupPage(groupId));
            }
        };

        setLoading(true);

        await FunctionsUserActions.createGroupAndAssignSelfAsAdmin(
            groupName,
            userEntity.mailAddress,
            userEntity.id,
            onSuccessCreateGroup,
            onErrorCreateGroup
        );
    }, [actionLogSender, currentSessionId, groupName, history, userEntity]);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="flex flex-col p-4" style={{ width: '600px' }}>
                        <div className="flex flex-col py-2">
                            <label htmlFor="group-name" className="font-bold">
                                グループ名
                            </label>
                            <input
                                type="text"
                                id="group-name"
                                value={groupName}
                                onChange={handleChange}
                                className="rounded-sm border border-gray-500 px-2 py-1"
                                autoFocus
                            />
                        </div>
                        <div className="py-2">
                            組織名等をご記入ください。トライアル期間として2週間無料で全機能を利用できます。
                        </div>
                        {errorMessage && <div className="py-2 text-red-700">{errorMessage}</div>}
                    </div>
                    <div className={'flex justify-end py-2'}>
                        <div className={'pr-2'}>
                            <Button color="primary" onClick={handleSubmit} loading={loading}>
                                作成する
                            </Button>
                        </div>
                        {groupCount > 0 && (
                            <div className={'pr-2'}>
                                <Button color="secondary" onClick={handleBackToGroupSelect}>
                                    グループ選択に戻る
                                </Button>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
