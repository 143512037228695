import { Rect } from '@view-model/models/common/basic';
import { ReadonlyFrame } from './ReadonlyFrame';
import { ResizableFrame } from './ResizableFrame';
import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';

type Props = {
    readonly: boolean;
    viewRect: Rect;
    onResizeStart(): void;
    onResizeEnd(viewRect: Rect): void;
    getGuardProps: GetGuardProps;
};

export const ViewSelectionFrame: React.FC<Props> = ({
    readonly,
    viewRect,
    onResizeStart,
    onResizeEnd,
    getGuardProps,
}: Props) => {
    return readonly ? (
        <ReadonlyFrame viewRect={viewRect} />
    ) : (
        <ResizableFrame
            viewRect={viewRect}
            onResizeStart={onResizeStart}
            onResizeEnd={onResizeEnd}
            getGuardProps={getGuardProps}
        />
    );
};
