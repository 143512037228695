import { useCallback, useState } from 'react';
import { UserEntity } from '@user/UserEntity';
import { EditUserModal } from '@user/EditUserModal';
import { AdminMenu } from '../AdminMenu';
import { MenuButton } from './MenuButton';
import { MenuLink } from './MenuLink';
import { NameLabel } from './NameLabel';

type Props = {
    userEntity: UserEntity;
    onLogout(): void;
};

/**
 * 通常ユーザ向けのポップアップメニュー
 */
export const RegularUserPopupMenu: React.FC<Props> = ({ userEntity, onLogout }: Props) => {
    // ユーザ編集モーダルの表示制御
    const [showEditUserModal, setEditUserModal] = useState<boolean>(false);
    const handleOpenEditUserModal = useCallback(() => setEditUserModal(true), []);
    const handleCloseEditUserModal = useCallback(() => setEditUserModal(false), []);

    const showAdminMenu = userEntity.isLeviiDomain();

    return (
        <>
            {/* アイコン+名前 */}
            <NameLabel name={userEntity.name} userId={userEntity.id} imageUrl={userEntity.imageUrl} />

            <MenuLink href="https://balus-help.levii.co.jp/" label="ヘルプ | Help" />

            {showAdminMenu && <AdminMenu />}

            <MenuButton onClick={handleOpenEditUserModal}>設定 | Settings</MenuButton>
            <EditUserModal isOpen={showEditUserModal} onClose={handleCloseEditUserModal} userEntity={userEntity} />

            <MenuButton onClick={onLogout}>ログアウト | Log out</MenuButton>
        </>
    );
};
