import { UserKey } from '@user/domain';
import { WorkspaceMemberRole } from './WorkspaceMemberRole';
import { WorkspaceMemberRolesJSON } from '@schema-app/workspace/member-roles/WorkspaceMemberRolesJSON';

type WorkspaceMemberRoleRecord = Record<string, WorkspaceMemberRole>;

export class WorkspaceMemberRoles {
    private readonly memberRoles: Readonly<WorkspaceMemberRoleRecord>;
    constructor(memberRoles: WorkspaceMemberRoleRecord) {
        this.memberRoles = { ...memberRoles };
    }

    dump(): WorkspaceMemberRolesJSON {
        return { ...this.memberRoles };
    }

    static load(dump: WorkspaceMemberRolesJSON | null): WorkspaceMemberRoles {
        return this.fromEntities(Object.entries(dump || {}));
    }

    static fromEntities(entries: [string, string][]): WorkspaceMemberRoles {
        const roles: WorkspaceMemberRoleRecord = {};
        entries.forEach(([key, value]) => {
            roles[key.startsWith('User:') ? key : `User:${key}`] = WorkspaceMemberRole.load(value);
        });
        return new WorkspaceMemberRoles(roles);
    }

    getRoleOf(userId: string): WorkspaceMemberRole | null {
        return this.memberRoles[`User:${userId}`] || null;
    }

    get length(): number {
        return Object.keys(this.memberRoles).length;
    }

    entries(): [UserKey, WorkspaceMemberRole][] {
        return Object.entries(this.memberRoles).map(([key, value]) => [UserKey.load(key), value]);
    }

    userIds(): string[] {
        return Object.keys(this.memberRoles).map((key) => key.replace('User:', ''));
    }

    userIdsByRole(role: WorkspaceMemberRole): string[] {
        const entries = this.entries().filter(([, value]) => value === role);
        return entries.map(([key]) => `${key.id}`);
    }

    hasMembersOf(role: WorkspaceMemberRole): boolean {
        return this.userIdsByRole(role).length > 0;
    }
}
