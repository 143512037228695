import { useCallback, useEffect, useRef, useState } from 'react';
import { useOnEnterCallback } from '@view-model/models/common/hooks/useOnEnterCallback';
import { classNames } from '@framework/utils';

type Props = {
    value: string;
    onBlur(e: React.FocusEvent<HTMLInputElement>): void;
    onClick(): void;
    isEditable: boolean;
    isEditing: boolean;
    isSeconds?: boolean;
};

export const TimeDisplay: React.FC<Props> = ({ value, onBlur, isEditing, isEditable, onClick, isSeconds }: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [showWarning, setShowWarning] = useState(false);
    useOnEnterCallback(inputRef, false);

    // カウントダウンで残り時間を反映するためのuseEffect
    useEffect(() => {
        if (inputRef.current && !inputRef.current.matches(':focus')) {
            inputRef.current.value = value;
        }
    }, [value]);

    // 編集開始時にinputにフォーカスを当て、編集終了時にinputの値を更新するuseEffect
    useEffect(() => {
        if (isEditing) {
            inputRef.current?.focus();
        } else if (inputRef.current) {
            inputRef.current.value = value;
            setShowWarning(false);
        }
    }, [isEditing, value]);

    const validateInput = useCallback(
        (input: string) => {
            const isValidFormat = /^[0-9]{0,2}$/.test(input);
            if (!isValidFormat) return false;

            if (isSeconds && parseInt(input, 10) > 59) return false;

            return true;
        },
        [isSeconds]
    );

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            const newValue = e.currentTarget.value;
            setShowWarning(!validateInput(newValue));
        },
        [validateInput]
    );

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setShowWarning(!validateInput(e.target.value));
        },
        [validateInput]
    );

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;

            if (!validateInput(inputValue)) {
                e.target.value = value;
            }

            onBlur(e);
            setShowWarning(false);
        },
        [value, onBlur, validateInput]
    );

    return (
        <div className="relative flex items-center">
            <input
                ref={inputRef}
                type="text"
                defaultValue={value}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                maxLength={2}
                className={classNames(
                    'box-content w-[2ch] bg-transparent text-center text-3xl font-medium focus:outline-none',
                    showWarning && 'border-2 border-red-500',
                    // 警告表示時にinputの枠線をそのまま追加すると表示が崩れるので、警告表示時はinputの枠線を表示する代わりにマージンを0にする
                    showWarning ? 'mx-0' : 'mx-[2px]'
                )}
                aria-label={isSeconds ? '秒を入力 | Input seconds' : '分を入力 | Input minutes'}
                style={{
                    ...(isEditing ? {} : { display: 'none' }),
                }}
            />
            <div
                className={classNames(
                    'mx-[2px] box-content w-[2ch] rounded-lg bg-transparent text-center text-3xl font-medium focus:outline-none',
                    isEditable && 'hover:bg-gray-200'
                )}
                onClick={onClick}
                role="button"
                aria-label={isSeconds ? '秒を表示 | Display seconds' : '分を表示 | Display minutes'}
                style={{
                    ...(isEditing ? { display: 'none' } : {}),
                }}
            >
                {value}
            </div>

            {isEditing && showWarning && (
                <div
                    className={classNames(
                        'absolute top-14 mt-1 whitespace-nowrap rounded-lg bg-red-200 px-3 py-1 text-xs text-red-600 shadow-md',
                        // 吹き出しのトンガリ部分
                        "before:absolute before:bottom-full before:h-0 before:w-0 before:border-8 before:border-transparent before:border-b-red-200 before:content-['']",
                        isSeconds ? '-right-14 before:right-18' : '-right-24 before:right-28'
                    )}
                >
                    {isSeconds ? '秒は0〜59の半角数字を入力してください' : '分は0〜99の半角数字を入力してください'}
                </div>
            )}
        </div>
    );
};
