import { generateDefaultIcon } from '@framework/utils';
import { UserId, WorkspaceId } from '@schema-common/base';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { UserEntity } from '@user/UserEntity';
import { Workspace, WorkspaceMemberRole, WorkspaceMemberRoles } from '@workspace/domain/workspace';
import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { WorkspaceMemberDeleteConfirmModal } from './WorkspaceMemberDeleteConfirmModal';
import { useWorkspaceMemberRoles } from '@workspace/domain/workspace/useWorkspaceMemberRoles';

const canDeleteMember = (
    currentUserId: UserId | null,
    memberId: UserId,
    workspace: Workspace,
    memberRoles: WorkspaceMemberRoles,
    internalWorkspaceIds: WorkspaceId[]
): boolean => {
    // グループ管理者自身については、ワークスペースから削除できない
    if (currentUserId === memberId) return false;

    // グループ内公開ワークスペース、もしくはパーソナルワークスペースからは削除できない
    if (internalWorkspaceIds.includes(workspace.id) || workspace.isPersonal) return false;

    // 対象となるメンバーがワークスペースの唯一の管理者である場合は削除できない
    if (memberRoles.getRoleOf(memberId) === 'admin' && memberRoles.userIdsByRole(WorkspaceMemberRole.admin).length <= 1)
        return false;

    return true;
};

type Props = {
    workspace: Workspace;
    currentUserId: UserId;
    memberUserEntity: UserEntity;
    internalWorkspaceIds: WorkspaceId[];
};

export const WorkspaceItem: React.FC<Props> = ({
    workspace,
    currentUserId,
    memberUserEntity,
    internalWorkspaceIds,
}: Props) => {
    const [deleting, setDeleting] = useState(false);
    const handleCloseDeleteModal = useCallback(() => {
        setDeleting(false);
    }, []);

    const memberRoles = useWorkspaceMemberRoles(workspace.id);

    if (!memberRoles) return null;

    return (
        <div className="flex items-center border-b-2 py-2 first:border-t-2" key={workspace.id}>
            {workspace.imageUrl ? (
                <img
                    className="mr-2 size-10 overflow-hidden rounded-full"
                    src={workspace.imageUrl}
                    alt={workspace.name.value}
                />
            ) : (
                <div className="mr-2 size-10 overflow-hidden rounded-full">
                    {generateDefaultIcon({
                        id: workspace.id,
                        name: workspace.name.value,
                        size: 40,
                    })}
                </div>
            )}
            <Link
                className="mr-auto font-bold text-brand underline"
                to={UserPagePathBuilder.workspacePage(workspace.id)}
                target="_blank"
                rel="noreferrer"
            >
                {workspace.name.value}
            </Link>

            {canDeleteMember(currentUserId, memberUserEntity.id, workspace, memberRoles, internalWorkspaceIds) && (
                <button
                    className={
                        'mr-2 border border-red-700 p-1 text-sm text-red-700 hover:bg-red-100 focus:outline-none'
                    }
                    onClick={() => setDeleting(true)}
                >
                    ワークスペースメンバーから削除
                </button>
            )}

            {deleting && (
                <WorkspaceMemberDeleteConfirmModal
                    isOpen={deleting}
                    memberData={memberUserEntity}
                    workspace={workspace}
                    onClose={handleCloseDeleteModal}
                />
            )}
        </div>
    );
};
