import { ViewId } from '@schema-common/base';
import { ViewName } from '@view-model/domain/view';
import { Rect } from '@view-model/models/common/basic';
import { BaseTitleBar } from './BaseTitleBar';
import { ThemeColor } from '@view-model/models/common/color';
import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';

type Props = {
    viewRect: Rect;
    name: ViewName;
    viewId: ViewId;
    onCopyView: () => void;
    onOpenPopupMenu: () => void;
    width: number;
    height: number;
    margin: number;
    menuContainerWidth: number;
    shouldHideMenuButtons: boolean;
    viewThemeColor: ThemeColor;
    readonly: boolean;
    getGuardProps: GetGuardProps;
};

export const ReadonlyTitleBar = ({
    viewRect,
    name,
    viewId,
    onCopyView,
    onOpenPopupMenu,
    width,
    height,
    margin,
    menuContainerWidth,
    shouldHideMenuButtons,
    viewThemeColor,
    readonly,
    getGuardProps,
}: Props) => {
    return (
        <g transform={viewRect.topLeft().toSVGTranslate()}>
            <BaseTitleBar
                width={width}
                height={height}
                margin={margin}
                menuContainerWidth={menuContainerWidth}
                shouldHideMenuButtons={shouldHideMenuButtons}
                viewThemeColor={viewThemeColor}
                name={name}
                viewId={viewId}
                readonly={readonly}
                onCopyView={onCopyView}
                onOpenPopupMenu={onOpenPopupMenu}
                getGuardProps={getGuardProps}
            />
        </g>
    );
};
