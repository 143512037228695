import { GroupId, GroupMemberInvitationId } from '@schema-common/base';

import { useGroupMemberInvitation } from '@group/hooks/useGroupMemberInvitation';
import { useGroupMemberSignUpMethod } from '@group/hooks';
import { Loading } from '@framework/ui/atoms/Loading';
import { ErrorMessage } from './ErrorMessage';
import { InvitationMenu } from './InvitationMenu';

type Props = {
    groupId: GroupId;
    invitationId: GroupMemberInvitationId;
};

export const InvitationContent: React.FC<Props> = ({ groupId, invitationId }: Props) => {
    const {
        loading: loadingMemberInvitation,
        invitation,
        ipRestrictionBlocked,
    } = useGroupMemberInvitation(groupId, invitationId);
    const { loading: loadingSignUpMethods, signUpMethods } = useGroupMemberSignUpMethod(groupId);

    return loadingMemberInvitation || loadingSignUpMethods ? (
        <Loading />
    ) : ipRestrictionBlocked ? (
        <ErrorMessage errorReason="ipRestrictionBlocked" />
    ) : !invitation ? (
        <ErrorMessage errorReason="notFound" />
    ) : invitation.isExpired() ? (
        <ErrorMessage errorReason="expired" />
    ) : !invitation.isActive() ? (
        <ErrorMessage errorReason="inactive" />
    ) : !signUpMethods ? (
        <ErrorMessage errorReason="signUpMethodNotFound" />
    ) : (
        <InvitationMenu invitation={invitation} availableSignUpMethods={signUpMethods} />
    );
};
