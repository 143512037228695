import { useAtomValue } from 'jotai';
import { viewTitleBarAtom } from '../viewTitlaBarAtom';
import { Rect } from '@view-model/models/common/basic';
import { ViewName } from '@view-model/domain/view';
import { ViewId, ViewModelId } from '@schema-common/base';
import { DragContext } from '@model-framework/ui';
import { ThemeColor } from '@view-model/models/common/color';
import { ReadonlyTitleBar } from './ReadonlyTitleBar';
import { DraggableTitleBar } from './DraggableTitleBar';
import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';

type Props = {
    readonly: boolean;
    viewIsLocked: boolean;
    viewIsPositionFixed: boolean;
    viewRect: Rect;
    viewModelId: ViewModelId;
    name: ViewName;
    viewId: ViewId;
    onClick(): void;
    onCopyView(): void;
    onOpenPopupMenu(): void;
    onDragStart(viewId: ViewId): void;
    onDrag(context: DragContext): void;
    onDragEnd(): void;
    viewThemeColor: ThemeColor;
    getGuardProps: GetGuardProps;
};

export const ViewTitleBar: React.FC<Props> = ({
    viewModelId,
    readonly,
    viewIsLocked,
    viewIsPositionFixed,
    viewRect,
    name,
    viewId,
    onClick,
    onCopyView,
    onOpenPopupMenu,
    onDragStart,
    onDrag,
    onDragEnd,
    viewThemeColor,
    getGuardProps,
}: Props) => {
    const { height, shouldHideMenuButtons, menu, margin } = useAtomValue(viewTitleBarAtom);

    if ((readonly || viewIsPositionFixed) && !viewIsLocked) {
        return (
            <ReadonlyTitleBar
                viewRect={viewRect}
                name={name}
                viewId={viewId}
                onCopyView={onCopyView}
                onOpenPopupMenu={onOpenPopupMenu}
                width={viewRect.width}
                height={height}
                margin={margin}
                menuContainerWidth={menu.containerWidth}
                shouldHideMenuButtons={shouldHideMenuButtons}
                viewThemeColor={viewThemeColor}
                readonly={readonly}
                getGuardProps={getGuardProps}
            />
        );
    }

    return (
        <DraggableTitleBar
            viewRect={viewRect}
            name={name}
            viewId={viewId}
            viewModelId={viewModelId}
            isLocked={viewIsLocked}
            onClick={onClick}
            onCopyView={onCopyView}
            onOpenPopupMenu={onOpenPopupMenu}
            onDragStart={onDragStart}
            onDrag={onDrag}
            onDragEnd={onDragEnd}
            width={viewRect.width}
            height={height}
            margin={margin}
            menuContainerWidth={menu.containerWidth}
            shouldHideMenuButtons={shouldHideMenuButtons}
            viewThemeColor={viewThemeColor}
            getGuardProps={getGuardProps}
        />
    );
};
