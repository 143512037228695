import { StickyZoneKey } from '@view-model/domain/key';
import { UserKey } from '@user/domain';
import { StickyZoneStyle } from './StickyZoneStyle';
import { StickyZoneSize } from './StickyZoneSize';
import { StickyZoneText } from './StickyZoneText';
import { Rect } from '@view-model/models/common/basic/Rect';
import { Point, Size } from '@view-model/models/common/basic';
import { RectShape } from '@model-framework/shape';
import { Timestamp } from '@framework/Timestamp';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';
import { StickyZoneId } from '@schema-common/base';

type StickyZoneAttributes = {
    key: StickyZoneKey;
    text: StickyZoneText;
    size: StickyZoneSize;
    style: StickyZoneStyle;
    url: string | null;
    createdUserKey: UserKey;
    createdAt: Timestamp;
};

export class StickyZone {
    private static readonly CornerRadius = 12;

    readonly key: StickyZoneKey;
    readonly text: StickyZoneText;
    readonly size: StickyZoneSize;
    readonly style: StickyZoneStyle;
    readonly url: string | null;
    readonly createdUserKey: UserKey;
    readonly createdAt: Timestamp;

    constructor(attributes: StickyZoneAttributes) {
        this.key = attributes.key;
        this.text = attributes.text;
        this.size = attributes.size;
        this.style = attributes.style;
        this.url = attributes.url || null;
        this.createdUserKey = attributes.createdUserKey;
        this.createdAt = attributes.createdAt;
    }

    static buildNew(attributes: {
        text?: StickyZoneText;
        size?: StickyZoneSize;
        style?: StickyZoneStyle;
        createdUserKey: UserKey;
    }): StickyZone {
        return new this({
            key: StickyZoneKey.buildNew(),
            text: attributes?.text || new StickyZoneText(''),
            size: attributes?.size || StickyZoneSize.buildNew(),
            style: attributes?.style || StickyZoneStyle.buildNew(),
            url: null,
            createdUserKey: attributes.createdUserKey,
            createdAt: Timestamp.now(),
        });
    }

    static load(dump: StickyZoneJSON): StickyZone {
        return new this({
            key: new StickyZoneKey(dump.key),
            text: StickyZoneText.load(dump.text),
            size: StickyZoneSize.load(dump.size),
            style: StickyZoneStyle.load(dump.style),
            url: dump.url || null,
            createdUserKey: new UserKey(dump.createdUserKey),
            createdAt: new Timestamp(dump.createdAt),
        });
    }

    dump(): StickyZoneJSON {
        return {
            key: this.key.toString(),
            text: this.text.dump(),
            size: this.size.dump(),
            style: this.style.dump(),
            ...(this.url ? { url: this.url } : {}),
            createdUserKey: this.createdUserKey.toString(),
            createdAt: this.createdAt.toISOString(),
        };
    }

    static size(): Size {
        return new Size(StickyZoneSize.DEFAULT_WIDTH, StickyZoneSize.DEFAULT_HEIGHT);
    }

    get id(): StickyZoneId {
        return this.key.id as StickyZoneId;
    }

    get createdUserId(): string {
        return `${this.createdUserKey.id}`;
    }

    isEqual(other: StickyZone): boolean {
        return other instanceof StickyZone && this.key.isEqual(other.key);
    }

    private _cloneWith(attributes: Partial<StickyZoneAttributes>): StickyZone {
        return new StickyZone({
            key: this.key,
            text: attributes.text || this.text,
            size: attributes.size || this.size,
            style: attributes.style || this.style,
            url: attributes.url || this.url,
            createdUserKey: attributes.createdUserKey || this.createdUserKey,
            createdAt: this.createdAt,
        });
    }

    cloneNew(): StickyZone {
        return StickyZone.load({
            ...this.dump(),
            key: StickyZoneKey.buildNew().toString(),
            createdAt: Timestamp.now().toISOString(),
        });
    }

    withText(newText: StickyZoneText): StickyZone {
        return this._cloneWith({ text: newText });
    }

    withSize(newSize: StickyZoneSize): StickyZone {
        return this._cloneWith({
            size: newSize,
        });
    }

    withCreatedUserKey(createdUserKey: UserKey): StickyZone {
        return this._cloneWith({ createdUserKey });
    }

    /**
     * ゾーンの中心座標を返す
     */
    getCenterPoint(position: Point): Point {
        return this.getRect(position).getCenterPoint();
    }

    includePoint(position: Point, { x, y }: { x: number; y: number }): boolean {
        return this.getRect(position).includePosition({ x, y });
    }

    getRect(position: Point): Rect {
        return new Rect(position, this.size);
    }

    intersectsWithRect(position: Point, rect: Rect): boolean {
        return this.getRect(position).intersects(rect);
    }

    getShape(): RectShape {
        const { width, height } = this.size;
        const radius = StickyZone.CornerRadius;

        return new RectShape({
            width,
            height,
            rx: radius,
            ry: radius,
        });
    }
}
