import { useEffect, useState } from 'react';
import { useCurrentUserId } from '@framework/auth';
import { useMountedRef } from '@framework/hooks';
import { UserEntity } from './UserEntity';
import { createUserEntityRepository } from './UserEntityRepository';

/**
 * 現在ログイン中ユーザの UserEntity を取得します。
 * 匿名ユーザの場合には UserEntity が作成されないため、 null が返ります。
 *
 * @returns
 */
export const useCurrentUserEntity = (): UserEntity | null => {
    const currentUserId = useCurrentUserId();
    const [entity, setEntity] = useState<UserEntity | null>(null);
    const mountedRef = useMountedRef();

    useEffect(() => {
        if (!currentUserId) {
            setEntity(null);
            return;
        }

        const repo = createUserEntityRepository(currentUserId);
        repo.addListener((userEntity) => {
            if (!mountedRef.current) return;

            setEntity(userEntity);
        });

        return () => repo.removeListener();
    }, [currentUserId, mountedRef]);

    return entity;
};
