// eslint-disable-next-line no-restricted-imports
import { passwordStrength as passwordStrengthOriginal, Options } from 'check-password-strength';
// eslint-disable-next-line no-restricted-imports
export type { DiversityType } from 'check-password-strength';

// NOTE: cloud functions側でもcheck-password-strengthを利用しているので、オプションの値を揃える
const options: Options<string> = [
    {
        id: 0,
        value: 'Too weak',
        minDiversity: 0,
        minLength: 0,
    },
    {
        id: 1,
        value: 'Weak',
        minDiversity: 2,
        minLength: 6,
    },
    {
        id: 2,
        value: 'Medium',
        minDiversity: 4,
        minLength: 8,
    },
    {
        id: 3,
        value: 'Strong',
        minDiversity: 4,
        minLength: 10,
    },
];

export const passwordStrength = (password: string) => {
    return passwordStrengthOriginal(password, options);
};
