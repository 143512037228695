import { ObjectRepository, RTDBPath } from '@framework/repository';
import { UserEntityJSON } from '@schema-app/user/entities/{userId}/UserEntityJSON';
import { UserEntity } from './UserEntity';
import { UserId } from '@schema-common/base';

type UserEntityRepository = ObjectRepository<UserEntityJSON, UserEntity>;

export const createUserEntityRepository = (userId: UserId): UserEntityRepository => {
    return new ObjectRepository(UserEntity, RTDBPath.User.userEntityPath(userId));
};
