import { useCallback } from 'react';
import { useActionLogSender } from '@framework/action-log';
import { isViewModelClipboardPayload, ViewModelClipboardPayload } from '@view-model/application/operation';
import { toast } from 'react-hot-toast';
import { ApplicationClipboardPayloadParser, ViewModelClipboardOperator } from '@view-model/application/clipboard';
import { useCurrentUserId } from '@framework/auth';
import { ViewModelOperation } from '@view-model/domain/view-model';
import { ClipboardUtils } from '@framework/ClipboardUtils';

type Handler = () => Promise<void>;

/**
 * クリップボードのペイロード情報からビューモデルを作成するハンドラー関数を返すカスタムフック。
 *
 * @param groupId
 * @param workspaceId
 * @param folderId
 * @returns
 */
export const useCreateViewModelFromClipboardPayloadHandler = (
    groupId: string,
    workspaceId: string,
    folderId: string
): Handler => {
    const actionLogSender = useActionLogSender();
    const currentUserId = useCurrentUserId();

    // 新しく作成するビューモデルの名称を組み立てる。
    // 同じワークスペース内でのコピー＆ペーストの場合には、「のコピー」をサフィックスに付与する。
    const buildViewModelName = useCallback(
        (payload: ViewModelClipboardPayload): string => {
            const suffix = payload.sourceWorkspaceId === workspaceId ? ' のコピー' : '';
            return `${payload.name}${suffix}`;
        },
        [workspaceId]
    );

    return useCallback(async (): Promise<void> => {
        if (!currentUserId) return;

        const start = new Date();
        const clipboardPayload = await ClipboardUtils.readCustomFormatJSON();
        const payload = ApplicationClipboardPayloadParser.parse(clipboardPayload);

        if (!payload || !isViewModelClipboardPayload(payload)) {
            toast.error('ペーストに失敗しました | Paste failed');
            return;
        }

        const toastId = 'view-model-pasting';

        toast('ビューモデルを作成しています...', { id: toastId, duration: Infinity });

        const name = buildViewModelName(payload);

        // 新しくビューモデルを作る
        const viewModelId = await ViewModelOperation.create(workspaceId, folderId, name, () => void 0);

        // 作成したビューモデルに対してクリップボードの内容を貼り付ける
        const errorMessage = await ViewModelClipboardOperator.pasteWithoutCommand(
            groupId,
            viewModelId,
            currentUserId,
            payload
        );

        toast.dismiss(toastId);

        if (errorMessage) {
            toast.error(errorMessage);
        }

        toast('ビューモデルを作成しました | View model added');

        // 行動ログを記録する
        actionLogSender('view_model:create_paste', {
            sourceViewModelId: payload.sourceViewModelId,
            viewModelId,
            viewModelName: name,
            processingTimeSec: (new Date().getTime() - start.getTime()) / 1000.0,
        });
    }, [actionLogSender, buildViewModelName, currentUserId, folderId, groupId, workspaceId]);
};
