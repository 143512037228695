import { ViewModelId } from '@schema-common/base';
import { useEffect, useMemo, useRef } from 'react';
import { useSnapshot } from '@framework/hooks';
import { RTDBPath } from '@framework/repository';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { PointJSON } from '@schema-common/view-model';
import {
    DescriptionPanelCollection,
    DescriptionPanelCollectionContents,
} from '@view-model/models/sticky/DescriptionPanel';
import { DescriptionPanelCollectionJSON } from '@view-model/models/sticky/DescriptionPanel/domain/DescriptionPanelCollection';
import { stickyModelContentsAtomFamily } from '@view-model/adapter/stickyModelContentsAtomFamily';
import { useSetAtom } from 'jotai/react';
import { ModelCommentThreadJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/model-comment-threads/{modelCommentThreadId}/ModelCommentThreadJSON';
import {
    ModelCommentContents,
    ModelCommentThread,
    ModelCommentThreadCollection,
} from '@view-model/models/sticky/ModelComment';
import {
    ElementDescription,
    ElementDescriptionSet,
    ElementDescriptionSetJSON,
} from '@view-model/models/sticky/ElementDescription';
import { DisplayOrderTreeMap } from '@model-framework/display-order/infrastructure/DisplayOrderTreeMap';
import { StickyModel } from '@view-model/domain/model';
import { StickyZoneJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/sticky-zones/{stickyZoneKey}/StickyZoneJSON';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { StickyNodeJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/nodes/{nodeId}/StickyNodeJSON';
import { StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { LinkJSON } from '@schema-app/view-model/contents/{viewModelId}/model-contents/{modelId}/links/{linkId}/LinkJSON';
import { LinkEntity } from '@view-model/models/sticky/StickyLink';
import { DisplayOrderTree } from '@model-framework/display-order';
import { toast } from 'react-hot-toast';
import { throttle } from 'lodash';
interface Props {
    viewModelId: ViewModelId;
    model: StickyModel;
}

// ビューの中のコンテンツをjotaiやモデルに読み込む
export function StickyModelContentsLoader({ viewModelId, model }: Props) {
    const atom = stickyModelContentsAtomFamily(model.id);
    const setStickyModelContents = useSetAtom(atom);
    const modelKey = model.key;

    const onError = useMemo(
        () =>
            // NOTE: permission errorが出る時には、このコンポーネント内の全部のuseSnapshotのonErrorが呼ばれるためthrottleしている
            throttle(() => {
                toast.error('読み込みに失敗しました');
            }, 1000),
        []
    );

    const [panels] = useSnapshot({
        path: RTDBPath.DescriptionPanel.panelsPath(viewModelId, model.id),
        load({ getChildValues }) {
            const panels = DescriptionPanelCollection.load(
                Object.values(getChildValues()) as DescriptionPanelCollectionJSON
            );
            return panels;
        },
        onError,
    });
    const [panelPositions] = useSnapshot({
        path: RTDBPath.DescriptionPanel.positionsPath(viewModelId, model.id),
        load({ snapshot }) {
            return PositionSet.load(snapshot.val() as Record<string, PointJSON> | null);
        },
        onError,
    });
    const panelContents = useMemo(
        () =>
            panels && panelPositions
                ? new DescriptionPanelCollectionContents(panels, panelPositions)
                : DescriptionPanelCollectionContents.buildEmpty(),
        [panels, panelPositions]
    );

    const [threads] = useSnapshot({
        path: RTDBPath.Comment.threadsPath(viewModelId, model.id),
        load({ getChildValues }) {
            const values = getChildValues() as Record<string, ModelCommentThreadJSON>;
            return new ModelCommentThreadCollection(
                Object.values(values).map((value) => ModelCommentThread.load(value))
            );
        },
        onError,
    });
    const [threadPositions] = useSnapshot({
        path: RTDBPath.Comment.positionsPath(viewModelId, model.id),
        load({ snapshot }) {
            return PositionSet.load(snapshot.val() as Record<string, PointJSON> | null);
        },
        onError,
    });
    const commentContents = useMemo(
        () =>
            threads && threadPositions
                ? new ModelCommentContents(modelKey, threads, threadPositions)
                : ModelCommentContents.buildEmpty(modelKey),
        [threads, threadPositions, modelKey]
    );

    const [loadedNodeDescriptionSet] = useSnapshot({
        path: RTDBPath.Node.descriptionsPath(viewModelId, model.id),
        load({ getChildValues }) {
            const values = getChildValues() as ElementDescriptionSetJSON;
            return ElementDescriptionSet.fromArray(
                Object.values(values).map((value) => ElementDescription.load(value))
            );
        },
        onError,
    });
    const nodeDescriptionSet = useMemo(
        () => loadedNodeDescriptionSet ?? ElementDescriptionSet.fromArray([]),
        [loadedNodeDescriptionSet]
    );

    const [loadedZoneDescriptionSet] = useSnapshot({
        path: RTDBPath.Zone.descriptionsPath(viewModelId, model.id),
        load({ getChildValues }) {
            const values = getChildValues() as ElementDescriptionSetJSON;
            return ElementDescriptionSet.fromArray(
                Object.values(values).map((value) => ElementDescription.load(value))
            );
        },
        onError,
    });
    const zoneDescriptionSet = useMemo(
        () => loadedZoneDescriptionSet ?? ElementDescriptionSet.fromArray([]),
        [loadedZoneDescriptionSet]
    );

    const [loadedDisplayOrderTree] = useSnapshot({
        path: RTDBPath.Model.displayOrderPath(viewModelId, model.id),
        load({ snapshot }) {
            const tree = new DisplayOrderTreeMap(snapshot.val() || {});
            return tree.toDisplayOrderTree('__root__');
        },
        onError,
    });
    const displayOrderTree = useMemo(() => loadedDisplayOrderTree ?? new DisplayOrderTree(), [loadedDisplayOrderTree]);

    const [loadedZonePositions] = useSnapshot({
        path: RTDBPath.Zone.positionsPath(viewModelId, model.id),
        load({ snapshot }) {
            const zonePositions = PositionSet.load(snapshot.val() as Record<string, PointJSON> | null);

            // modelに同期
            model.setZonePositions(zonePositions);

            return zonePositions;
        },
        onError,
    });
    const stickyZonePositions = useMemo(() => loadedZonePositions ?? new PositionSet(), [loadedZonePositions]);

    const [loadedStickyZones] = useSnapshot({
        path: RTDBPath.Zone.zonesPath(viewModelId, model.id),
        load({ getChildValues }) {
            const values = getChildValues() as Record<string, StickyZoneJSON>;
            const stickyZones = Object.values(values).map((value) => StickyZone.load(value));

            // modelに同期
            model.setZones(stickyZones);

            return stickyZones;
        },
        onError,
    });
    const stickyZones = useMemo(() => loadedStickyZones ?? [], [loadedStickyZones]);

    const [loadedNodes] = useSnapshot({
        path: RTDBPath.Node.nodesPath(viewModelId, model.id),
        load({ getChildValues }) {
            const values = getChildValues() as Record<string, StickyNodeJSON>;
            const nodeSorting = (a: StickyNode, b: StickyNode) => a.compareByDisplayOrder(b);
            const nodes = Object.values(values)
                .map((value) => StickyNode.load(value))
                .sort(nodeSorting);

            // modelに同期する
            model.setNodes(nodes);

            return nodes;
        },
        onError,
    });
    const stickyNodes = useMemo(() => loadedNodes ?? [], [loadedNodes]);

    const [loadedLinks] = useSnapshot({
        path: RTDBPath.Link.linksPath(viewModelId, model.id),
        load({ getChildValues }) {
            const values = getChildValues() as Record<string, LinkJSON>;
            const links = Object.values(values).map((value) => LinkEntity.load(value));

            // modelに同期
            model.setLinks(links);

            return links;
        },
        onError,
    });
    const links = useMemo(() => loadedLinks ?? [], [loadedLinks]);

    useEffect(() => {
        setStickyModelContents((prev) => ({
            ...prev,
            displayOrderTree,
            nodeDescriptionSet,
            zoneDescriptionSet,
            commentContents,
            panelContents,
            stickyZonePositions,
            stickyZones,
            stickyNodes,
            links,
        }));
    }, [
        setStickyModelContents,
        displayOrderTree,
        nodeDescriptionSet,
        zoneDescriptionSet,
        commentContents,
        panelContents,
        stickyZonePositions,
        stickyZones,
        stickyNodes,
        links,
    ]);

    const modelIdRef = useRef(model.id);
    modelIdRef.current = model.id;

    // unmount時に削除
    useEffect(() => {
        return () => {
            stickyModelContentsAtomFamily.remove(modelIdRef.current);
        };
    }, []);

    return null;
}
