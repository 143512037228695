import { SimpleFooterLayout } from '@user/layouts/SimpleFooterLayout';
import BalusLogo from '@assets/balus-logo-brand-horizontal.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { EditPasswordForm } from '@user/EditPasswordForm';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { NotFound } from '@framework/ui/pages/NotFound';

export const SignUpRequestConfirmPage: React.FC<unknown> = () => {
    const [signUpRequestToken, setSignUpRequestToken] = useState('');
    const [name, setName] = useState('');
    const [mailAddress, setMailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState<boolean>(false);

    const history = useHistory();
    const location = useLocation();

    const paramToken = useMemo(() => new URLSearchParams(location.search).get('token'), [location]);

    const handleChangePassword = useCallback((password: string) => {
        setPassword(password);
    }, []);
    const isEmpty = password === '';

    const handleSubmit = useCallback(async () => {
        setProcessing(true);

        FunctionsUserActions.signUpPasswordAuth(name, mailAddress, password, signUpRequestToken)
            .then((token) => {
                FirebaseAuthenticationClient.signInWithCustomToken(token);
            })
            .then(() => {
                history.push('/');
            })
            .catch((error) => {
                // FirebaseErrorはmessageでしかエラー内容を判別できない
                if (error.message && error.message.includes('same email address')) {
                    setError(
                        'Balusにユーザ登録されているメールアドレスです。ユーザ登録フォームを閉じてからログインを選択して、ログインしてください。'
                    );
                } else {
                    setError('ユーザ登録に失敗しました。時間をあけてもう一度試してみてください。');
                }
                console.error(error);
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [history, mailAddress, name, password, signUpRequestToken]);

    useEffect(() => {
        if (!paramToken) {
            setSignUpRequestToken('invalid');
            return;
        }

        FunctionsUserActions.fetchSignUpRequestToken(paramToken).then((data) => {
            if (data) {
                setMailAddress(data.mailAddress);
                setName(data.name);
                setSignUpRequestToken(paramToken);
            } else {
                setSignUpRequestToken('invalid');
            }
        });
    }, [paramToken]);

    if (signUpRequestToken === 'invalid') {
        return <NotFound message="トークンが不正です。" />;
    }

    return (
        <SimpleFooterLayout>
            <div className="flex w-screen flex-col items-center p-8">
                <img src={BalusLogo} className="w-64" />

                <div className="py-8 text-3xl font-bold">ユーザ登録確認</div>
                <hr className="w-full" />

                {/* content */}
                <div className="flex flex-col p-8" style={{ width: '450px' }}>
                    <div className="pb-2 font-bold">メールアドレス (変更できません)</div>
                    <input
                        type="text"
                        className="cursor-not-allowed rounded-sm border bg-gray-200 p-2"
                        defaultValue={mailAddress}
                        disabled
                    />

                    <div className="pb-2 pt-8 font-bold">名前 (変更できません)</div>
                    <input type="text" className="rounded-sm border p-2" defaultValue={name} disabled />

                    <EditPasswordForm onChange={handleChangePassword} disabled={processing} />

                    {error && <div className="pt-8 text-red-700">{error}</div>}
                </div>

                {/* footer */}
                <hr className="w-full" />
                <div className="flex justify-end px-8 pb-8 pt-4">
                    <FlatButton
                        color="brand"
                        onClick={handleSubmit}
                        className="ml-2 px-8"
                        disabled={isEmpty || processing}
                        loading={processing}
                    >
                        ユーザ登録
                    </FlatButton>
                </div>
            </div>
        </SimpleFooterLayout>
    );
};
