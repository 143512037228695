import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { usePageViewLogger } from '@framework/action-log';
import { LoginForm } from '@user/Login';
import { useCurrentUserEntity } from '@user/UserEntity';
import { SimpleFooterLayout } from '@user/layouts/SimpleFooterLayout';
import BalusLogo from '@assets/balus-logo-brand-horizontal.svg';

export const LoginPage: React.FC<unknown> = () => {
    const currentUserEntity = useCurrentUserEntity();
    const pageViewLogger = usePageViewLogger('LoginPage');

    useEffect(() => {
        if (currentUserEntity) return;

        pageViewLogger.landing();
        return () => pageViewLogger.leaving();
    }, [currentUserEntity, pageViewLogger]);

    const searchParams = new URLSearchParams(window.location.search);
    const requestPath = searchParams.get('requestPath') || '/';

    // 既にログイン済みであれば、ログインフォームを表示するのではなく、元のリクエストパス (or HomePage) にリダイレクトする
    return currentUserEntity ? (
        <Redirect to={requestPath} />
    ) : (
        <SimpleFooterLayout>
            <div className="mx-auto flex flex-col items-center px-4 pb-8 pt-16 md:container">
                <img src={BalusLogo} className="w-64" />
                <div className="py-8 text-4xl font-bold">Balusにログインする</div>
                <LoginForm />
            </div>
        </SimpleFooterLayout>
    );
};
