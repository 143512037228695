import { Fragment, useState, useMemo, useEffect } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { Input, RadioInput } from '@framework/ui/atoms/Input';
import cidrRegex from 'cidr-regex';
import ipRangeCheck from 'ip-range-check';
import { IpRestrictionConfiguration } from '@schema-app/group/ip-restrictions/{groupId}/IpRestrictionConfiguration';
import { toast } from 'react-hot-toast';
import isEqual from 'lodash/isEqual';
import { usePrevious } from '@view-model/models/common/hooks/usePrevious';

interface Props {
    ipRestrictionConfiguration: IpRestrictionConfiguration;
    saveIpRestrictionConfiguration: (config: IpRestrictionConfiguration) => Promise<void>;
    currentIp: string | null;
}

interface State {
    policy: 'allow_only' | 'none';
    allowRules: { cidr: string; memo: string }[];
    errors: Record<string, string>;
    saving: boolean;
}

function transformConfigurationToState(config: IpRestrictionConfiguration): State {
    return {
        ...config,
        allowRules: !config.allowRules || config.allowRules.length === 0 ? [{ cidr: '', memo: '' }] : config.allowRules,
        errors: {},
        saving: false,
    };
}

export const IpRestrictionForm: React.FC<Props> = (props: Props) => {
    const { ipRestrictionConfiguration, saveIpRestrictionConfiguration, currentIp } = props;
    const prevIpRestrictionConfiguration = usePrevious(ipRestrictionConfiguration);

    const initialState = useMemo<State>(
        () => transformConfigurationToState(ipRestrictionConfiguration),
        [ipRestrictionConfiguration]
    );

    const [state, setState] = useState<State>(initialState);

    const isDirty = !isEqual(state, initialState);

    useEffect(() => {
        // 別のユーザ、別のタブで設定が更新された場合には、 state を更新する
        if (!isEqual(prevIpRestrictionConfiguration, ipRestrictionConfiguration)) {
            setState(transformConfigurationToState(ipRestrictionConfiguration));
        }
    }, [ipRestrictionConfiguration, prevIpRestrictionConfiguration, state]);

    const onSubmit = async () => {
        const { configuration, errors } = parse(state);
        if (Object.values(errors).length > 0) {
            setState((prev) => ({ ...prev, errors }));
            return;
        }
        setState((prev) => ({ ...prev, errors: {}, saving: true }));

        try {
            await saveIpRestrictionConfiguration(configuration);
            toast.success('設定を保存しました');
        } catch (e) {
            toast.error('設定の保存に失敗しました');
        } finally {
            setState((prev) => ({ ...prev, saving: false }));
        }
    };

    return (
        <div className={'flex flex-col gap-4'}>
            <div className="pt-4 font-bold">アクセス許可ポリシー</div>
            <div className={'flex flex-col gap-2'}>
                <label className={'flex w-max gap-2'}>
                    <RadioInput
                        type={'radio'}
                        id={'allowAll'}
                        disabled={state.saving}
                        checked={state.policy === 'none'}
                        onChange={(event) => {
                            const policy = event.currentTarget.checked ? 'none' : 'allow_only';
                            setState((prev) => ({ ...prev, policy }));
                        }}
                    />
                    全てのIPのアクセスを許可する
                </label>

                <label className={'flex w-max gap-2'}>
                    <RadioInput
                        type={'radio'}
                        id={'enableIpRestriction'}
                        disabled={state.saving}
                        checked={state.policy === 'allow_only'}
                        onChange={(event) => {
                            const policy = event.currentTarget.checked ? 'allow_only' : 'none';
                            setState((prev) => ({ ...prev, policy }));
                        }}
                    />
                    設定した範囲のみアクセスを許可する
                </label>
            </div>

            <div className="pt-4 font-bold">IP範囲の設定</div>

            <div>
                アクセスを許可するIPの範囲を設定します。
                <br />
                IP部分にはIPv4とIPv6両方の形式を記述できます。
            </div>

            <div className={'grid grid-cols-12 gap-4'}>
                <div className={'col-span-7 self-end text-sm'}>CIDR(IP/サブネットマスク)</div>
                <div className={'col-span-4 self-end text-sm'}>メモ</div>
                <div className={'col-span-1'}>
                    <Button
                        color={'secondary'}
                        disabled={state.policy !== 'allow_only' || state.saving}
                        size={'sm'}
                        onClick={() => {
                            const newRules = [...state.allowRules];
                            newRules.push({
                                cidr: '',
                                memo: '',
                            });
                            setState((prev) => ({ ...prev, allowRules: newRules }));
                        }}
                    >
                        追加
                    </Button>
                </div>

                {state.allowRules.map((rule, index) => {
                    return (
                        <Fragment key={index}>
                            <div className={'col-span-7'}>
                                <Input
                                    type={'text'}
                                    size={'sm'}
                                    className={state.errors[`items.${index}.cidr`] ? 'border-red-500' : ''}
                                    value={rule.cidr}
                                    placeholder={'192.0.2.0/24'}
                                    disabled={state.policy !== 'allow_only' || state.saving}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setState((prev) => {
                                            const allowRules = [...prev.allowRules];
                                            allowRules[index] = {
                                                ...allowRules[index],
                                                cidr: value,
                                            };
                                            return { ...prev, allowRules };
                                        });
                                    }}
                                />
                            </div>

                            <div className={'col-span-4'}>
                                <Input
                                    type={'text'}
                                    size={'sm'}
                                    className={state.errors[`items.${index}.memo`] ? 'border-red-500' : ''}
                                    value={rule.memo}
                                    disabled={state.policy !== 'allow_only' || state.saving}
                                    placeholder={'例: 本社からのアクセス'}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setState((prev) => {
                                            const allowRules = [...prev.allowRules];
                                            allowRules[index] = {
                                                ...allowRules[index],
                                                memo: value,
                                            };
                                            return { ...prev, allowRules };
                                        });
                                    }}
                                />
                            </div>
                            <div className={'col-span-1'}>
                                {state.allowRules.length > 1 && (
                                    <Button
                                        disabled={state.policy !== 'allow_only' || state.saving}
                                        size={'sm'}
                                        color={'secondary'}
                                        onClick={() => {
                                            setState((prev) => {
                                                const allowRules = [...state.allowRules];
                                                allowRules.splice(index, 1);
                                                const next = { ...prev, allowRules };
                                                const { errors } = parse(next);
                                                return { ...next, errors };
                                            });
                                        }}
                                    >
                                        削除
                                    </Button>
                                )}
                            </div>
                        </Fragment>
                    );
                })}
            </div>

            {Object.values(state.errors).length > 0 && (
                <div>
                    {[...new Set(Object.values(state.errors))].map((error, index) => (
                        <div key={index} className={'font-bold text-red-500'}>
                            {error}
                        </div>
                    ))}
                </div>
            )}

            {currentIp &&
                currentIp !== '' &&
                state.policy === 'allow_only' &&
                !ipRangeCheck(
                    currentIp,
                    state.allowRules.map(({ cidr }) => cidr)
                ) && (
                    <div className="font-bold text-red-500">
                        ※ 現在のアクセス元IPアドレス ({currentIp})
                        が許可リストに含まれていません。このまま保存するとグループにアクセスできなくなる可能性があります。
                    </div>
                )}

            <div className={'flex gap-4'}>
                <Button color={'brand'} className={''} disabled={!isDirty || state.saving} onClick={onSubmit}>
                    保存する
                </Button>

                <Button
                    color={'secondary'}
                    disabled={!isDirty || state.saving}
                    onClick={() => {
                        setState(initialState);
                    }}
                >
                    キャンセル
                </Button>
            </div>

            <div></div>
        </div>
    );
};

function parseCidr(target: string): string | null {
    target = target.trim();
    return cidrRegex({ exact: true }).test(target) ? target : null;
}

const parse = (state: State): { configuration: IpRestrictionConfiguration; errors: Record<string, string> } => {
    const errors: Record<string, string> = {};

    for (const [index, rule] of state.allowRules.entries()) {
        if (state.policy === 'none' && rule.cidr.length === 0 && rule.memo.length === 0) {
            continue;
        }
        if (rule.cidr.length === 0) {
            errors[`items.${index}.cidr`] = 'CIDRを入力してください';
        } else if (parseCidr(rule.cidr) === null) {
            errors[`items.${index}.cidr`] = 'CIDRの形式が正しくありません';
        }
    }

    const configuration: IpRestrictionConfiguration = {
        policy: state.policy,
        allowRules: state.allowRules,
    };

    return { configuration, errors };
};
