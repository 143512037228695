import { GroupJSON } from '@schema-app/group/entities/{groupId}/GroupJSON';
import { GroupAnalysisSetting } from './GroupAnalysisSetting';
import { GroupMemberSignUpMethod } from './GroupMemberSignUpMethod';

export class GroupSetting {
    constructor(
        public readonly availableSignUpMethods: GroupMemberSignUpMethod,
        public readonly analysisSetting: GroupAnalysisSetting
    ) {}

    dump(): GroupJSON['setting'] {
        const { availableSignUpMethods, analysisSetting } = this;
        return {
            availableSignUpMethods: availableSignUpMethods.dump(),
            analysisSetting: analysisSetting.dump(),
        };
    }

    static load(dump: GroupJSON['setting']): GroupSetting {
        const { availableSignUpMethods, analysisSetting } = dump;
        return new this(
            GroupMemberSignUpMethod.load(availableSignUpMethods),
            GroupAnalysisSetting.load(analysisSetting)
        );
    }

    static buildNew(): GroupSetting {
        return new this(GroupMemberSignUpMethod.buildNew(), GroupAnalysisSetting.buildNew());
    }
}
