import { useEffect, useState } from 'react';
import { useCurrentUserEntity } from '@user/UserEntity';
import { useGroupEntity } from '@group/hooks/useGroupEntity';

/**
 * 指定のグループで分析画面の表示が有効になっているかどうかのフラグを返します。
 * エンティティを取得できない場合には、false を返します。
 *
 * @param groupId
 * @returns boolean
 */
export const useGroupAnalysisSettingAvailability = (groupId: string): boolean => {
    const [availability, setAvailability] = useState<boolean>(false);

    const { group } = useGroupEntity(groupId);
    const userEntity = useCurrentUserEntity();

    useEffect(() => {
        if (group) setAvailability(!!userEntity && group.setting.analysisSetting.isAnalysisAvailable());
    }, [groupId, group, userEntity]);

    return availability;
};
