import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

type Props = {
    horizontal?: boolean;
    large?: boolean;
    message?: string;
};

export const Loading: React.FC<Props> = ({ message, large = false, horizontal = false }: Props) => {
    const iconFontSize = large ? 'text-9xl' : 'text-3xl';
    const messageFontSize = large ? 'text-6xl' : 'text-2xl';
    const messagePadding = large ? 'p-4' : '';

    return (
        <>
            {horizontal ? (
                <div className={'flex flex-row items-center rounded-lg bg-white p-4'}>
                    <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${iconFontSize} text-brand`} />
                    <div className="flex pl-4" />
                    <div className={`text-center ${messageFontSize} font-light text-brand`}>
                        {message || 'Loading...'}
                    </div>
                </div>
            ) : (
                <div className="flex flex-col items-center rounded-lg bg-white p-4">
                    <FontAwesomeIcon icon={faSpinner} className={`fa-spin ${iconFontSize} text-brand`} />
                    <div className={`mt-2 ${messagePadding} text-center ${messageFontSize} font-light text-brand`}>
                        {message || 'Loading...'}
                    </div>
                </div>
            )}
        </>
    );
};
