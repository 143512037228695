import { useCallback, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';
import { useHistory } from 'react-router-dom';
import { UserPagePathBuilder } from '../UserPagePathBuilder';

type Props = {
    isOpen: boolean;
    onClose(): void;
};

export const UserSignUpRequestWithEmailModal: React.FC<Props> = ({ isOpen, onClose }: Props) => {
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState('');

    const [name, setName] = useState('');
    const [mailAddress, setMailAddress] = useState('');

    const history = useHistory();

    const handleChangeMailAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setMailAddress(event.target.value);
    }, []);

    const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const handleClose = useCallback(() => {
        setProcessing(false);
        onClose();
        history.push(UserPagePathBuilder.entryPage());
    }, [history, onClose]);

    const handleSubmit = useCallback(async () => {
        setProcessing(true);

        FunctionsUserActions.sendSignUpRequestToken(name, mailAddress)
            .then(() => {
                history.push(UserPagePathBuilder.signUpRequestDonePage());
            })
            .catch((error) => {
                // FirebaseErrorはmessageでしかエラー内容を判別できない
                if (error.message && error.message.includes('same email address')) {
                    setError(
                        'Balusにユーザ登録されているメールアドレスです。ユーザ登録フォームを閉じてからログインを選択して、ログインしてください。'
                    );
                } else {
                    setError('ユーザ登録に失敗しました。時間をあけてもう一度試してみてください。');
                }
                console.error(error);
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [history, mailAddress, name]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className="flex flex-col">
            {/* header */}
            <div className="px-8 pb-4 pt-8 text-2xl font-bold">ユーザ登録</div>
            <hr className="w-full" />

            {/* content */}
            <div className="flex flex-col p-8">
                <div className="pb-2 font-bold">メールアドレス</div>
                <input
                    type="text"
                    className="rounded-sm border p-2"
                    value={mailAddress}
                    onChange={handleChangeMailAddress}
                    autoFocus
                />

                <div className="pb-2 pt-8 font-bold">名前</div>
                <input type="text" className="rounded-sm border p-2" value={name} onChange={handleChangeName} />

                {error && <div className="pt-8 text-red-700">{error}</div>}
            </div>

            {/* footer */}
            <hr className="w-full" />
            <div className="flex justify-end px-8 pb-8 pt-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
                <FlatButton
                    color="brand"
                    onClick={handleSubmit}
                    className="ml-2 px-8"
                    disabled={processing}
                    loading={processing}
                >
                    ユーザ登録
                </FlatButton>
            </div>
        </Modal>
    );
};
