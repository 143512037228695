import { useEffect, useMemo } from 'react';
import { TopNavigation } from '@framework/ui/elements/TopNavigation';
import { Redirect, useLocation } from 'react-router-dom';
import { useCurrentUserId } from '@framework/auth';
import { GroupSelection } from '@user/pages/HomePage/GroupSelection';
import { usePageViewLogger } from '@framework/action-log';
import { useAssignedGroups } from '@group/hooks';
import { FullScreenLoading } from '@framework/ui';
import { UserId } from '@schema-common/base';
import { Entry } from './Entry';

type Props = {
    currentUserId: UserId;
};

const HomePageContent: React.FC<Props> = ({ currentUserId }: Props) => {
    const { groups, loading, hasIpRestrictionBlocked } = useAssignedGroups(currentUserId);

    if (loading) return <FullScreenLoading />;

    return (
        <div>
            <TopNavigation />
            <GroupSelection groups={groups} hasIpRestrictionBlocked={hasIpRestrictionBlocked} />
        </div>
    );
};

export const HomePage: React.FunctionComponent<unknown> = () => {
    const currentUserId = useCurrentUserId();
    const pageViewLogger = usePageViewLogger('HomePage');

    const location = useLocation();

    const mode = useMemo(() => {
        const params = new URLSearchParams(location.search);
        return params.get('mode') || '';
    }, [location]);

    useEffect(() => {
        if (!currentUserId) return;

        pageViewLogger.landing();
        return () => pageViewLogger.leaving();
    }, [currentUserId, pageViewLogger]);

    return (
        <>
            {currentUserId ? (
                <HomePageContent currentUserId={currentUserId} />
            ) : mode === 'entry' ? (
                <Entry />
            ) : (
                <Redirect to={'/login'} />
            )}
        </>
    );
};
