import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';
import { DragEvent, useD3Drag } from '@view-model/models/common/hooks/useD3Drag';

const CIRCLE_RADIUS = 36;

type Props = {
    cx: number;
    cy: number;
    cursor: string;
    onDragStart(): void;
    onDrag(event: DragEvent): void;
    onDragEnd(): void;
    getGuardProps: GetGuardProps;
};

export const CornerPointer: React.FC<Props> = ({
    cx,
    cy,
    cursor,
    onDragStart,
    onDrag,
    onDragEnd,
    getGuardProps,
}: Props) => {
    const ref = useD3Drag<SVGCircleElement>({ onDragStart, onDrag, onDragEnd });

    return (
        <circle
            className="fill-brand"
            cx={cx}
            cy={cy}
            r={CIRCLE_RADIUS}
            cursor={cursor}
            ref={ref}
            {...getGuardProps()}
        />
    );
};
