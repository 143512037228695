import { useEffect, useState } from 'react';
import { useMountedRef } from '@framework/hooks';
import { createGroupEntityRepository, GroupEntity, isGroupEntity } from '@group/domain';
import { AuthSessionId, GroupId, UserId } from '@schema-common/base';
import { DataSnapshot, RefBuilder, RTDBPath } from '@framework/repository';
import { GroupIpRestrictionCheckRequest } from '@group/AccessControl';
import { useCurrentSessionId } from '@framework/auth/AuthContext';

/**
 * 指定のユーザが所属するグループのID一覧を返す。
 * 未ログインの場合には、所属するグループは存在しないので、空配列を返す。
 *
 * @param userId
 * @returns
 */
const useAssignedGroupIds = (userId: UserId): Set<GroupId> | null => {
    const [ids, setIds] = useState<Set<GroupId> | null>(null);
    const mountedRef = useMountedRef();

    useEffect(() => {
        const ref = RefBuilder.ref(RTDBPath.Group.assignedGroupIndexPath(userId));
        const callback = (snapshot: DataSnapshot) => {
            if (!mountedRef.current) return; // マウント解除済みならば、 state 更新しない
            setIds(new Set(Object.keys(snapshot.val() || {})));
        };
        ref.on('value', callback);
        return () => ref.off('value', callback);
    }, [userId, mountedRef]);
    return ids;
};

const fetchGroupEntities = async (
    groupIds: GroupId[],
    userId: UserId,
    sessionId: AuthSessionId
): Promise<{ groups: GroupEntity[]; hasIpRestrictionBlocked: boolean }> => {
    // IPアドレス制限によるアクセス可否を判定する
    const ipRestrictionCheckResults = await GroupIpRestrictionCheckRequest.checkMany(groupIds, userId, sessionId);

    // アクセス可能なグループIDの一覧
    const accessibleGroupIds = Object.entries(ipRestrictionCheckResults)
        .filter(([, status]) => status === 'Allow')
        .map(([id]) => id);

    const hasIpRestrictionBlocked = Object.entries(ipRestrictionCheckResults).some(([, status]) => status === 'Deny');

    const groups = await Promise.all(accessibleGroupIds.map((id) => createGroupEntityRepository(id).get()));
    return {
        groups: groups.filter(isGroupEntity).sort(GroupEntity.compare),
        hasIpRestrictionBlocked,
    };
};

type Result = {
    groups: GroupEntity[];
    loading: boolean;
    hasIpRestrictionBlocked: boolean;
};

const Loading: Result = { groups: [], loading: true, hasIpRestrictionBlocked: false };

/**
 * 指定のユーザが所属するグループ・エンティティの一覧を返す。
 * 一覧を未取得・取得中には null を返し、取得完了後にはエンティティの配列を返す。
 * @param userId
 * @returns
 */
export const useAssignedGroups = (userId: UserId): Result => {
    const ids = useAssignedGroupIds(userId);
    const mountedRef = useMountedRef();
    const [state, setState] = useState<Result>(Loading);
    const sessionId = useCurrentSessionId();

    useEffect(() => {
        if (!sessionId) return;

        if (!ids) {
            return;
        }

        fetchGroupEntities(Array.from(ids), userId, sessionId).then(({ groups, hasIpRestrictionBlocked }) => {
            if (!mountedRef.current) return; // マウント解除済みならば何もしない
            setState({
                groups,
                loading: false,
                hasIpRestrictionBlocked,
            });
        });
    }, [ids, mountedRef, sessionId, userId]);

    return state;
};

/**
 * 指定のユーザが複数のグループに所属するか否かを返す。
 * @param userId
 * @returns
 */
export const useAssignedToManyGroups = (userId: UserId): boolean => {
    const ids = useAssignedGroupIds(userId);
    return ids ? ids.size > 1 : false;
};
