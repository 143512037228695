import { useContext, useMemo, useRef, createContext } from 'react';
import { isEqual } from 'lodash';
import { ModelId, StickyZoneId, ViewModelId } from '@schema-common/base';
import {
    CommandHelper,
    CompositeCommand,
    ICommand,
    UpdateMultiPathCommand,
    useCommandManager,
} from '@model-framework/command';
import { UserPublicProfile } from '@user/PublicProfile';
import { StickyZoneText } from '@view-model/models/sticky/StickyZoneView';
import { Point, Rect, Size } from '@view-model/models/common/basic';
import { useHandler } from '@framework/hooks';
import { getClientId } from '@framework/app';
import { StickyZoneKey } from '@view-model/domain/key';
import { DBPath, RTDBPath } from '@framework/repository';
import { JSONValue } from '@model-framework/repository/JSONRepository';
import { EditingUser } from '@model-framework/text';
import { EditingUserRepository } from '@model-framework/text/editing-user';
import { StickyZoneChangeParentZoneCommand } from '../command';
import { DisplayOrderRepository } from '@model-framework/display-order';
import { StickyModelContentsOperation } from '@view-model/adapter';

import { useNodeEditingUser } from '../../editing-users/useNodeEditingUser';

interface StickyZoneContextValue {
    myEditing: boolean;
    handleEditingTextStart(text: StickyZoneText): Promise<boolean>;
    handleEditingTextChanged(text: StickyZoneText): Promise<void>;
    handleEditingTextConfirmed(text: StickyZoneText): Promise<void>;
    handleEditingURLChanged(url: string | null, previousURL: string | null): void;
    handleResize(position: Point, size: Size): void;
    handleResizeEnd(previousPosition: Point, previousSize: Size, position: Point, size: Size): void;
}

const StickyZoneContext = createContext<StickyZoneContextValue | null>(null);

export const useStickyZoneContext = (): StickyZoneContextValue => {
    const context = useContext(StickyZoneContext);
    if (!context) throw new Error('No StickyZoneContextProvider found when calling useStickyZoneContext.');

    return context;
};

type Props = {
    currentUserProfile: UserPublicProfile;
    viewModelId: ViewModelId;
    modelId: ModelId;
    zoneId: StickyZoneId;
    operation: StickyModelContentsOperation;
    children: React.ReactNode;
};

export const StickyZoneContextProvider: React.FC<Props> = ({
    currentUserProfile,
    viewModelId,
    modelId,
    zoneId,
    operation,
    children,
}: Props) => {
    const commandManager = useCommandManager();
    const displayOrderRepository = new DisplayOrderRepository(viewModelId, modelId);

    const clientId = useMemo(() => getClientId(), []);
    const zoneKey = StickyZoneKey.buildFromID(zoneId);
    const previousTextRef = useRef<StickyZoneText | null>(null);

    const buildTextUpdateCommand = (prevText: StickyZoneText, text: StickyZoneText): ICommand => {
        return CommandHelper.buildUpdateCommand(
            RTDBPath.Zone.zoneTextPath(viewModelId, modelId, zoneKey),
            prevText.dump(),
            text.dump()
        );
    };

    const buildZoneBoundsRTDBValue = (position: Point, size: Size): Record<DBPath, JSONValue> => {
        return {
            [RTDBPath.Zone.positionPath(viewModelId, modelId, zoneKey.id)]: position.dump(),
            [RTDBPath.Zone.sizePath(viewModelId, modelId, zoneKey)]: size.dump(),
        };
    };

    const currentEditingUser = useNodeEditingUser(zoneKey);
    const otherUserEditing = currentEditingUser && currentEditingUser.clientID !== clientId;
    const myEditing = currentEditingUser ? currentEditingUser.clientID === clientId : false;

    const editingUserRepository = useMemo(() => new EditingUserRepository(viewModelId), [viewModelId]);

    const saveMyEditingUser = () => {
        const editingUser = EditingUser.buildFromProfile(currentUserProfile, clientId);
        editingUserRepository.save(zoneKey, editingUser).then();
    };

    const clearMyEditingUser = () => {
        editingUserRepository.delete(zoneKey);
    };

    const handleEditingTextStart = useHandler(async (text: StickyZoneText): Promise<boolean> => {
        if (otherUserEditing) {
            return false;
        }

        previousTextRef.current = text;
        saveMyEditingUser();

        return true;
    });

    const handleEditingTextChanged = useHandler(async (text: StickyZoneText): Promise<void> => {
        buildTextUpdateCommand(text, text).do();
    });

    const handleEditingTextConfirmed = useHandler(async (text: StickyZoneText): Promise<void> => {
        if (!myEditing || !previousTextRef.current) {
            return;
        }

        const addHistory = !text.isEqual(previousTextRef.current);

        if (addHistory) {
            const command = buildTextUpdateCommand(previousTextRef.current, text);
            commandManager.execute(command);
        }

        previousTextRef.current = null;
        clearMyEditingUser();
    });

    const handleResize = useHandler((position: Point, size: Size): void => {
        const values = buildZoneBoundsRTDBValue(position, size);
        const command = new UpdateMultiPathCommand(values, values);
        command.do();
    });

    const handleResizeEnd = useHandler(
        (previousPosition: Point, previousSize: Size, position: Point, size: Size): void => {
            const prevValues = buildZoneBoundsRTDBValue(previousPosition, previousSize);
            const values = buildZoneBoundsRTDBValue(position, size);

            const updateMultiPathcommand = new UpdateMultiPathCommand(prevValues, values);

            const newZoneRect = new Rect(position, size);
            const { removedElementIds, addedElementIds } = operation.getChangeParentElementIdsByNewZoneRect(
                newZoneRect,
                zoneId
            );
            const addElementIdsToZoneCommand = new StickyZoneChangeParentZoneCommand(
                addedElementIds,
                zoneId,
                displayOrderRepository
            );
            const removeElementIdsFromZoneCommand = new StickyZoneChangeParentZoneCommand(
                removedElementIds,
                null,
                displayOrderRepository
            );

            const commands = new CompositeCommand(
                updateMultiPathcommand,
                addElementIdsToZoneCommand,
                removeElementIdsFromZoneCommand
            );

            // 移動開始時と同じ位置・サイズになる場合はUndoスタックを積まずに実行（確定前の状態はリセットするため実行はする）
            if (isEqual(prevValues, values)) {
                commands.do();
            } else {
                commandManager.execute(commands);
            }
        }
    );

    const handleEditingURLChanged = useHandler((url: string | null, previousURL: string | null): void => {
        const command = CommandHelper.buildUpdateCommand(
            RTDBPath.Zone.zoneUrlPath(viewModelId, modelId, zoneKey),
            previousURL,
            url
        );
        commandManager.execute(command);
    });

    const contextValue = useMemo(
        () => ({
            handleEditingTextStart,
            handleEditingTextChanged,
            handleEditingTextConfirmed,
            handleEditingURLChanged,
            handleResize,
            handleResizeEnd,
            myEditing,
        }),
        [
            handleEditingTextStart,
            handleEditingTextChanged,
            handleEditingTextConfirmed,
            handleEditingURLChanged,
            handleResize,
            handleResizeEnd,
            myEditing,
        ]
    );

    return <StickyZoneContext.Provider value={contextValue}>{children}</StickyZoneContext.Provider>;
};
