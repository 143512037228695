import { useEffect, useState } from 'react';
import { WorkspaceMemberRoles } from './vo/WorkspaceMemberRoles';
import { WorkspaceId } from '@schema-common/base';
import { ObjectRepository, RTDBPath } from '@framework/repository';

export const useWorkspaceMemberRoles = (workspaceId: WorkspaceId): WorkspaceMemberRoles | null => {
    const [result, setResult] = useState<WorkspaceMemberRoles | null>(null);

    useEffect(() => {
        const repo = new ObjectRepository(WorkspaceMemberRoles, RTDBPath.Workspace.memberRolesPath(workspaceId));

        repo.addListener((memberRoles) => setResult(memberRoles));

        return () => {
            repo.removeListener();
        };
    }, [workspaceId]);

    return result;
};
