import { ViewKey } from '@view-model/domain/key';
import { ViewEntity } from '@view-model/domain/view';
import { ViewModelOperationLogSender } from '@view-model/models/framework/action-log';
import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { MLAPIRepository } from '@framework/ml-api';
import { StickyZone } from '@view-model/models/sticky/StickyZoneView';
import { StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { ViewModelId, StickyZoneId } from '@schema-common/base';

export class MLAPIOperator {
    // Clipboard と同様にオリジナルのデータを取得する
    private static getPayload(viewModelId: ViewModelId, view: ViewEntity): Promise<MLAPIPayload> {
        const modelCascadingRepository = new ModelCascadeRepository(viewModelId);
        return modelCascadingRepository.load(view.modelId).then((originalModelContents) => {
            if (originalModelContents === undefined)
                throw new Error(`ModelContents(${view.modelKey.toString()}) is not found.`);
            return MLAPIPayload.fromViewAndModelContents(viewModelId, view, originalModelContents);
        });
    }

    private static logPayload(logSender: ViewModelOperationLogSender, apiName: string, payload: MLAPIPayload): void {
        logSender(`view:ml:invoke`, {
            mlAPIName: apiName,
            mlAPIPayloadId: payload.id,
            viewId: new ViewKey(payload.view.key).id,
            viewName: payload.view.name,
        });
    }

    static async outlier(
        viewModelId: ViewModelId,
        view: ViewEntity,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'outlier', payload);

        return MLAPIRepository.outlier(payload).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async cluster(
        viewModelId: ViewModelId,
        view: ViewEntity,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'cluster', payload);

        return MLAPIRepository.cluster(payload).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTCluster(
        viewModelId: ViewModelId,
        view: ViewEntity,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'chatgpt_cluster', payload);

        return MLAPIRepository.chatgpt_cluster(payload).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTOutlier(
        viewModelId: ViewModelId,
        view: ViewEntity,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'chatgpt_outlier', payload);

        return MLAPIRepository.chatgpt_outlier(payload).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTQuestion(
        viewModelId: ViewModelId,
        view: ViewEntity,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'chatgpt_question', payload);

        return MLAPIRepository.chatgpt_question(payload).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTSummary(
        viewModelId: ViewModelId,
        view: ViewEntity,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'chatgpt_summary', payload);

        return MLAPIRepository.chatgpt_summary(payload).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTModeling(
        viewModelId: ViewModelId,
        view: ViewEntity,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'chatgpt_modeling', payload);

        return MLAPIRepository.chatgpt_modeling(payload).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTSimilarNode(
        viewModelId: ViewModelId,
        view: ViewEntity,
        stickyNode: StickyNode,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'chatgpt_similar_node', payload);

        return MLAPIRepository.chatgpt_similar_node(payload, stickyNode.key).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTZoneTitle(
        viewModelId: ViewModelId,
        view: ViewEntity,
        stickyZone: StickyZone,
        logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        this.logPayload(logSender, 'chatgpt_zone_title', payload);

        return MLAPIRepository.chatgpt_zone_title(payload, stickyZone.key).catch((error) => {
            console.error(error);
            return null;
        });
    }

    // ビューから文章（説明パネル）を生成する
    static async chatGPTText(
        viewModelId: ViewModelId,
        view: ViewEntity,
        // ai_verification
        // 検証用にフロントからプロンプトを送れるようにしたいので、ここではプロンプトを受け取る
        // 検証が終わり次第プロンプトは外す想定
        prompt: string
        // 検証中はログは一旦省略
        // logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        // 検証中はログは一旦省略
        // this.logPayload(logSender, 'chatgpt_text', payload);

        return MLAPIRepository.chatgpt_text({ ...payload, prompt }).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTModelingFromText(
        viewModelId: ViewModelId,
        view: ViewEntity,
        // ai_verification
        // 検証用にフロントからプロンプトを送れるようにしたいので、ここではプロンプトを受け取る
        // 検証が終わり次第プロンプトは外す想定
        prompt: string
        // 検証中はログは一旦省略
        // logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        // 検証中はログは一旦省略
        // this.logPayload(logSender, 'chatgpt_modeling_from_text', payload);

        return MLAPIRepository.chatgpt_modeling_from_text({ ...payload, prompt }).catch((error) => {
            console.error(error);
            return null;
        });
    }

    static async chatGPTZoneComparison(
        viewModelId: ViewModelId,
        view: ViewEntity,
        zoneIds: StickyZoneId[],
        // ai_verification
        // 検証用にフロントからプロンプトを送れるようにしたいので、ここではプロンプトを受け取る
        // 検証が終わり次第プロンプトは外す想定
        prompt: string
        // 検証中はログは一旦省略
        // logSender: ViewModelOperationLogSender
    ): Promise<MLAPIPayload | null> {
        const payload = await this.getPayload(viewModelId, view);
        // 検証中はログは一旦省略
        // this.logPayload(logSender, 'chatgpt_zone_comparison', payload);

        return MLAPIRepository.chatgpt_zone_comparison({ ...payload, prompt }, zoneIds).catch((error) => {
            console.error(error);
            return null;
        });
    }
}
