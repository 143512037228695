import { useState } from 'react';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { ViewModelSettingModal, ViewModelDeleteConfirmModal } from '@view-model/ui/components/ViewModel';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { ItemContent } from './ItemContent';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useViewModelCopy } from '../../hooks';
import { MoveToOtherWorkspaceModal } from '../MoveToOtherWorkspaceModal';
import { useCurrentUserId } from '@framework/auth';

type Props = {
    showEditMenu: boolean;
    viewModel: ViewModelEntity;
    workspace: Workspace;
};

type ModalTarget = 'Edit' | 'Delete' | 'MoveToOtherWorkspace';

export const ViewModelItem: React.FC<Props> = ({ showEditMenu, viewModel, workspace }: Props) => {
    const {
        attributes: draggableAttributes,
        listeners,
        setNodeRef,
        transform,
    } = useDraggable({
        id: viewModel.id,
        attributes: { role: 'div' },
    });

    const style = {
        transform: CSS.Translate.toString(transform),
    };

    const name = viewModel.name.value;
    const updatedAt = viewModel.updatedAt;
    const publicReadable = viewModel.isPublicReadable(workspace.setting);
    const [modalTarget, setModalTarget] = useState<ModalTarget | null>(null);

    const handleEdit = () => setModalTarget('Edit');
    const handleDelete = () => setModalTarget('Delete');
    const handleModalClose = () => setModalTarget(null);
    const handleCopy = useViewModelCopy(viewModel);
    const handleMoveToOtherWorkspace = () => setModalTarget('MoveToOtherWorkspace');

    const currentUserId = useCurrentUserId();

    return (
        <>
            <div ref={setNodeRef} style={style} {...draggableAttributes}>
                <ItemContent
                    viewModelId={viewModel.id}
                    publicReadable={publicReadable}
                    name={name}
                    isLocked={viewModel.isLocked}
                    updatedAt={updatedAt}
                    showEditMenu={showEditMenu}
                    onEdit={handleEdit}
                    onCopy={handleCopy}
                    onDelete={handleDelete}
                    onMoveToOtherWorkspace={handleMoveToOtherWorkspace}
                    dragListeners={listeners}
                />
            </div>

            <ViewModelSettingModal
                isOpen={modalTarget === 'Edit'}
                onClose={handleModalClose}
                workspace={workspace}
                viewModel={viewModel}
            />
            <ViewModelDeleteConfirmModal
                viewModel={viewModel}
                isOpen={modalTarget === 'Delete'}
                onClose={handleModalClose}
            />
            {currentUserId && (
                <MoveToOtherWorkspaceModal
                    isOpen={modalTarget === 'MoveToOtherWorkspace'}
                    onClose={handleModalClose}
                    viewModel={viewModel}
                    userId={currentUserId}
                    groupId={workspace.groupId}
                    workspaceId={workspace.id}
                />
            )}
        </>
    );
};
