import { useEffect, useState } from 'react';
import { createGroupEntityRepository, GroupEntity } from '@group/domain';
import { AuthSessionId, GroupId, isValidId, UserId } from '@schema-common/base';
import { RTDBPath, TextRepository } from '@framework/repository';
import { GroupMemberRoleJSON } from '@schema-common/group';
import { useCurrentUserId } from '@framework/auth';
import { GroupIpRestrictionCheckRequest } from '@group/AccessControl';
import { useCurrentSessionId } from '@framework/auth/AuthContext';

type Result = {
    group: GroupEntity | null;
    loading: boolean;
};

const Loading: Result = { group: null, loading: true };
const NotFound: Result = { group: null, loading: false };

type Cleanup = () => void;

const fetchGroupEntity = async (
    groupId: GroupId,
    currentUserId: UserId | null,
    currentSessionId: AuthSessionId | null,
    setState: (state: Result | ((prev: Result) => Result)) => void
): Promise<Cleanup> => {
    const cleanups: (() => void)[] = [];
    const cleanup = () => cleanups.forEach((func) => func());

    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、
     * Not Found として取り扱う。
     */
    if (!isValidId(groupId)) {
        setState(NotFound);
        return cleanup;
    }

    // 未ログイン状態ではグループ・エンティティを取得できない
    if (!currentUserId) {
        setState(NotFound);
        return cleanup;
    }

    // ローディング状態に遷移させる
    setState(Loading);

    let notFound = false;
    const roleRepo = new TextRepository<GroupMemberRoleJSON>(RTDBPath.Group.memberRolePath(groupId, currentUserId));
    const role = await roleRepo.get();

    if (!role) {
        notFound = true;
    }

    roleRepo.addListener((role) => {
        if (!role) {
            // グループのメンバーロールから外された場合には、 Not Found に状態を変更する
            setState(NotFound);
            notFound = true;
        } else {
            // 閲覧できない状態から、閲覧可能な状態に遷移した時には、ページ自体のリロードによって強制的に再描画を行う
            if (notFound) {
                notFound = false;
                window.location.reload();
            }
        }
    });
    cleanups.push(() => roleRepo.removeListener());

    if (notFound) {
        setState(NotFound);
        return cleanup;
    }

    // グループ単位のIPアドレス制限の可否チェックを行い、判定結果が出るまで待つ
    const ipRestrictionCheckResult = await GroupIpRestrictionCheckRequest.listen(
        groupId,
        currentUserId,
        currentSessionId,
        (status, prevStatus) => {
            if (prevStatus === 'Allow' && status === 'Deny') {
                // アクセス許可から禁止に変化した時は、 Not Found 状態に変更する
                setState(NotFound);
                return;
            }

            if (prevStatus === 'Deny' && status === 'Allow') {
                // アクセス禁止から許可に変化した時は、画面自体をリロードする
                window.location.reload();
            }
        },
        cleanups
    );

    if (ipRestrictionCheckResult === 'Deny') {
        setState(NotFound);
        return cleanup;
    }

    const repo = createGroupEntityRepository(groupId);
    repo.addListener((group) => {
        setState(group ? { group, loading: false } : NotFound);
    });
    cleanups.push(() => repo.removeListener());

    return cleanup;
};

/**
 * 指定のグループのエンティティとloadingを返します。
 * エンティティを取得できない場合には、{ group: null } を返します。
 *
 * @param groupId
 * @returns {group: GroupEntity, loading: boolean}
 */
export const useGroupEntity = (groupId: string): Result => {
    const [result, setResult] = useState<Result>(Loading);

    const currentUserId = useCurrentUserId();
    const currentSessionId = useCurrentSessionId();

    useEffect(() => {
        let cleanup: null | (() => void) = null;

        fetchGroupEntity(groupId, currentUserId, currentSessionId, setResult).then((f) => (cleanup = f));

        return () => {
            cleanup?.();
        };
    }, [currentSessionId, currentUserId, groupId]);

    return result;
};
