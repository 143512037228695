import { useCallback, useState } from 'react';
import { UserEntity } from '@user/UserEntity';
import { FlatButton } from '@framework/ui/atoms';
import { Modal } from '@framework/ui/elements';
import { EditPasswordForm } from '@user/EditPasswordForm';
import { toast } from 'react-hot-toast';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Props = {
    isOpen: boolean;
    onClose(): void;
    userEntity: UserEntity;
};

export const PasswordCreationModal: React.FC<Props> = ({ isOpen, onClose, userEntity }: Props) => {
    const [password, setPassword] = useState('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState('');

    const handleChangePassword = useCallback((password: string) => {
        setPassword(password);
    }, []);
    const isEmpty = password === '';

    const handleClose = useCallback(() => {
        setProcessing(false);
        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        if (password === '') return;

        setProcessing(true);

        if (await FunctionsUserActions.createPassword(password)) {
            toast.success('パスワードを設定しました');
            handleClose();
        } else {
            setError('パスワードの設定に失敗しました。時間をあけてもう一度試してみてください。');
            setProcessing(false);
        }
    }, [handleClose, password]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className="flex flex-col">
            {/* header */}
            <div className="px-8 pb-4 pt-8 text-2xl font-bold">パスワードを設定する</div>
            <hr className="w-full" />

            {/* content */}
            <div className="flex flex-col p-8">
                <div className="pb-2 font-bold">メールアドレス (変更できません)</div>
                <input
                    type="text"
                    className="cursor-not-allowed rounded-sm border bg-gray-200 p-2"
                    defaultValue={userEntity.mailAddress}
                    disabled
                />

                <EditPasswordForm autoFocus onChange={handleChangePassword} disabled={processing} />

                {error && <div className="pt-8 text-red-700">{error}</div>}
            </div>

            {/* footer */}
            <hr className="w-full" />
            <div className="flex justify-end px-8 pb-8 pt-4">
                <FlatButton onClick={handleClose}>閉じる</FlatButton>
                <FlatButton
                    color="brand"
                    onClick={handleSubmit}
                    className="ml-2 px-8"
                    disabled={isEmpty || processing}
                    loading={processing}
                >
                    保存
                </FlatButton>
            </div>
        </Modal>
    );
};
