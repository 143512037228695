import { useCallback } from 'react';
import { ViewId } from '@schema-common/base';
import { toast } from 'react-hot-toast';
import { ClipboardUtils } from '@framework/ClipboardUtils';

type Handler = () => void;

export const useCopyViewUrl = (viewId: ViewId): Handler => {
    return useCallback(async () => {
        const query = new URLSearchParams();

        query.set('targetView', viewId);
        const url = `${window.location.origin}${window.location.pathname}?${query.toString()}`;

        try {
            await ClipboardUtils.copy(url);
            toast.success('コピーしました | Copied');
        } catch (e) {
            console.warn('クリップボードへのコピーに失敗しました', e);
            toast.error('コピーに失敗しました | Copy failed');
        }
    }, [viewId]);
};
