import { useRef, useState, MouseEventHandler, KeyboardEventHandler, useLayoutEffect, ChangeEventHandler } from 'react';
import { StickyZoneFontSize, StickyZoneText } from '../../domain';
import { ForeignObjectAutosize2 } from '@view-model/models/common/components/ForeignObjectAutosize';
import { useD3DblClickCallback, useD3MouseDownCallback, useD3MouseUpCallback } from '@view-model/models/common/hooks';
import { flushSync } from 'react-dom';

type Props = {
    size: { width: number; height: number };
    isMyEditing: boolean; // 自分が編集中かどうか(RTDB側の値)
    otherUserEditing: boolean;
    textSelectable: boolean;
    text: StickyZoneText;
    fontSize: StickyZoneFontSize;
    isHyperLink: boolean;
    onEditStart(): void;
    onChange(text: StickyZoneText): void;
    onEditEnd(text: StickyZoneText): void;
};

type State = {
    isEditing: boolean;
    editingText: string;
};

export const StickyZoneTextarea: React.FC<Props> = ({
    size,
    isMyEditing,
    otherUserEditing,
    textSelectable,
    text,
    fontSize,
    isHyperLink,
    onChange,
    onEditStart,
    onEditEnd,
}: Props) => {
    const textRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const { width } = size;

    const [state, setState] = useState<State>({
        isEditing: false,
        editingText: '',
    });

    const startEdit = (selection?: { start: number; end: number }) => {
        onEditStart();

        // flushSyncでtextareaがレンダリングされるのを待つ
        flushSync(() => {
            setState((prev) => (prev.isEditing ? prev : { ...prev, isEditing: true, editingText: text.value }));
        });
        const textarea = textareaRef.current;

        if (!textarea) {
            console.warn('Textarea is not rendered yet. This should not happen.');
            return;
        }

        textarea.focus();

        if (selection) {
            textarea.setSelectionRange(selection.start, selection.end);
        } else {
            const length = textarea.value.length;
            textarea.setSelectionRange(length, length);
        }
    };

    // テキスト要素のダブルクリックイベントを紐付ける
    useD3DblClickCallback(textRef, () => startEdit(), true);

    // 通常時(非編集時)のテキスト領域のクリック、ドラッグイベントを紐付ける
    // ゾーンのテキスト表示時にドラッグでテキスト選択できるように、(ゾーン自体が移動しないように)イベント伝搬を止める
    useD3MouseDownCallback(textRef, () => void 0, true);
    useD3MouseUpCallback(
        textRef,
        async () => {
            if (!textSelectable) return;
            // ユーザがテキスト上のクリック or ドラッグで選択していれば、その範囲をstartEditに渡す
            const selection = window.getSelection();
            if (selection && selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                startEdit({
                    start: range.startOffset,
                    end: range.endOffset,
                });
            } else {
                startEdit();
            }
        },
        true
    );

    const handleKeyDown: KeyboardEventHandler = (event) => {
        const withMetaKey = event.ctrlKey || event.metaKey;
        if (event.key == 'Enter' && withMetaKey && textareaRef.current) {
            textareaRef.current.blur();
        }
    };

    const handleMouseDownCapture: MouseEventHandler = (event) => {
        // テキスト選択できるようにイベント伝搬を止める
        event.stopPropagation();
    };

    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
        const value = event.target.value;
        const newText = new StickyZoneText(value);
        setState((prev) => ({ ...prev, editingText: value }));
        onChange(newText);
    };

    const handleBlur = () => {
        const newText = new StickyZoneText(state.editingText);
        setState((prev) => ({ ...prev, isEditing: false }));
        onEditEnd(newText);
    };

    const textValue = state.isEditing ? state.editingText : text.value;

    useLayoutEffect(() => {
        // 高さ調整
        const textarea = textareaRef.current;
        if (textarea) {
            textarea.style.height = textarea.scrollHeight + 'px';
        }
    });

    return (
        <ForeignObjectAutosize2>
            <div style={{ width: width, fontSize: StickyZoneFontSize.getFontSize(fontSize) }}>
                <div
                    className="size-full items-start justify-center"
                    style={{
                        display: !state.isEditing ? 'none' : 'flex',
                    }}
                >
                    <textarea
                        ref={textareaRef}
                        className="mx-6 mb-6 mt-10 resize-none border-none bg-transparent text-center align-middle font-bold outline-none"
                        value={textValue}
                        placeholder={'タイトル | Title'}
                        rows={1}
                        onKeyDown={handleKeyDown}
                        onMouseDownCapture={handleMouseDownCapture}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly={!isMyEditing}
                        style={{ width: 'calc(100% - 48px)' }}
                    />
                </div>
                <div
                    ref={textRef}
                    className={
                        isHyperLink
                            ? 'mx-6 mb-6 mt-10 items-start justify-center whitespace-break-spaces break-all text-center font-bold text-brand underline'
                            : 'mx-6 mb-6 mt-10 items-start justify-center whitespace-break-spaces break-all text-center font-bold text-black no-underline'
                    }
                    style={{
                        width: 'calc(100% - 48px)',
                        display: state.isEditing ? 'none' : 'flex',
                        cursor: textSelectable ? (!otherUserEditing ? 'text' : 'not-allowed') : 'default',
                    }}
                >
                    {textSelectable ? text.value || 'タイトル | Title' : text.value}
                </div>
            </div>
        </ForeignObjectAutosize2>
    );
};
