import { useCallback, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { classNames } from '@framework/utils';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Props = {
    temporaryId: string;
    setTemporaryId: (temporaryId: string) => void;
};

export const MultiFactorAuthForm: React.FC<Props> = ({ temporaryId, setTemporaryId }: Props) => {
    const [oneTimePassword, setOneTimePassword] = useState('');
    const [oneTimePasswordError, setOneTimePasswordError] = useState('');
    const [multiFactorAuthProcessing, setMultiFactorAuthProcessing] = useState(false);
    const isOneTimePasswordSendable = oneTimePassword && oneTimePasswordError === '';

    const handleChangeOneTimePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        setOneTimePassword(email);
    }, []);

    const handleBlurOneTimePassword = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setOneTimePasswordError('認証コードを入力してください');
        } else {
            setOneTimePasswordError('');
        }
    }, []);

    const handleOneTimePasswordSubmit = useCallback(() => {
        if (temporaryId == '' || oneTimePassword == '') {
            return;
        }
        setMultiFactorAuthProcessing(true);
        setOneTimePasswordError('');

        FunctionsUserActions.oneTimePasswordVerification(oneTimePassword, temporaryId)
            .then((token) => {
                if (token) {
                    return FirebaseAuthenticationClient.signInWithCustomToken(token);
                } else {
                    setOneTimePasswordError(
                        'ログインに失敗しました。ワンタイムパスワードに誤りが無いか、もう一度確認してください。'
                    );
                }
            })
            .catch((err) => {
                console.error(err);
                setOneTimePasswordError(
                    'システムエラーによりログインに失敗しました。しばらく時間を置いてから再度お試しください。'
                );
            })
            .finally(() => {
                setMultiFactorAuthProcessing(false);
            });
    }, [temporaryId, oneTimePassword]);

    const handleBackToLoginWithEmailForm = useCallback(() => {
        setTemporaryId('');
        setOneTimePassword('');
        setOneTimePasswordError('');
        setMultiFactorAuthProcessing(false);
    }, [setTemporaryId]);

    return (
        <>
            <div className="flex w-80 flex-col px-5 py-2">
                <div
                    className="cursor-pointer font-bold text-brand hover:underline"
                    onClick={handleBackToLoginWithEmailForm}
                >
                    &lt; 戻る
                </div>
                <div className="pb-2 pt-4 font-bold">認証コード</div>
                <input
                    type="text"
                    value={oneTimePassword}
                    onChange={handleChangeOneTimePassword}
                    onBlur={handleBlurOneTimePassword}
                    className={classNames('w-full rounded-sm border p-2', oneTimePasswordError ? 'border-red-700' : '')}
                    autoFocus
                    disabled={multiFactorAuthProcessing}
                />
                {oneTimePasswordError && <div className="text-sm text-red-700">{oneTimePasswordError}</div>}
                <div className="pt-4" />
                <FlatButton
                    color="brand"
                    onClick={handleOneTimePasswordSubmit}
                    disabled={!isOneTimePasswordSendable || multiFactorAuthProcessing}
                    loading={multiFactorAuthProcessing}
                >
                    送信する
                </FlatButton>
            </div>
            <div className="mt-8">
                ご登録のメールアドレスに認証コードをお送りしました。
                <br />
                <br />※ 認証コードが有効期限切れとなった場合、お手数ですが
                <br />
                <button
                    onClick={handleBackToLoginWithEmailForm}
                    className="text-blue-700 hover:underline focus:outline-none"
                >
                    こちら
                </button>
                より再度ログイン操作をやり直してください。
            </div>
        </>
    );
};
