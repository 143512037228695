import { useCallback, useEffect, useState } from 'react';
import { useActionLogSender } from '@framework/action-log';
import { Loading } from '@framework/ui/atoms';
import { Button } from '@framework/ui/atoms/Button';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { GroupId, UserId, WorkspaceId } from '@schema-common/base';
import { ViewModelEntity, ViewModelOperation } from '@view-model/domain/view-model';
import { Workspace, WorkspaceMemberRole, WorkspaceMemberRoles } from '@workspace/domain/workspace';
import { useAssignedWorkspaces } from '@workspace/ui/WorkspaceList';
import { ObjectRepository, RTDBPath } from '@framework/repository';

type WorkspaceData = { workspace: Workspace; roles: WorkspaceMemberRoles };

const fetchWorkspaceMembers = async (workspaces: Workspace[]): Promise<WorkspaceData[]> => {
    const result: WorkspaceData[] = [];

    await Promise.all(
        workspaces.map(async (workspace) => {
            const repo = new ObjectRepository(WorkspaceMemberRoles, RTDBPath.Workspace.memberRolesPath(workspace.id));
            const roles = (await repo.get()) || WorkspaceMemberRoles.load({});
            result.push({ workspace, roles });
        })
    );

    return result;
};

type Props = {
    isOpen: boolean;
    userId: UserId;
    groupId: GroupId;
    workspaceId: WorkspaceId;
    viewModel: ViewModelEntity;
    onClose(): void;
};

export const MoveToOtherWorkspaceModal: React.FC<Props> = ({
    isOpen,
    userId,
    groupId,
    workspaceId,
    viewModel,
    onClose,
}: Props) => {
    const actionLogSender = useActionLogSender();
    const allWorkspaces = useAssignedWorkspaces(userId, groupId);

    const [workspaces, setWorkspaces] = useState<Workspace[] | null>(null);

    useEffect(() => {
        if (allWorkspaces === null || userId === null) {
            setWorkspaces(null);
            return;
        }

        fetchWorkspaceMembers(allWorkspaces).then((wsWithRoles) => {
            setWorkspaces(
                wsWithRoles
                    .filter(({ workspace }) => workspace.isShared)
                    .filter(({ roles }) => {
                        const role = roles.getRoleOf(userId);
                        return role && [WorkspaceMemberRole.admin, WorkspaceMemberRole.editor].includes(role);
                    })
                    .filter(({ workspace }) => workspace.id !== workspaceId)
                    .map(({ workspace }) => workspace)
                    .sort(Workspace.compare)
            );
        });
    }, [allWorkspaces, userId, workspaceId]);

    const [destinationWorkspaceId, setDestinationWorkspaceId] = useState<WorkspaceId | null>(null);
    useEffect(() => {
        setDestinationWorkspaceId(null);
    }, [isOpen]);

    const [processing, setProcessing] = useState<boolean>(false);
    const canMove = destinationWorkspaceId !== null;

    const handleClose = useCallback(() => onClose(), [onClose]);
    const handleMove = useCallback(async () => {
        if (destinationWorkspaceId === null) {
            alert('移動先のワークスペースを選択してください。');
            return;
        }

        setProcessing(true);
        try {
            await ViewModelOperation.moveToOtherWorkspace(
                workspaceId,
                destinationWorkspaceId,
                viewModel.id,
                actionLogSender
            );
            setDestinationWorkspaceId(null);
            onClose();
        } finally {
            setProcessing(false);
        }
    }, [actionLogSender, destinationWorkspaceId, onClose, viewModel.id, workspaceId]);

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={'ビューモデルを別のワークスペースに移動'}
            submitButton={
                <Button onClick={handleMove} disabled={!canMove} loading={processing}>
                    移動
                </Button>
            }
            cancelButton={
                <Button onClick={handleClose} color={'secondary'}>
                    キャンセル
                </Button>
            }
        >
            <div className="p-2" style={{ width: '600px' }}>
                ビューモデル「{viewModel.name.value}」の移動先ワークスペースを選択してください。
                <br />
                <span className="text-sm">
                    ※ 移動後にサイドバーに表示されない場合にはページの再読み込みを行なってください。
                </span>
                <hr className="mt-2" />
                {workspaces === null ? (
                    <Loading />
                ) : workspaces.length === 0 ? (
                    '移動可能なワークスペースが見つかりませんでした。'
                ) : (
                    <div className="mb-4 mt-2 overflow-y-auto" style={{ minHeight: '250px', maxHeight: '50vh' }}>
                        {workspaces.map((workspace) => (
                            <div key={workspace.id}>
                                <label className="block cursor-pointer rounded p-2 hover:bg-gray-200">
                                    <input
                                        type="radio"
                                        name="destination-workspace"
                                        className="form-radio mr-1 size-6 border-brand"
                                        checked={workspace.id === destinationWorkspaceId}
                                        onChange={() => setDestinationWorkspaceId(workspace.id)}
                                        disabled={processing}
                                    />
                                    {workspace.name.toString()}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </GeneralModal>
    );
};
