import { GroupEntity } from '@group/domain';

import { CenteredContent } from '@user/pages/HomePage/CenteredContent';
import { GroupList } from '@user/pages/HomePage/GroupList';
import { useState } from 'react';
import { CreateGroup } from './CreateGroup';
import { FlatButton } from '@framework/ui/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

type ContentProps = {
    title: string;
    children: React.ReactNode;
    hasIpRestrictionBlocked?: boolean;
};

const Content: React.FC<ContentProps> = ({ title, children, hasIpRestrictionBlocked }: ContentProps) => {
    return (
        <div
            className="rounded border border-gray-500 bg-white shadow"
            style={{
                width: '638px',
                minHeight: '300px',
            }}
        >
            <div className="border-b border-gray-500 px-4 py-2 text-xl">{title}</div>
            <div
                className="overflow-y-auto p-4"
                style={{
                    maxHeight: '60vh',
                }}
            >
                {children}
            </div>

            {hasIpRestrictionBlocked && (
                <div className="mt-4 border-t p-4 text-sm">
                    ※
                    IPアドレス制限の設定によりアクセスできないグループは非表示となっています。許可されたネットワークからアクセスし直してください。
                </div>
            )}
        </div>
    );
};

type Props = {
    groups: GroupEntity[];
    hasIpRestrictionBlocked: boolean;
};

export const GroupSelection: React.FC<Props> = ({ groups, hasIpRestrictionBlocked }: Props) => {
    const [createNew, setCreateNew] = useState<boolean>(false);

    const handleCreateNew = () => {
        // 表示切り替え（グループ選択 → グループ作成）
        setCreateNew(true);
    };

    const handleBackToGroupSelect = () => {
        // 表示切り替え（グループ作成 → グループ選択）
        setCreateNew(false);
    };

    return (
        <div className="size-full bg-gray-100">
            <CenteredContent>
                {createNew || groups.length === 0 ? (
                    <Content title={'グループを作成'}>
                        <CreateGroup groupCount={groups.length} handleBackToGroupSelect={handleBackToGroupSelect} />
                    </Content>
                ) : (
                    <Content title={'グループを選択してください'} hasIpRestrictionBlocked={hasIpRestrictionBlocked}>
                        <GroupList groups={groups} />
                        <FlatButton color="brand" className="mt-2 w-56 px-3 py-2" onClick={handleCreateNew}>
                            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
                            新規グループ
                        </FlatButton>
                    </Content>
                )}
            </CenteredContent>
        </div>
    );
};
