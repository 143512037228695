import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { MLAPIConfig } from '@config/MLAPIConfig';
import { NodeKey, StickyZoneKey } from '@view-model/domain/key';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { StickyZoneId } from '@schema-common/base';

export class MLAPIRepository {
    private static async invoke(endpoint: string, payload: MLAPIPayload): Promise<MLAPIPayload> {
        const token = await FirebaseAuthenticationClient.getIdToken();
        if (!token) {
            throw new Error('JWT token is required to invoke ML APIs.');
        }

        return fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        }).then((response) => {
            return response.json().then((data) => data as MLAPIPayload);
        });
    }

    static async outlier(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.outlier, payload);
    }

    static async cluster(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.cluster, payload);
    }

    static async chatgpt_cluster(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_cluster, payload);
    }

    static async chatgpt_outlier(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_outlier, payload);
    }

    static async chatgpt_question(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_question, payload);
    }

    static async chatgpt_summary(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_summary, payload);
    }

    static async chatgpt_modeling(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_modeling, payload);
    }

    static async chatgpt_similar_node(payload: MLAPIPayload, nodeKey: NodeKey): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_similar_node + nodeKey, payload);
    }

    static async chatgpt_zone_title(payload: MLAPIPayload, stickyZoneKey: StickyZoneKey): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_zone_title + stickyZoneKey, payload);
    }

    static async chatgpt_text(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_text, payload);
    }

    static async chatgpt_modeling_from_text(payload: MLAPIPayload): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_modeling_from_text, payload);
    }

    static async chatgpt_zone_comparison(payload: MLAPIPayload, zoneIds: StickyZoneId[]): Promise<MLAPIPayload> {
        return this.invoke(MLAPIConfig.chatgpt_zone_comparison + zoneIds.join(','), payload);
    }
}
