import { useCallback, useState } from 'react';
import { Loading } from '@framework/ui/atoms/Loading';
import { LoginMethodMenu } from './LoginMethodMenu';
import { useLoginErrorMessage } from './useLoginErrorMessage';
import { useLoginWithOAuth, ProviderName } from './useLoginWithOAuth';
import { LoginWithEmailFormContainer } from './LoginWithEmailFormContainer';

type Props = {
    allowSignUp?: boolean;
};

export const LoginForm: React.FC<Props> = ({ allowSignUp = false }: Props) => {
    const [processing, setProcessing] = useState(false);
    const loginWithOAuth = useLoginWithOAuth(allowSignUp ? 'signUp' : 'login');

    const handleLoginWithOAuth = useCallback(
        (providerName: ProviderName) => {
            setProcessing(true);
            loginWithOAuth(providerName);
        },
        [loginWithOAuth]
    );

    const handleLoginWithGoogle = useCallback(() => handleLoginWithOAuth('google'), [handleLoginWithOAuth]);
    const handleLoginWithQuadcept = useCallback(() => handleLoginWithOAuth('quadcept'), [handleLoginWithOAuth]);

    const [showLoginWithEmailForm, setShowLoginWithEmailForm] = useState<boolean>(false);
    const handleLoginWithEmail = useCallback(() => setShowLoginWithEmailForm(true), []);
    const handleCloseLoginWithEmailForm = useCallback(() => setShowLoginWithEmailForm(false), []);

    const loginErrorMessage = useLoginErrorMessage();

    if (showLoginWithEmailForm) {
        return <LoginWithEmailFormContainer onClose={handleCloseLoginWithEmailForm} />;
    }

    if (processing) {
        return (
            <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                <Loading message="ログインしています..." />
            </div>
        );
    }

    return (
        <>
            <LoginMethodMenu
                onLoginWithGoogle={handleLoginWithGoogle}
                onLoginWithQuadcept={handleLoginWithQuadcept}
                onLoginWithEmail={handleLoginWithEmail}
            />

            {loginErrorMessage && <div className="w-1/2 min-w-80 text-red-700">{loginErrorMessage}</div>}
        </>
    );
};
