import { Workspace, WorkspaceMemberRole } from '@workspace/domain/workspace';
import { generateDefaultIcon } from '@framework/utils';
import { WorkspaceRoleSelect } from './WorkspaceRoleSelect';
import { Link } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { WorkspaceId } from '@schema-common/base/id';

type Props = {
    workspaces: Workspace[];
    selectedWorkspaces: SelectedWorkspaceItem[];
    onChange: (workspaces: SelectedWorkspaceItem[]) => void;
};

export type SelectedWorkspaceItem = {
    workspaceId: WorkspaceId;
    role: WorkspaceMemberRole;
};

export const InvitePageWorkspaceList: React.FC<Props> = ({ workspaces, selectedWorkspaces, onChange }: Props) => {
    const handleCheckListChange = (workspaceId: WorkspaceId, role?: WorkspaceMemberRole) => {
        const workspaces = [...selectedWorkspaces];
        const index = workspaces.findIndex((item) => item.workspaceId === workspaceId);
        if (index !== -1) {
            if (role) {
                workspaces[index].role = role;
            } else {
                workspaces.splice(index, 1);
            }
        } else {
            workspaces.push({ workspaceId, role: WorkspaceMemberRole.viewer });
        }
        onChange(workspaces);
    };

    return (
        <div className="max-h-125 overflow-y-scroll border-2 border-gray-300">
            {workspaces.map((workspace) => (
                <div key={workspace.key.toString()}>
                    <WorkspaceListItem
                        workspace={workspace}
                        role={selectedWorkspaces.find((item) => item.workspaceId === workspace.id)?.role}
                        isChecked={selectedWorkspaces.some((item) => item.workspaceId === workspace.id)}
                        onChange={handleCheckListChange}
                    />
                </div>
            ))}
        </div>
    );
};

type WorkspaceListItemProps = {
    workspace: Workspace;
    role?: WorkspaceMemberRole;
    isChecked: boolean;
    onChange: (workspaceId: WorkspaceId, role?: WorkspaceMemberRole) => void;
};

const WorkspaceListItem: React.FC<WorkspaceListItemProps> = ({
    workspace,
    role,
    isChecked,
    onChange,
}: WorkspaceListItemProps) => {
    const name = workspace.name.toString();
    const imageUrl = workspace.imageUrl;

    const handleOnRoleChange = (role: WorkspaceMemberRole) => {
        onChange(workspace.id, role);
    };

    const handleOnCheckBoxChange = () => {
        onChange(workspace.id);
    };

    return (
        <div className="flex justify-between border-b border-gray-300 px-4 py-2 hover:bg-gray-100">
            <div className="flex items-center">
                <input
                    type="checkbox"
                    className="form-checkbox size-5"
                    checked={isChecked}
                    onChange={handleOnCheckBoxChange}
                />
                <div className="pr-2" />
                <span className="size-10 overflow-hidden rounded-full">
                    {imageUrl ? (
                        <img className={'size-10 overflow-hidden rounded-full'} src={imageUrl} alt={name} />
                    ) : (
                        <div className={'size-10 overflow-hidden rounded-full'}>
                            {generateDefaultIcon({ id: workspace.id, name: workspace.name.toString(), size: 40 })}
                        </div>
                    )}
                </span>
                <Link
                    to={UserPagePathBuilder.workspacePage(workspace.id)}
                    target="_blank"
                    rel="noreferrer"
                    className="w-96 truncate px-2 text-lg font-bold leading-10 text-brand underline"
                >
                    {name}
                </Link>
            </div>
            <WorkspaceRoleSelect
                value={role || WorkspaceMemberRole.viewer}
                onChange={handleOnRoleChange}
                disabled={!isChecked}
            />
        </div>
    );
};
