import { useEffect, useState } from 'react';
import { createWorkspaceEntityRepository, WorkspaceEntity, WorkspaceSetting } from '@workspace/domain/workspace';
import { ObjectRepository, RTDBPath, TextRepository } from '@framework/repository';
import { AuthSessionId, UserId, WorkspaceId, isValidId } from '@schema-common/base';
import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';
import { useCurrentUserId } from '@framework/auth';
import { GroupIpRestrictionCheckRequest } from '@group/AccessControl';
import { useCurrentSessionId } from '@framework/auth/AuthContext';

type Result = {
    workspace: WorkspaceEntity | null;
    accessible: boolean; // 対象ビューモデルに権限的にアクセス可能な時に true を返す
    loading: boolean; // ローディング中に true を返す
};

const Loading: Result = { workspace: null, accessible: false, loading: true };
const NotFound: Result = { workspace: null, accessible: false, loading: false };
const Accessible: Result = { workspace: null, accessible: true, loading: false };

type Cleanup = () => void;

const fetchWorkspaceEntity = async (
    workspaceId: WorkspaceId,
    currentUserId: UserId | null,
    currentSessionId: AuthSessionId | null,
    setState: (state: Result | ((prev: Result) => Result)) => void
): Promise<Cleanup> => {
    const cleanups: (() => void)[] = [];
    const cleanup = () => cleanups.forEach((func) => func());

    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、
     * Not Found として取り扱う。
     */
    if (!isValidId(workspaceId)) {
        setState(NotFound);
        return cleanup;
    }

    // ローディング状態に遷移させる
    setState(Loading);

    // ワークスペースのグループIDは変化しないので、一度取得して完了で良い
    const groupId = await new TextRepository(RTDBPath.Workspace.workspaceGroupIdPath(workspaceId)).get();

    // ワークスペースの groupId プロパティの値が見つからない場合には Not Found 扱い
    if (!groupId) {
        setState(NotFound);
        return cleanup;
    }

    let notFound = false;

    const startListen = async (workspaceId: WorkspaceId): Promise<void> => {
        const roleRepo = currentUserId
            ? new TextRepository<WorkspaceMemberRoleJSON>(RTDBPath.Workspace.memberRolePath(workspaceId, currentUserId))
            : null;
        const settingRepo = new ObjectRepository(WorkspaceSetting, RTDBPath.Workspace.settingPath(workspaceId));

        const role = roleRepo ? await roleRepo.get() : null;
        const setting = await settingRepo.get();

        // ワークスペースの設定が存在しないとき、エンティティに対するRead権限が無い時には、ロード完了状態に遷移させる
        if (setting === null || !setting.canReadEntity(role)) {
            setState(NotFound);
            notFound = true;
        }

        const listenerCallback = (setting: WorkspaceSetting | null, role: WorkspaceMemberRoleJSON | null) => {
            if (setting === null || !setting.canReadEntity(role)) {
                // 閲覧できない場合には、状態を Not Found に遷移させる
                setState(NotFound);
                notFound = true;
            } else {
                // 閲覧できない状態から、閲覧可能な状態に遷移した時には、ページ自体のリロードによって強制的に再描画を行う
                if (notFound) {
                    notFound = false;
                    window.location.reload();
                }
            }
        };

        if (roleRepo) {
            roleRepo.addListener((role) => listenerCallback(setting, role));
            cleanups.push(() => roleRepo.removeListener());
        }

        settingRepo.addListener((setting) => listenerCallback(setting, role));
        cleanups.push(() => settingRepo.removeListener());
    };

    await startListen(workspaceId);

    if (notFound) {
        setState(NotFound);
        return cleanup;
    }

    // グループ単位のIPアドレス制限の可否チェックを行い、判定結果が出るまで待つ
    const ipRestrictionCheckResult = await GroupIpRestrictionCheckRequest.listen(
        groupId,
        currentUserId,
        currentSessionId,
        (status, prevStatus) => {
            if (prevStatus === 'Allow' && status === 'Deny') {
                // アクセス許可から禁止に変化した時は、 Not Found 状態に変更する
                setState(NotFound);
                return;
            }

            if (prevStatus === 'Deny' && status === 'Allow') {
                // アクセス禁止から許可に変化した時は、画面自体をリロードする
                window.location.reload();
            }
        },
        cleanups
    );

    if (ipRestrictionCheckResult === 'Deny') {
        setState(NotFound);
        return cleanup;
    }

    // 未ログイン状態ならば、「アクセス可能である」というフラグのみを返す
    if (!currentUserId || !currentSessionId) {
        setState(Accessible);
        return cleanup;
    }

    // ワークスペース・エンティティを取得する
    const wsRepo = createWorkspaceEntityRepository(workspaceId);
    wsRepo.addListener((workspace) => {
        if (workspace) {
            setState((prev) => {
                if (prev.workspace?.isEqual(workspace)) return prev;
                return {
                    workspace,
                    accessible: true,
                    loading: false,
                };
            });
        } else {
            setState(NotFound);
        }
    });
    cleanups.push(() => wsRepo.removeListener());

    return cleanup;
};

export const useWorkspaceEntity = (workspaceId: WorkspaceId): Result => {
    const [result, setResult] = useState<Result>(Loading);

    const currentUserId = useCurrentUserId();
    const currentSessionId = useCurrentSessionId();

    useEffect(() => {
        let cleanup: null | (() => void) = null;

        fetchWorkspaceEntity(workspaceId, currentUserId, currentSessionId, setResult).then((f) => (cleanup = f));

        return () => {
            cleanup?.();
        };
    }, [currentSessionId, currentUserId, workspaceId]);

    return result;
};
