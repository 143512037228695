import { useCallback, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { Loading } from '@framework/ui/atoms/Loading';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { useGroupMemberUserIds } from '@group/hooks';
import { WorkspaceEntity, WorkspaceMemberRole, WorkspaceMemberRoles } from '@workspace/domain/workspace';
import { MemberAddList } from './MemberAddList';
import { RoleSelect } from './RoleSelect';
import { classNames } from '@framework/utils';
import { difference } from 'lodash';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { toast } from 'react-hot-toast';

type Props = {
    isOpen: boolean;
    onClose(): void;
    workspace: WorkspaceEntity;
    memberRoles: WorkspaceMemberRoles;
};

export const MemberAddModal: React.FC<Props> = ({ isOpen, onClose, workspace, memberRoles }: Props) => {
    const groupMemberUserIds = useGroupMemberUserIds(workspace.groupId);
    const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const [role, setRole] = useState<WorkspaceMemberRole>(WorkspaceMemberRole.admin);
    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const changed = selectedUserIds.length > 0;

    const handleChangeUserIds = useCallback((userIds: string[]) => setSelectedUserIds(userIds), []);
    const handleChangeRole = useCallback((role: WorkspaceMemberRole) => setRole(role), []);

    const handleClose = useCallback(() => {
        setProcessing(false);
        setErrorMessage('');
        setSelectedUserIds([]);
        setRole(WorkspaceMemberRole.admin);
        setIsSelectedAll(false);

        onClose();
    }, [onClose]);

    const handleSubmit = useCallback(async () => {
        setProcessing(true);
        setErrorMessage('');

        const result = await WorkspaceOperation.addMembers(workspace.id, workspace.groupId, selectedUserIds, role);

        if (result) {
            handleClose();
        } else {
            toast.error('メンバー追加に失敗しました');
        }
    }, [workspace.id, workspace.groupId, selectedUserIds, role, handleClose]);

    const submitButton = (
        <Button color="primary" onClick={handleSubmit} disabled={!changed || processing} loading={processing}>
            追加
        </Button>
    );

    const cancelButton = (
        <Button color="secondary" onClick={handleClose} disabled={processing}>
            キャンセル
        </Button>
    );

    const handleChangeSelectedAll = () => {
        if (!groupMemberUserIds) {
            return;
        }
        const workspaceUserIds = memberRoles.userIds();
        const availableUserIds = difference(groupMemberUserIds, workspaceUserIds);

        setSelectedUserIds(isSelectedAll ? [] : availableUserIds);
        setIsSelectedAll(!isSelectedAll);
    };

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={`${workspace.name.value}にメンバーを追加する`}
            submitButton={submitButton}
            cancelButton={cancelButton}
        >
            <div className="mb-4 mt-2 p-2" style={{ width: '600px' }}>
                {groupMemberUserIds ? (
                    <>
                        <label className="mb-5 flex grow cursor-pointer flex-row px-5">
                            <input
                                type="checkbox"
                                className={classNames(
                                    'form-checkbox mr-1 h-6 w-6 border-brand',
                                    processing ? 'cursor-not-allowed bg-gray-300' : 'cursor-pointer'
                                )}
                                onChange={handleChangeSelectedAll}
                                checked={isSelectedAll}
                                disabled={processing}
                            />
                            <span className="flex h-6 grow truncate px-2 leading-6">全員を選択/選択解除</span>
                        </label>
                        <div className="my-2 overflow-y-auto" style={{ minHeight: '200px', maxHeight: '50vh' }}>
                            <MemberAddList
                                workspaceUserIds={memberRoles.userIds()}
                                groupUserIds={groupMemberUserIds}
                                selectedUserIds={selectedUserIds}
                                disabled={processing}
                                onChange={handleChangeUserIds}
                            />
                        </div>
                        {selectedUserIds.length > 0 ? (
                            <div className="py-2 pl-10">
                                上記で選択した{selectedUserIds.length}名を
                                <RoleSelect role={role} onChange={handleChangeRole} disabled={processing} />
                                として追加します。
                            </div>
                        ) : (
                            <div className="h-12 py-2"></div>
                        )}
                    </>
                ) : (
                    <Loading />
                )}
            </div>
            {errorMessage && <div className="p-2 text-red-700">{errorMessage}</div>}
        </GeneralModal>
    );
};
