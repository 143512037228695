import { TimeDisplay } from './TimeDisplay';
import { classNames } from '@framework/utils';
import { useEffect, useState, useRef } from 'react';

type Props = {
    totalSeconds: number;
    isExpired: boolean;
    isEditable: boolean;
    onMinutesBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onSecondsBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    isEditingMinutes?: boolean;
    isEditingSeconds?: boolean;
    onMinutesClick?: () => void;
    onSecondsClick?: () => void;
};

const formatTimeValue = (value: number): { formattedMinutes: string; formattedSeconds: string } => {
    const minutes = Math.floor(value / 60);
    const seconds = value % 60;
    return {
        formattedMinutes: minutes.toString().padStart(2, '0'),
        formattedSeconds: seconds.toString().padStart(2, '0'),
    };
};

export const TimeDisplayContainer: React.FC<Props> = ({
    totalSeconds,
    onMinutesBlur = () => void 0,
    onSecondsBlur = () => void 0,
    isExpired,
    isEditable,
    isEditingMinutes = false,
    isEditingSeconds = false,
    onMinutesClick = () => void 0,
    onSecondsClick = () => void 0,
}: Props) => {
    const { formattedMinutes, formattedSeconds } = formatTimeValue(totalSeconds);
    const [animationKey, setAnimationKey] = useState(0);
    const animationExecutedRef = useRef(false);

    // inactiveなタブなどではタイマーが終了したときにアニメーションが実行されないため、inactiveなタブでもアニメーションが実行されるようにする
    useEffect(() => {
        const animationExecuted = animationExecutedRef.current;
        if (isExpired && !animationExecuted) {
            setAnimationKey((prev) => prev + 1);
            animationExecutedRef.current = true;
        } else if (!isExpired) {
            animationExecutedRef.current = false;
        }
    }, [isExpired]);

    return (
        <>
            <style>
                {`@keyframes blinkEffect {
                    0% {
                        opacity: 1;
                    }
                    50%  {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }`}
            </style>
            <div
                key={animationKey}
                className={classNames('mx-3 flex text-3xl font-medium', isExpired && 'text-orange-500')}
                style={{
                    ...(isExpired
                        ? {
                              animation: 'blinkEffect 1s 5',
                          }
                        : {}),
                }}
            >
                <TimeDisplay
                    value={formattedMinutes}
                    onBlur={onMinutesBlur}
                    isEditable={isEditable}
                    isEditing={isEditingMinutes}
                    onClick={onMinutesClick}
                />
                <span className={classNames('flex w-[1ch] items-center justify-center')}>:</span>
                <TimeDisplay
                    value={formattedSeconds}
                    onBlur={onSecondsBlur}
                    isEditable={isEditable}
                    isEditing={isEditingSeconds}
                    onClick={onSecondsClick}
                    isSeconds={true}
                />
            </div>
        </>
    );
};
