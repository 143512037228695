import { Loading } from '@framework/ui/atoms';
import { classNames } from '@framework/utils';
import { GroupId, ViewId, ViewModelId } from '@schema-common/base';
import { useGetTransformedVisibleAreaCenterPoint } from '@user/pages/ViewModelPage';
import { useCurrentUserPublicProfile } from '@user/PublicProfile';
import { ViewModelAsset } from '@view-model/domain/view-model';
import { Rect } from '@view-model/models/common/basic';
import { ForeignObjectAutosize2 } from '@view-model/models/common/components/ForeignObjectAutosize';
import { Size } from '@view-model/models/common/types/ui';
import { useStickyModelContentsOperation } from '@view-model/ui/components/Model';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-hot-toast';

export type DragFileTarget = {
    viewId: ViewId;
};

export const initialDragFileTarget: DragFileTarget = {
    viewId: '',
};

type EnterAction = {
    type: 'Enter';
    payload: {
        viewId: ViewId;
    };
};
type ExitAction = {
    type: 'Exit';
    payload: {
        viewId: ViewId;
    };
};

export type DragFileTargetActions = EnterAction | ExitAction;

export const dragFileTargetReducer = (state: DragFileTarget, action: DragFileTargetActions): DragFileTarget => {
    switch (action.type) {
        case 'Enter':
            if (state.viewId === action.payload.viewId) {
                // 同じならviewIdを初期化する
                // （同一View内で、一度DnDエリアが表示され、そのDnDエリアから抜ける際にDragEnterが発火した場合）
                return {
                    ...state,
                    viewId: '',
                };
            }

            // 新しいviewIdをセット
            return {
                ...state,
                viewId: action.payload.viewId,
            };
        case 'Exit':
            if (state.viewId !== '' && action.payload.viewId !== '' && state.viewId !== action.payload.viewId) {
                // 異なるviewIdなら何もしない
                // （既に別のViewのDnDエリア内に留まっている状態 ＝＞
                //   既に別のViewのDragEnterが発火した後に元のViewのDragLeaveが発火した場合）
                return state;
            }

            return {
                ...state,
                viewId: '',
            };
        default:
            return state;
    }
};

type Props = {
    groupId: GroupId;
    viewModelId: ViewModelId;
    viewId: ViewId;
    viewRect: Rect;
    size: Size;
    fontSize: number;
    dispatch: React.Dispatch<DragFileTargetActions>;
};

export const DragFileArea: React.FC<Props> = (props: Props) => {
    const { groupId, viewModelId, viewId, viewRect, size, fontSize, dispatch } = props;

    const [uploading, setUploading] = useState(false);
    const operation = useStickyModelContentsOperation();
    const userProfile = useCurrentUserPublicProfile();
    const getTransformedVisibleAreaCenterPoint = useGetTransformedVisibleAreaCenterPoint();

    const onDrop = useCallback(
        async (files: File[]) => {
            if (!userProfile) return;

            if (files.length > 10) {
                toast.error('一度にアップロード可能な画像は10枚までです');
                return;
            }

            setUploading(true);

            await Promise.all(
                files.map(async (file) => {
                    const asset = ViewModelAsset.buildFromFile(file, userProfile.id);
                    const result = await asset.upload(groupId, viewModelId, file);

                    if (result) {
                        toast.error(result);
                        return;
                    }

                    const markdown = asset.imageMarkdown(groupId, viewModelId, location.href);
                    const createPoint = operation.getCreatePoint(getTransformedVisibleAreaCenterPoint(viewRect));
                    operation.addDescriptionPanel(createPoint, markdown);
                    return;
                })
            );

            setUploading(false);

            dispatch({ type: 'Exit', payload: { viewId } });
        },
        [dispatch, getTransformedVisibleAreaCenterPoint, groupId, operation, userProfile, viewId, viewModelId, viewRect]
    );

    const onDragLeave = () => {
        dispatch({ type: 'Exit', payload: { viewId } });
    };

    const onClose = () => {
        dispatch({ type: 'Exit', payload: { viewId } });
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, onDragLeave, noClick: true, noKeyboard: true });

    return (
        <ForeignObjectAutosize2>
            <div className="m-10" style={{ width: `${size.width - 80}px`, height: `${size.height - 80}px` }}>
                <div
                    {...getRootProps()}
                    className={classNames(
                        'h-full w-full cursor-pointer',
                        'flex items-center justify-center',
                        'border border-dashed border-black bg-gray-200',
                        'rounded-[40px]'
                    )}
                    onClick={onClose}
                >
                    {uploading ? (
                        <Loading large={true} message="アップロードしています..." />
                    ) : (
                        <div style={{ fontSize: fontSize * 0.6 }}>
                            画像ファイルをドラッグ&
                            <br />
                            ドロップでアップロードできます。
                        </div>
                    )}
                    <input type="file" {...getInputProps()} accept="image/*" disabled={uploading} />
                </div>
            </div>
        </ForeignObjectAutosize2>
    );
};
