import { useCallback, useEffect, useMemo, useState } from 'react';
import { PasswordInput } from './PasswordInput';
import { PasswordStrengthIndicator } from './PasswordStrengthIndicator';
import { passwordStrength, type DiversityType } from '@framework/passwordStrength';

const MinimumPasswordLength = 8;

const dict: Record<DiversityType, string> = {
    lowercase: '小文字',
    uppercase: '大文字',
    number: '数字',
    symbol: '記号',
};

const diversities = Object.keys(dict) as DiversityType[];

type Props = {
    autoFocus?: boolean;
    disabled?: boolean;
    onChange(password: string): void;
};

export const EditPasswordForm: React.FC<Props> = ({ autoFocus, disabled, onChange }: Props) => {
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [confirmError, setConfirmError] = useState<string | null>(null);

    // パスワードの強度チェック
    const strength = useMemo(() => passwordStrength(password), [password]);
    const nextRequiredDiversity = useMemo<DiversityType | null>(() => {
        if (password.length === 0) return null;
        return diversities.find((diversity) => !strength.contains.includes(diversity)) || null;
    }, [password, strength]);

    const handleChangePassword = useCallback((password: string) => {
        setPassword(password);
    }, []);
    const handleChangePasswordConfirm = useCallback((password: string) => {
        setPasswordConfirm(password);
    }, []);

    useEffect(() => {
        if (password.length > 0 && password.length < MinimumPasswordLength) {
            setError('パスワードは8文字以上で指定してください。');
            return;
        }

        if (nextRequiredDiversity) {
            setError(`パスワードには ${dict[nextRequiredDiversity]} を含めてください。`);
            return;
        }

        setError(null);
    }, [password, nextRequiredDiversity]);

    const validateOnBlur = useCallback(() => {
        if (password.length >= MinimumPasswordLength && passwordConfirm && password !== passwordConfirm) {
            setConfirmError('確認用パスワードがパスワードと一致していません。');
        } else {
            setConfirmError(null);
        }
    }, [password, passwordConfirm]);

    useEffect(() => {
        // 入力エラーがなく、パスワードと確認用パスワードが一致している場合には、 password を上位コンポーネントに伝える。
        if (error === null && confirmError === null && password === passwordConfirm) {
            onChange(password);
        } else {
            onChange('');
        }
    }, [confirmError, error, onChange, password, passwordConfirm]);

    return (
        <>
            <div className="pb-2 pt-8 font-bold">ご希望のパスワード</div>
            <PasswordInput
                value={password}
                onChange={handleChangePassword}
                error={error}
                onBlur={validateOnBlur}
                autoFocus={autoFocus}
                disabled={disabled}
            />
            <PasswordStrengthIndicator password={password} />

            <div className="pb-2 pt-8 font-bold">パスワードの確認</div>
            <PasswordInput
                value={passwordConfirm}
                onChange={handleChangePasswordConfirm}
                error={confirmError}
                onBlur={validateOnBlur}
                disabled={disabled}
            />
        </>
    );
};
