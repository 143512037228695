import { useCallback, useEffect, useState } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { Loading } from '@framework/ui/atoms/Loading';
import { GroupMemberInvitation } from '@group/domain';
import { GroupMemberSignUpMethod } from '@group/domain/GroupEntity';
import { ProviderName, useLoginWithOAuth } from '@user/Login';
import { UserSignUpWithEmailModal } from '../UserSignUpWithEmailModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { UserSignUpRequestWithEmailModal } from '@user/pages/HomePage/UserSignUpRequestWithEmailModal';

type Props = {
    availableSignUpMethods: GroupMemberSignUpMethod;
    invitation?: GroupMemberInvitation;
    onLogin(): void;
};

export const SignUpMethodMenu: React.FC<Props> = ({ availableSignUpMethods, invitation, onLogin }: Props) => {
    const [processing, setProcessing] = useState(false);
    const loginWithOAuth = useLoginWithOAuth('signUp');

    const handleLoginWithOAuth = useCallback(
        (providerName: ProviderName) => {
            setProcessing(true);
            loginWithOAuth(providerName);
        },
        [loginWithOAuth]
    );

    const handleLoginWithGoogle = useCallback(() => handleLoginWithOAuth('google'), [handleLoginWithOAuth]);
    const handleLoginWithQuadcept = useCallback(() => handleLoginWithOAuth('quadcept'), [handleLoginWithOAuth]);

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = useCallback(() => setShowModal(true), []);
    const handleCloseModal = useCallback(() => setShowModal(false), []);

    useEffect(() => {
        // このグループへの参加時に利用可能なログイン方法が1つだけ存在する場合には、そのログイン方法名を得る。
        const method = availableSignUpMethods.getSingleAvailableMethod();

        if (method === 'email') {
            handleShowModal();
            return;
        }
    }, [availableSignUpMethods, handleShowModal]);

    if (processing) {
        return (
            <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                <Loading />
            </div>
        );
    }

    return (
        <div className="flex w-100 flex-col space-y-4 px-5 py-2">
            {availableSignUpMethods.availableOf('google') && (
                <FlatButton color="brand" onClick={handleLoginWithGoogle}>
                    <span className="pr-2 text-lg">
                        <FontAwesomeIcon icon={faGoogle} />
                    </span>
                    Googleを利用してユーザ登録する
                </FlatButton>
            )}

            {availableSignUpMethods.availableOf('quadcept') && (
                <FlatButton color="brand" onClick={handleLoginWithQuadcept}>
                    <span className="pr-2 text-lg font-bold">Q</span>
                    Quadceptを利用してユーザ登録する
                </FlatButton>
            )}

            {availableSignUpMethods.availableOf('email') && (
                <FlatButton color="brand" onClick={handleShowModal}>
                    メールアドレスを利用してユーザ登録する
                </FlatButton>
            )}
            <div className="pt-4 text-center">
                Balusのアカウントをお持ちの方はこちら
                <div className="py-2">
                    <FlatButton onClick={onLogin}>ログイン</FlatButton>
                </div>
            </div>

            {invitation && (
                <UserSignUpWithEmailModal
                    isOpen={showModal}
                    onClose={handleCloseModal}
                    mailAddress={invitation.mailAddress}
                />
            )}
            {!invitation && <UserSignUpRequestWithEmailModal isOpen={showModal} onClose={handleCloseModal} />}
        </div>
    );
};
