import { useState } from 'react';
import { Rect } from '@view-model/models/common/basic';
import { MIN_VIEW_HEIGHT, MIN_VIEW_WIDTH } from '../../constants';
import { SideLine } from './SideLine';
import { CornerPointer } from './CornerPointer';
import { ResizingOverlay } from './ResizingOverlay';
import { useViewOperation } from '@view-model/ui/components/View/ViewContext';
import { DragEvent } from '@view-model/models/common/hooks/useD3Drag';
import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';

const MARGIN = 12; // ビューに対するリサイズ矩形のマージン

type Props = {
    viewRect: Rect;
    onResizeStart(): void;
    onResizeEnd(viewRect: Rect): void;
    getGuardProps: GetGuardProps;
};

type State = {
    dragging: boolean;
    viewRectOnDrag: Rect; // ドラッグ時のビュー矩形
    viewRectOnDragStart: Rect; // ドラッグ開始時のビュー矩形
};

export const ResizableFrame: React.FC<Props> = ({ viewRect, onResizeStart, onResizeEnd, getGuardProps }: Props) => {
    const [state, setState] = useState<State>({
        dragging: false,
        viewRectOnDrag: viewRect,
        viewRectOnDragStart: viewRect,
    });

    const viewOperation = useViewOperation();

    const handleDragStart = () => {
        setState((prev) => ({ ...prev, dragging: true, viewRectOnDragStart: viewRect }));
        onResizeStart();
    };

    const handleDragEnd = async () => {
        setState((prev) => ({ ...prev, dragging: false }));
        await viewOperation.handleResizingConfirmed(state.viewRectOnDrag, state.viewRectOnDragStart, onResizeEnd);
    };

    const handleDragTopSide = ({ y, dragStartY }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingTopSide(y - dragStartY, MIN_VIEW_HEIGHT),
            };
        });
    };

    const handleDragBottomSide = ({ y, dragStartY }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingBottomSide(y - dragStartY, MIN_VIEW_HEIGHT),
            };
        });
    };

    const handleDragLeftSide = ({ x, dragStartX }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingLeftSide(x - dragStartX, MIN_VIEW_WIDTH),
            };
        });
    };

    const handleDragRightSide = ({ x, dragStartX }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingRightSide(x - dragStartX, MIN_VIEW_WIDTH),
            };
        });
    };

    const handleDragTopLeftCorner = ({ x, y, dragStartX, dragStartY }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingTopLeftCorner(
                    x - dragStartX,
                    y - dragStartY,
                    MIN_VIEW_WIDTH,
                    MIN_VIEW_HEIGHT
                ),
            };
        });
    };

    const handleDragTopRightCorner = ({ x, y, dragStartX, dragStartY }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingTopRightCorner(
                    x - dragStartX,
                    y - dragStartY,
                    MIN_VIEW_WIDTH,
                    MIN_VIEW_HEIGHT
                ),
            };
        });
    };

    const handleDragBottomLeftCorner = ({ x, y, dragStartX, dragStartY }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingBottomLeftCorner(
                    x - dragStartX,
                    y - dragStartY,
                    MIN_VIEW_WIDTH,
                    MIN_VIEW_HEIGHT
                ),
            };
        });
    };

    const handleDragBottomRightCorner = ({ x, y, dragStartX, dragStartY }: DragEvent) => {
        setState((prev) => {
            return {
                ...prev,
                viewRectOnDrag: prev.viewRectOnDragStart.resizeByMovingBottomRightCorner(
                    x - dragStartX,
                    y - dragStartY,
                    MIN_VIEW_WIDTH,
                    MIN_VIEW_HEIGHT
                ),
            };
        });
    };

    const { left, top, right, bottom } = (state.dragging ? state.viewRectOnDrag : viewRect)
        .applyMarginKeepingCenter(MARGIN)
        .getLTRB();

    return (
        <>
            {/* Left */}
            <SideLine
                x1={left}
                y1={top}
                x2={left}
                y2={bottom}
                onDragStart={handleDragStart}
                onDrag={handleDragLeftSide}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {/* Right */}
            <SideLine
                x1={right}
                y1={top}
                x2={right}
                y2={bottom}
                onDragStart={handleDragStart}
                onDrag={handleDragRightSide}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {/* Top */}
            <SideLine
                x1={left}
                y1={top}
                x2={right}
                y2={top}
                onDragStart={handleDragStart}
                onDrag={handleDragTopSide}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {/* Bottom */}
            <SideLine
                x1={left}
                y1={bottom}
                x2={right}
                y2={bottom}
                onDragStart={handleDragStart}
                onDrag={handleDragBottomSide}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {/* TopLeft */}
            <CornerPointer
                cx={left}
                cy={top}
                cursor="nwse-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragTopLeftCorner}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {/* TopRight */}
            <CornerPointer
                cx={right}
                cy={top}
                cursor="nesw-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragTopRightCorner}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {/* BottomLeft */}
            <CornerPointer
                cx={left}
                cy={bottom}
                cursor="nesw-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragBottomLeftCorner}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {/* BottomRight */}
            <CornerPointer
                cx={right}
                cy={bottom}
                cursor="nwse-resize"
                onDragStart={handleDragStart}
                onDrag={handleDragBottomRightCorner}
                onDragEnd={handleDragEnd}
                getGuardProps={getGuardProps}
            />
            {state.dragging && state.viewRectOnDrag && <ResizingOverlay resizingViewRect={state.viewRectOnDrag} />}
        </>
    );
};
