import { useEffect, useState } from 'react';
import { ViewModelEntity } from './ViewModelEntity';
import { ObjectRepository, RTDBPath, TextRepository } from '@framework/repository';
import { WorkspaceEntity, WorkspaceSetting } from '@workspace/domain/workspace';
import { canReadViewModel } from './canReadViewModel';
import { isViewModelSharingUserRoleType, ViewModelSharingUserRoleType } from './vo/ViewModelSharingUserRoleType';
import { AuthSessionId, UserId, ViewModelId, WorkspaceId, isValidId } from '@schema-common/base';
import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';
import { useCurrentUserId } from '@framework/auth';
import { useCurrentSessionId } from '@framework/auth/AuthContext';
import { GroupIpRestrictionCheckRequest } from '@group/AccessControl';

type Result = {
    workspace: WorkspaceEntity | null;
    viewModel: ViewModelEntity | null;
    accessible: boolean; // 対象ビューモデルに権限的にアクセス可能な時に true を返す
    loading: boolean; // ローディング中に true を返す
};

const Loading: Result = { workspace: null, viewModel: null, accessible: false, loading: true };
const NotFound: Result = { workspace: null, viewModel: null, accessible: false, loading: false };
const Accessible: Result = { workspace: null, viewModel: null, accessible: true, loading: false };

type Cleanup = () => void;

const fetchViewModelEntity = async (
    viewModelId: ViewModelId,
    currentUserId: UserId | null,
    currentSessionId: AuthSessionId | null,
    setState: (state: Result | ((prev: Result) => Result)) => void
): Promise<Cleanup> => {
    const cleanups: (() => void)[] = [];
    const cleanup = () => cleanups.forEach((func) => func());

    /**
     * 引数に指定された id が RTDB のパス階層で指定できない文字列パターンの場合には、
     * Not Found として取り扱う。
     */
    if (!isValidId(viewModelId)) {
        setState(NotFound);
        return cleanup;
    }

    // ローディング状態に遷移させる
    setState(Loading);

    // ビューモデルのグループIDは変化しないので、一度取得して完了で良い
    const groupId = await new TextRepository(RTDBPath.ViewModel.viewModelGroupIdPath(viewModelId)).get();

    // ビューモデルのワークスペースは別ワークスペースに移動することがあるので継続して監視できるようにする
    const workspaceIdRepo = new TextRepository(RTDBPath.ViewModel.viewModelWorkspaceIdPath(viewModelId));
    const workspaceId = await workspaceIdRepo.get();

    // ビューモデルの groupId, workspaceId プロパティの値が見つからない場合には Not Found 扱い
    if (!groupId || !workspaceId) {
        setState(NotFound);
        return cleanup;
    }

    let notFound = false;

    /**
     * 対象ビューモデル・エンティティを取得可能か、権限チェックを継続的に行う関数
     */
    const startListen = async (workspaceId: WorkspaceId | null): Promise<void> => {
        if (!workspaceId) {
            setState(NotFound);
            notFound = true;
            return;
        }

        const roleRepo = currentUserId
            ? new TextRepository<WorkspaceMemberRoleJSON>(RTDBPath.Workspace.memberRolePath(workspaceId, currentUserId))
            : null;
        const settingRepo = new ObjectRepository(WorkspaceSetting, RTDBPath.Workspace.settingPath(workspaceId));
        const trashedAtRepo = new TextRepository(RTDBPath.ViewModel.trashedAtPath(viewModelId));
        const sharingUserRoleRepo = new TextRepository(RTDBPath.ViewModel.sharingUserRolePath(viewModelId));

        const role = roleRepo ? await roleRepo.get() : null;
        const setting = await settingRepo.get();
        const trashedAt = await trashedAtRepo.get();
        const sharingUserRole = await sharingUserRoleRepo.get();

        // 対象のビューモデルを閲覧できない場合に true を返す
        const isInvisible = (
            setting: WorkspaceSetting | null,
            sharingUserRole: string | null,
            role: WorkspaceMemberRoleJSON | null,
            trashedAt: string | null
        ): boolean => {
            return (
                setting === null ||
                sharingUserRole === null ||
                !isViewModelSharingUserRoleType(sharingUserRole) ||
                !canReadViewModel(setting, role, trashedAt, ViewModelSharingUserRoleType.load(sharingUserRole))
            );
        };

        // 最初に取得した情報を用いて、閲覧可否の結果を出す
        if (isInvisible(setting, sharingUserRole, role, trashedAt)) {
            setState(NotFound);
            notFound = true;
        }

        const listenerCallback = (
            setting: WorkspaceSetting | null,
            sharingUserRole: string | null,
            role: WorkspaceMemberRoleJSON | null,
            trashedAt: string | null
        ) => {
            if (isInvisible(setting, sharingUserRole, role, trashedAt)) {
                // 閲覧できない場合には、状態を Not Found に遷移させる
                setState(NotFound);
                notFound = true;
            } else {
                // 閲覧できない状態から、閲覧可能な状態に遷移した時には、ページ自体のリロードによって強制的に再描画を行う
                if (notFound) {
                    notFound = false;
                    window.location.reload();
                }
            }
        };

        // 閲覧可否の判定に必要な情報を継続的に監視し、状態変化があった場合に再読み込みを行う
        if (roleRepo) {
            roleRepo.addListener((role) => listenerCallback(setting, sharingUserRole, role, trashedAt));
            cleanups.push(() => roleRepo.removeListener());
        }

        settingRepo.addListener((setting) => listenerCallback(setting, sharingUserRole, role, trashedAt));
        cleanups.push(() => settingRepo.removeListener());

        trashedAtRepo.addListener((trashedAt) => listenerCallback(setting, sharingUserRole, role, trashedAt));
        cleanups.push(() => trashedAtRepo.removeListener());

        sharingUserRoleRepo.addListener((sharingUserRole) =>
            listenerCallback(setting, sharingUserRole, role, trashedAt)
        );
        cleanups.push(() => sharingUserRoleRepo.removeListener());
    };

    await startListen(workspaceId);

    workspaceIdRepo.addListener((newWorkspaceId) => {
        if (newWorkspaceId !== workspaceId) {
            startListen(newWorkspaceId);
        }
    });
    cleanups.push(() => workspaceIdRepo.removeListener());

    if (notFound) {
        setState(NotFound);
        return cleanup;
    }

    // グループ単位のIPアドレス制限の可否チェックを行い、判定結果が出るまで待つ
    const ipRestrictionCheckResult = await GroupIpRestrictionCheckRequest.listen(
        groupId,
        currentUserId,
        currentSessionId,
        (status, prevStatus) => {
            if (prevStatus === 'Allow' && status === 'Deny') {
                // アクセス許可から禁止に変化した時は、 Not Found 状態に変更する
                setState(NotFound);
                return;
            }

            if (prevStatus === 'Deny' && status === 'Allow') {
                // アクセス禁止から許可に変化した時は、画面自体をリロードする
                window.location.reload();
            }
        },
        cleanups
    );

    if (ipRestrictionCheckResult === 'Deny') {
        setState(NotFound);
        return cleanup;
    }

    // 未ログイン状態ならば、「アクセス可能である」というフラグのみを返す
    if (!currentUserId || !currentSessionId) {
        setState(Accessible);
        return cleanup;
    }

    const wsRepo = new ObjectRepository(WorkspaceEntity, RTDBPath.Workspace.workspaceEntityPath(workspaceId));
    const vmRepo = new ObjectRepository(ViewModelEntity, RTDBPath.ViewModel.viewModelPath(viewModelId));

    const [workspace, viewModel] = await Promise.all([wsRepo.get(), vmRepo.get()]);

    // ワークスペース、ビューモデルの一方が null の場合には、両方を null にしてロード完了
    if (workspace === null && viewModel === null) {
        setState(NotFound);
        return cleanup;
    }

    // 最初の取得完了のステート更新を行ってから、変更監視を始める
    setState({ workspace, viewModel, accessible: true, loading: false });

    wsRepo.addListener((workspace) => setState((prev) => ({ ...prev, workspace })));
    cleanups.push(() => wsRepo.removeListener());

    vmRepo.addListener((viewModel) =>
        setState(({ viewModel: prev, ...rest }) =>
            // ビューモデルの updatedAt 以外が一致しているならば、ビューモデル・エンティティはそのまま更新しない。
            // (updatedAt 以外に変更があった場合には、変更監視コールバックの新しいビューモデル・エンティティに置き換える)
            viewModel && prev && viewModel.isEqualExcludingUpdatedAt(prev)
                ? { ...rest, viewModel: prev }
                : { ...rest, viewModel }
        )
    );
    cleanups.push(() => vmRepo.removeListener());

    return cleanup;
};

export const useViewModelEntity = (viewModelId: ViewModelId): Result => {
    const [result, setResult] = useState<Result>(Loading);

    const currentUserId = useCurrentUserId();
    const currentSessionId = useCurrentSessionId();

    useEffect(() => {
        let cleanup: null | (() => void) = null;

        // ビューモデルとワークスペースの読み取り可否の権限チェックを行なってから、エンティティの取得・監視を行う。
        fetchViewModelEntity(viewModelId, currentUserId, currentSessionId, setResult).then((f) => (cleanup = f));

        return () => {
            cleanup?.();
        };
    }, [currentSessionId, currentUserId, viewModelId]);

    return result;
};
