import { SimpleFooterLayout } from '@user/layouts/SimpleFooterLayout';
import BalusLogo from '@assets/balus-logo-brand-horizontal.svg';
import { Link } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const FooterLink: React.FC<unknown> = () => (
    <div className="pt-8">
        <Link to={UserPagePathBuilder.loginPage()} className="text-sm text-blue-700 hover:underline">
            ログインはこちら
        </Link>
        <br />
        <a
            href="https://links.balus.app/contact-form"
            target="_blank"
            rel="noreferrer"
            className="text-sm text-blue-700 hover:underline"
        >
            お問合せフォーム <FontAwesomeIcon icon={faExternalLinkAlt} />
        </a>
    </div>
);

export const SignUpRequestDone: React.FC<unknown> = () => {
    return (
        <SimpleFooterLayout>
            <div className="flex w-screen flex-col items-center p-8">
                <img src={BalusLogo} className="w-64" />
                <div className="py-8 text-3xl font-bold">Balusにユーザ登録をする</div>

                <div className="flex flex-col rounded-lg border border-gray-500 p-8" style={{ width: '600px' }}>
                    <div className="text-2xl font-bold">メールをご確認ください</div>
                    <div className="pt-8">
                        入力していただいたメールアドレス宛に、ユーザ登録できるリンクをお送りしました。
                        <br />
                        メールが確認できない場合には、迷惑メールボックスに入っていないか、入力したメールアドレスが間違っていないか、改めてご確認ください。
                    </div>

                    <FooterLink />
                </div>
            </div>
        </SimpleFooterLayout>
    );
};
