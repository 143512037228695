import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { classNames } from '@framework/utils';

type Props = {
    icon: IconDefinition;
    onClick(): void;
    disabled: boolean;
    ariaLabel: string;
};

export const TimeStepButton: React.FC<Props> = ({ icon, onClick, disabled, ariaLabel }: Props) => {
    return (
        <button
            onClick={onClick}
            className={classNames(
                'size-6 rounded-full hover:bg-gray-200 focus:outline-none disabled:bg-white disabled:text-gray-500'
            )}
            disabled={disabled}
            aria-label={ariaLabel}
        >
            <FontAwesomeIcon icon={icon} />
        </button>
    );
};
