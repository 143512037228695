import { useCallback } from 'react';
import { UserEntity } from '@user/UserEntity';
import { FlatButton } from '@framework/ui/atoms';
import { generateDefaultUserIcon } from '@framework/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faExternalLinkAlt, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
    userEntity: UserEntity;
    name: string;
    imageUrl: string | null;
    onChangeName(name: string): void;
    onCreatePassword(): void;
    onChangePassword(): void;
    onConnectJiji(): void;
    onDisconnectJiji(): void;
    onImageClear(): void;
    onUploadImage(event: React.ChangeEvent<HTMLInputElement>): void;
};

export const EditUserForm: React.FC<Props> = ({
    userEntity,
    name,
    imageUrl,
    onChangeName,
    onCreatePassword,
    onChangePassword,
    onConnectJiji,
    onDisconnectJiji,
    onImageClear,
    onUploadImage,
}: Props) => {
    const handleChangeName = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            onChangeName(event.target.value);
        },
        [onChangeName]
    );

    return (
        <>
            <div className="flex items-center justify-between">
                {imageUrl ? (
                    <div className="relative size-16">
                        <div className="absolute size-16 overflow-hidden rounded-full">
                            <img className="size-full" src={imageUrl} alt={userEntity.name} />
                        </div>
                        <div className="absolute flex size-16 items-start justify-end">
                            <div
                                className="relative flex cursor-pointer items-center justify-center"
                                onClick={onImageClear}
                            >
                                <FontAwesomeIcon icon={faCircle} className="absolute text-2xl text-red-500" />
                                <FontAwesomeIcon icon={faTimes} className="absolute text-xl text-white" />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="relative size-16 overflow-hidden rounded-full">
                        <div className="absolute size-full opacity-50">
                            {generateDefaultUserIcon({
                                id: userEntity.id,
                                name: userEntity.name.toString(),
                                size: 64,
                            })}
                        </div>
                        <div className="absolute flex size-full items-center justify-center">
                            <FontAwesomeIcon icon={faPlus} className="text-2xl text-blue-500" />
                        </div>
                        <input
                            type="file"
                            id="icon-image"
                            className="absolute size-full cursor-pointer opacity-0"
                            accept="image/png, image/jpeg"
                            onChange={onUploadImage}
                        />
                    </div>
                )}
                <div className="flex w-5/6 flex-col">
                    <div className="pb-2 font-bold">名前 | Name</div>
                    <input
                        type="text"
                        className="rounded-sm border p-2"
                        value={name}
                        onChange={handleChangeName}
                        autoFocus
                    />
                </div>
            </div>

            <div className="pb-2 pt-8 font-bold">メールアドレス | Email address</div>
            <input
                type="text"
                className="cursor-not-allowed rounded-sm border bg-gray-200 p-2"
                defaultValue={userEntity.mailAddress}
                disabled
            />
            <div className="pt-1 text-xs">
                ※ メールアドレスの変更は
                <a
                    href="https://links.balus.app/contact-form"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700 hover:underline"
                >
                    こちら <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
                からご連絡ください。
            </div>
            <div className="pt-1 text-xs">
                * If you want to change your email address, please contact us from &nbsp;
                <a
                    href="https://links.balus.app/contact-form"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700 hover:underline"
                >
                    this page <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
                .
            </div>

            <div className="flex flex-row justify-between pb-2 pt-8 font-bold">
                パスワード | Password
                {userEntity.hasPassword ? (
                    <FlatButton onClick={onChangePassword}>変更 | Edit</FlatButton>
                ) : (
                    <FlatButton onClick={onCreatePassword}>追加 | Add</FlatButton>
                )}
            </div>

            <div className="flex flex-row justify-between pb-2 pt-8 font-bold">
                外部アカウント連携 | Account integrations
                {userEntity.jijiId ? (
                    <FlatButton onClick={onDisconnectJiji}>変更 | Edit</FlatButton>
                ) : (
                    <FlatButton onClick={onConnectJiji}>連携 | Connect</FlatButton>
                )}
            </div>
        </>
    );
};
