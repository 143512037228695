import {
    GroupMemberInvitationJSON,
    GroupMemberInvitationJSONOnWrite,
} from '@schema-app/group/member-invitations/{groupId}/{invitationId}/GroupMemberInvitationJSON';
import { UserEntity } from '@user/UserEntity';

export class InvitationSenderUser {
    constructor(
        readonly userId: string,
        readonly name: string,
        readonly email: string,
        readonly imageUrl: string | null
    ) {}

    dump(): GroupMemberInvitationJSONOnWrite['senderUser'] {
        const { userId, name, email, imageUrl } = this;
        return { userId, name, email, imageUrl };
    }

    static load(dump: GroupMemberInvitationJSON['senderUser']): InvitationSenderUser {
        const { userId, name, email, imageUrl } = dump;
        return new this(userId, name, email, imageUrl);
    }

    static fromUserData(user: UserEntity): InvitationSenderUser {
        const { id, name, mailAddress, imageUrl } = user;

        return new this(id, name, mailAddress, imageUrl);
    }
}
