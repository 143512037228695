import { GroupLicenseType, GroupLicenseJSON } from '@schema-app/group/licenses/{groupId}/GroupLicenseJSON';
import { Timestamp } from '@framework/Timestamp';
import { GroupMemberRole } from '../GroupEntity';
export class GroupLicense {
    constructor(
        readonly type: GroupLicenseType,
        readonly count: number,
        readonly expiresAt: Timestamp
    ) {}

    dump(): GroupLicenseJSON {
        return {
            type: this.type,
            count: this.count,
            expiresAt: this.expiresAt.toUnixTimestamp(),
        };
    }

    static load(dump: GroupLicenseJSON): GroupLicense {
        const { type, count, expiresAt } = dump;
        return new this(type, count, Timestamp.fromUnixTimestamp(expiresAt));
    }

    /**
     * グループに招待可能な残り回数を計算する
     */
    static getAvailableInvitationCount({
        groupLicense,
        role,
        memberCount,
        guestCount,
    }: {
        groupLicense?: GroupLicense;
        role: GroupMemberRole;
        memberCount: number;
        guestCount: number;
    }): number {
        if (!groupLicense) {
            // NOTE: グループライセンスが必ず存在するようにマイグレーションした後消す
            return Infinity;
        }

        if (groupLicense.count <= 0) {
            return 0;
        }

        return Math.max(0, groupLicense.count - (role === 'guest' ? guestCount : memberCount));
    }
}
