import { useCallback, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { WorkspaceEntity, WorkspaceMemberRoles } from '@workspace/domain/workspace';
import { MemberEditList } from './MemberEditList';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';
import { RTDBPath, usePrimitiveValueListener } from '@framework/repository';

type Props = {
    isOpen: boolean;
    onClose(): void;
    workspace: WorkspaceEntity;
    memberRoles: WorkspaceMemberRoles;
    currentUserId: string;
    onMemberAdd(): void;
    onMemberRemove(): void;
};

/**
 * 既存のワークスペースメンバーの役割(ロール)を変更するモーダル
 * @returns
 */
export const MemberEditModal: React.FC<Props> = ({
    isOpen,
    onClose,
    workspace,
    memberRoles,
    currentUserId,
    onMemberAdd,
    onMemberRemove,
}: Props) => {
    const [internalPublicSetting] = usePrimitiveValueListener<WorkspaceInternalPublicMemberRoleJSON>(
        RTDBPath.Group.internalPublicWorkspacePath(workspace.groupId, workspace.id)
    );

    const [processing, setProcessing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClose = useCallback(() => {
        setProcessing(false);
        setErrorMessage('');

        onClose();
    }, [onClose]);

    const closeButton = (
        <Button color="secondary" onClick={handleClose} disabled={processing}>
            閉じる
        </Button>
    );

    return (
        <GeneralModal
            isOpen={isOpen}
            onClose={handleClose}
            title={`${workspace.name.value}のメンバー`}
            submitButton={null}
            cancelButton={closeButton}
        >
            {internalPublicSetting ? (
                <div className="m-2 rounded border border-yellow-700 bg-yellow-200 p-2 text-sm">
                    グループ内公開ワークスペースに設定されているため、メンバーの追加・削除を行うことはできません。
                    <br />
                    特定のメンバーにだけ権限を付与したい場合には、プライベートワークスペースに変更してください。
                </div>
            ) : (
                <div className="flex justify-end p-2">
                    <button
                        className="rounded border border-solid border-brand px-2 py-1 text-sm text-brand hover:bg-gray-200"
                        onClick={onMemberAdd}
                    >
                        メンバーを追加
                    </button>
                    <button
                        className="ml-2 rounded border border-solid border-red-700 px-2 py-1 text-sm text-red-700 hover:bg-gray-200"
                        onClick={onMemberRemove}
                    >
                        メンバーを削除
                    </button>
                </div>
            )}
            <div
                className="mb-4 mt-2 overflow-y-auto p-2"
                style={{ width: '600px', minHeight: '250px', maxHeight: '50vh' }}
            >
                <MemberEditList workspaceId={workspace.id} currentUserId={currentUserId} memberRoles={memberRoles} />
            </div>
            {errorMessage && <div className="p-2 text-red-700">{errorMessage}</div>}
        </GeneralModal>
    );
};
