import { GroupEntity, GroupMemberRole } from '@group/domain';
import { UserEntity } from '@user/UserEntity';
import { useCallback, useEffect, useState } from 'react';
import { FullScreenLoading } from '@framework/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { useCurrentSessionId, useCurrentUserId } from '@framework/auth/AuthContext';
import { KeysRepository, ObjectRepository, RTDBPath } from '@framework/repository';
import { WorkspaceId } from '@schema-common/base';
import { Workspace } from '@workspace/domain/workspace';
import { WorkspaceMemberDeleteConfirmModal } from './WorkspaceMemberDeleteConfirmModal';
import { useAssignedWorkspaces } from '@workspace/ui/WorkspaceList';
import { getMemberDataViaGroupConsoleUsing } from '../common/getMemberDataViaGroupConsoleUsing';
import { useSnapshot } from '@framework/hooks';
import { GroupMemberRoleJSON } from '@schema-common/group';
import { NotFound } from '@framework/ui/pages/NotFound';
import { WorkspaceItem } from './WorkspaceItem';

type Props = {
    group: GroupEntity;
};

export const GroupConsoleMemberPage: React.FC<Props> = ({ group }: Props) => {
    const { memberId } = useParams<{ memberId: string }>();
    const sessionId = useCurrentSessionId();
    const [memberData, setMemberData] = useState<UserEntity | null>(null);
    const [loading, setLoading] = useState(true);
    const [deleteTargetWorkspace, setDeleteTargetWorkspace] = useState<Workspace | null>(null);
    const [internalWorkspaceIds, setInternalWorkspaceIds] = useState<WorkspaceId[]>([]);
    const assignedWorkspaces = useAssignedWorkspaces(memberId || null, group.id);
    const currentUserId = useCurrentUserId();

    // メンバーデータの取得
    useEffect(() => {
        if (!sessionId || !memberId) return;

        const getMemberData = async () => {
            const memberDataRepo = new ObjectRepository(UserEntity, RTDBPath.User.userEntityPath(memberId));

            return await getMemberDataViaGroupConsoleUsing(group.id, sessionId, () => memberDataRepo.get());
        };

        getMemberData()
            .then((memberData) => {
                setMemberData(memberData);
            })
            .finally(() => setLoading(false));
    }, [memberId, sessionId, group.id]);

    // グループ内公開ワークスペースの一覧取得
    useEffect(() => {
        if (!memberData) return;
        const internalWorkspaceIdsRepo = new KeysRepository(RTDBPath.Group.internalPublicWorkspacesPath(group.id));

        internalWorkspaceIdsRepo.get().then((workspaceIds) => {
            setInternalWorkspaceIds(workspaceIds);
        });
    }, [memberData, group]);

    const [memberRole] = useSnapshot<GroupMemberRoleJSON>({
        path: RTDBPath.Group.memberRolePath(group.id, memberId),
        load: ({ snapshot }) => snapshot.val(),
    });

    const handleCloseDeleteModal = useCallback(() => {
        setDeleteTargetWorkspace(null);
    }, []);

    if (loading) return <FullScreenLoading />;

    // 未ログイン状態ではグループ管理画面を表示できない
    if (!currentUserId) return <NotFound />;

    return (
        <>
            <Link className="mb-4 flex items-center" to={UserPagePathBuilder.groupConsoleMembersPage(group.id)}>
                <FontAwesomeIcon icon={faAngleLeft} className="text-brand" />
                <span className="text-brand">メンバー</span>
            </Link>

            {memberData ? (
                <>
                    <div className="mb-4 flex size-10 items-center">
                        <UserIcon
                            name={memberData.name}
                            id={memberData.id}
                            imageUrl={memberData.imageUrl}
                            size="large"
                            className="mr-2 grow-0"
                        />
                        <span className="shrink-0">{memberData.name}</span>
                    </div>
                    <div className="mb-4">
                        <div className="mb-2 font-bold">メールアドレス</div>
                        <div>{memberData.mailAddress}</div>
                    </div>
                    <div className="mb-4">
                        <div className="mb-2 font-bold">ロール</div>
                        <div>{memberRole ? GroupMemberRole.getLocalString(memberRole) : 'なし'}</div>
                    </div>

                    <div className="mb-2 font-bold">参加ワークスペース</div>
                    <div className="overflow-y-auto" style={{ maxHeight: 'calc(100% - 275px)' }}>
                        {!assignedWorkspaces || assignedWorkspaces.length <= 0 ? (
                            <div>参加しているワークスペースはありません</div>
                        ) : (
                            assignedWorkspaces.map((workspace) => (
                                <WorkspaceItem
                                    key={workspace.id}
                                    workspace={workspace}
                                    currentUserId={currentUserId}
                                    memberUserEntity={memberData}
                                    internalWorkspaceIds={internalWorkspaceIds}
                                />
                            ))
                        )}
                    </div>
                </>
            ) : (
                <div>メンバーがいません</div>
            )}
            {deleteTargetWorkspace && memberData && (
                <WorkspaceMemberDeleteConfirmModal
                    isOpen={!!deleteTargetWorkspace}
                    memberData={memberData}
                    workspace={deleteTargetWorkspace}
                    onClose={handleCloseDeleteModal}
                />
            )}
        </>
    );
};
