import { useMemo } from 'react';
import { Workspace, WorkspaceMemberRole } from '@workspace/domain/workspace';
import { ViewModelEntity, ViewModelSharingUserRoleType } from '@view-model/domain/view-model';
import { UserId } from '@schema-common/base';
import { useWorkspaceMemberRoles } from '@workspace/domain/workspace/useWorkspaceMemberRoles';

export type ViewModelPermissions = {
    isEditable: boolean;
    isContentEditable: boolean;
    isDeletable: boolean;
};

const defaultPermissions = {
    isEditable: false,
    isContentEditable: false,
    isDeletable: false,
};

/**
 * workspaceContext と viewModelContext をもとに、対象の viewModel に対する権限情報を計算して返す。
 *
 * workspaceContext には、対象ワークスペースとログイン中ユーザの情報が含まれており、
 * ワークスペースの公開設定も考慮して権限チェックを行える。
 */
export const useViewModelPermissions = (
    currentUserId: UserId | null,
    workspace: Workspace,
    viewModel: ViewModelEntity | null
): ViewModelPermissions => {
    const roles = useWorkspaceMemberRoles(workspace.id);

    return useMemo(() => {
        if (currentUserId === null || viewModel === null) {
            return defaultPermissions;
        }

        // 対象ワークスペースに所属していて、管理者ロールまたは編集者ロールの場合には、編集・削除操作が可能
        const role = roles?.getRoleOf(currentUserId);
        const adminOrEditor = role && [WorkspaceMemberRole.admin, WorkspaceMemberRole.editor].includes(role);
        if (adminOrEditor) {
            return {
                isEditable: true,
                isContentEditable: true && !viewModel.isLocked,
                isDeletable: true,
            };
        }

        // 対象ワークスペースがURL共有許可されている場合は、ビューモデルの共有設定に従ってコンテンツ編集の可否が決まる
        if (workspace.setting.isViewModelURLShareable) {
            return {
                isEditable: false,
                isContentEditable:
                    viewModel.sharingUserRole === ViewModelSharingUserRoleType.Editor && !viewModel.isLocked,
                isDeletable: false,
            };
        }

        // 公開ワークスペースの場合など、ビューモデルの編集・削除操作は行えない
        return {
            isEditable: false,
            isContentEditable: false,
            isDeletable: false,
        };
    }, [currentUserId, viewModel, roles, workspace.setting.isViewModelURLShareable]);
};
