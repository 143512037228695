import { GroupMemberRole } from '@group/domain';
import React, { useCallback, useState } from 'react';

type Props = {
    defaultRole: GroupMemberRole;
    onChange(role: GroupMemberRole): void;
    disabled: boolean;
};

const descriptions: Record<GroupMemberRole, string> = {
    admin: 'ワークスペースの管理、メンバー招待などの管理業務を行えます。',
    member: '複数のワークスペースに所属してBalusを活用することができます。',
    guest: '特定の1つのワークスペースにのみ所属して、限定的にBalusを利用できます。',
};

export const InvitationRoleSelect: React.FC<Props> = ({ defaultRole, disabled, onChange }: Props) => {
    const [selectedRole, setSelectedRole] = useState<GroupMemberRole>(defaultRole);
    const handleChangeRole = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const role = GroupMemberRole.load(event.target.value);
            setSelectedRole(role);
            onChange(role);
        },
        [onChange]
    );

    return (
        <div className="flex flex-row space-x-4">
            {GroupMemberRole.allValues().map((role) => (
                <label className="cursor-pointer rounded border p-4" key={role}>
                    <input
                        type="radio"
                        name="role"
                        value={role}
                        className="form-radio mx-1"
                        onChange={handleChangeRole}
                        checked={role === selectedRole}
                        disabled={disabled}
                    />
                    {GroupMemberRole.getLocalString(role)}
                    <hr className="my-1" />
                    <span className="text-sm">{descriptions[role]}</span>
                </label>
            ))}
        </div>
    );
};
