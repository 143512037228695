import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';
import { ObjectRepository, RefBuilder, RTDBPath, ServerValue } from '@framework/repository';
import { GroupId, NO_ASSIGN, NoAssign, UserId, WorkspaceId } from '@schema-common/base';
import { createWorkspaceEntityRepository } from './workspace/repository';
import { WorkspaceMemberRole } from './workspace/vo/WorkspaceMemberRole';
import { WorkspaceSetting } from './workspace/vo/WorkspaceSetting';
import { Workspace } from './workspace/Workspace';
import { WorkspaceInternalPublicMemberRoleJSON, WorkspaceMemberRoleJSON } from '@schema-common/workspace';
import { difference } from 'lodash';
import { OperatorUserJSON } from '@schema-app/admin/operator-users/{operatorUserId}/OperatorUserJSON';
import { GroupMemberRoles } from '@group/domain/GroupEntity/GroupMemberRoles';
import { WorkspaceMemberRoles } from './workspace/vo/WorkspaceMemberRoles';

export class WorkspaceOperation {
    /**
     * 指定 groupId に対してワークスペースを作成し、 userId をワークスペース管理者として追加する。
     *
     * ワークスペースの作成時には、以下に対してデータを保存する。
     *   1. workspaces/{workspaceKey}
     *   2. workspace/assigned-group-workspace-index/{userId}/{groupId}/{workspaceId}
     *   3. group-contents/{groupKey}/workspace-keys/{workspaceKey}
     *
     * @param name
     * @param groupId
     * @param userId
     * @param onComplete
     */
    static async create(name: string, groupId: string, userId: string): Promise<WorkspaceId | null> {
        const workspace = Workspace.buildNew(name, groupId, userId, await getRealtimeDatabaseCurrentTimestamp());
        return this.saveForCreate(workspace, userId, WorkspaceMemberRole.admin);
    }

    /**
     * 指定 groupId に対して、ユーザ userId のパーソナルワークスペースを作成します。
     * @param groupId
     * @param userId
     * @returns
     */
    static async createPersonal(groupId: GroupId, userId: UserId): Promise<WorkspaceId | null> {
        const workspace = Workspace.buildNewPersonal(groupId, userId, await getRealtimeDatabaseCurrentTimestamp());
        const ref = RefBuilder.ref(RTDBPath.Workspace.settingPath(workspace.id));
        const snapshot = await ref.get();

        // ワークスペースの設定が存在するならば、そのワークスペースに対してメンバー追加を行う。
        if (snapshot.val()) {
            const success = await this.addMembers(workspace.id, groupId, [userId], WorkspaceMemberRole.editor);
            return success ? workspace.id : null;
        } else {
            return this.saveForCreate(workspace, userId, WorkspaceMemberRole.editor);
        }
    }

    /**
     * ワークスペースを新規作成する時に利用する保存処理
     */
    private static async saveForCreate(
        workspace: Workspace,
        userId: UserId,
        role: WorkspaceMemberRole
    ): Promise<WorkspaceId | null> {
        const groupId = workspace.groupId;

        return new Promise((resolve) => {
            RefBuilder.ref().update(
                {
                    [RTDBPath.Workspace.workspaceEntityPath(workspace.id)]: workspace.dump(),
                    [RTDBPath.Workspace.memberRolePath(workspace.id, userId)]: role,
                    [RTDBPath.Group.workspaceIndexValuePath(groupId, workspace.id)]: NO_ASSIGN,
                    [RTDBPath.Workspace.assignedGroupWorkspaceIndexValuePath(userId, groupId, workspace.id)]: NO_ASSIGN,
                },
                (err) => {
                    if (err) {
                        console.error(err);
                        resolve(null);
                    } else {
                        resolve(workspace.id);
                    }
                }
            );
        });
    }

    /**
     * ワークスペース名と説明を更新する。
     */
    static async update(
        workspaceId: WorkspaceId,
        name: string,
        description: string,
        imageUrl: string | null
    ): Promise<boolean> {
        return new Promise((resolve) => {
            RefBuilder.ref(RTDBPath.Workspace.workspaceEntityPath(workspaceId)).update(
                {
                    name,
                    description,
                    imageUrl,
                    updatedAt: ServerValue.TIMESTAMP,
                },
                (err) => {
                    if (err) console.error(err);
                    resolve(!err);
                }
            );
        });
    }

    /**
     * 指定のワークスペースの設定を更新する。
     * 更新の成否をbooleanで返す。
     */
    static async updateSetting(workspaceId: WorkspaceId, workspaceSetting: WorkspaceSetting): Promise<boolean> {
        return new Promise((resolve) => {
            RefBuilder.ref(RTDBPath.Workspace.workspaceEntityPath(workspaceId)).update(
                {
                    setting: workspaceSetting.dump(),
                    updatedAt: ServerValue.TIMESTAMP,
                },
                (err) => {
                    if (err) console.error(err);
                    resolve(!err);
                }
            );
        });
    }

    /**
     * 指定のワークスペースのグループ内公開設定を更新します。
     * プライベートからグループ内公開に変更する時には、グループメンバー全員をワークスペースに追加します。
     * 更新の成否をbooleanで返す。
     */
    static async updateInternalPublicSetting(
        workspaceId: WorkspaceId,
        value: WorkspaceInternalPublicMemberRoleJSON | null
    ): Promise<boolean> {
        const workspace = await new ObjectRepository(
            Workspace,
            RTDBPath.Workspace.workspaceEntityPath(workspaceId)
        ).get();
        if (!workspace) {
            console.error(`WorkspaceEntity(id=${workspaceId}) is not found.`);
            return false;
        }

        const workspaceMemberRoles = await new ObjectRepository(
            WorkspaceMemberRoles,
            RTDBPath.Workspace.memberRolesPath(workspaceId)
        ).get();
        if (!workspaceMemberRoles) {
            console.error(`WorkspaceMemberRoles(workspaceId=${workspaceId}) is not found.`);
            return false;
        }

        const groupMemberRoles = await new ObjectRepository(
            GroupMemberRoles,
            RTDBPath.Group.memberRolesPath(workspace.groupId)
        ).get();
        if (!groupMemberRoles) {
            console.error(`GroupMemberRoles(groupId=${workspace.groupId}) is not found.`);
            return false;
        }

        // グループ内公開設定の値を保存する。
        try {
            await RefBuilder.ref(RTDBPath.Group.internalPublicWorkspacePath(workspace.groupId, workspaceId)).set(value);
        } catch (err) {
            console.error(err);
            return false;
        }

        // 保存するグループ内公開設定の値が null の場合には、メンバー追加処理をスキップして処理終了する。
        if (!value) {
            return true;
        }

        const addingUserIds = difference(groupMemberRoles.userIds(), workspaceMemberRoles.userIds());
        return this.addMembers(workspaceId, workspace.groupId, addingUserIds, WorkspaceMemberRole.load(value));
    }

    /**
     * 指定のワークスペースに指定の複数ユーザをまとめて追加する。
     *
     * ワークスペースにメンバーを追加する際には、 ユーザごとに以下の2つのパスを更新する。
     *   1. workspaces/{wsKey}/members/{userKey}: role
     *   2. workspace/assigned-group-workspace-index/{userId}/{groupId}}/{workspaceId}: 'N/A'
     * 最後の引数が省略された時(または真値を指定した時)は、ワークスペース・エンティティの updatedAt を更新する。
     */
    static async addMembers(
        workspaceId: WorkspaceId,
        groupId: GroupId,
        userIds: UserId[],
        role: WorkspaceMemberRole
    ): Promise<boolean> {
        const values = userIds.reduce(
            (result, userId) => ({
                ...result,
                [RTDBPath.Workspace.memberRolePath(workspaceId, userId)]: role,
                [RTDBPath.Workspace.assignedGroupWorkspaceIndexValuePath(userId, groupId, workspaceId)]: NO_ASSIGN,
            }),
            {}
        );

        return new Promise((resolve) => {
            RefBuilder.ref().update(values, (err) => {
                if (err) {
                    console.error(err);
                    resolve(false);
                } else {
                    resolve(true); // success
                }
            });
        });
    }

    static async removeMembers(workspaceId: WorkspaceId, groupId: GroupId, userIds: UserId[]): Promise<boolean> {
        const values = userIds.reduce(
            (result, userId) => ({
                ...result,
                [RTDBPath.Workspace.memberRolePath(workspaceId, userId)]: null,
                [RTDBPath.Workspace.assignedGroupWorkspaceIndexValuePath(userId, groupId, workspaceId)]: null,
            }),
            {}
        );

        return new Promise((resolve) =>
            RefBuilder.ref().update(values, (err) => {
                if (err) {
                    console.error(err);
                    resolve(false);
                } else {
                    resolve(true); // success
                }
            })
        );
    }

    /**
     * ワークスペースの論理削除を行う.
     *  * ワークスペースのメンバーを0人に変更
     *  * ワークスペースの共有設定を無効化
     *  * 論理削除日時、削除ユーザを記録
     *
     * 削除の成否をbooleanで返す。
     */
    static async logicalDelete(
        workspaceId: WorkspaceId,
        trashedBy: UserId | OperatorUserJSON['email']
    ): Promise<boolean> {
        // workspaceの取得
        const workspace = await createWorkspaceEntityRepository(workspaceId).get();
        if (!workspace) return false;
        // 既に論理削除済みならば、特に処理を行わずに削除失敗(false)を返す
        if (workspace.trashedAt) return false;

        // ワークスペースの公開設定を無効化
        if (
            !(await this.updateSetting(
                workspace.id,
                WorkspaceSetting.load({ isPublicSpace: false, isViewModelURLShareable: false })
            ))
        ) {
            return false;
        }

        // ワークスペースのグループ内公開設定を無効化
        if (!(await this.updateInternalPublicSetting(workspace.id, null))) {
            return false;
        }

        // 更新日、削除日、削除ユーザーIDを設定
        const result = await new Promise((resolve) =>
            RefBuilder.ref(RTDBPath.Workspace.workspaceEntityPath(workspace.id)).update(
                {
                    updatedAt: ServerValue.TIMESTAMP,
                    trashedAt: ServerValue.TIMESTAMP,
                    trashedBy: trashedBy,
                },
                (err) => {
                    if (err) console.error(err);
                    resolve(!err);
                }
            )
        );
        if (!result) {
            return false;
        }

        // ユーザー関係のレコードを全て削除
        const memberRepo = new ObjectRepository(WorkspaceMemberRoles, RTDBPath.Workspace.memberRolesPath(workspace.id));
        const memberRoles = await memberRepo.get();
        const memberIds = memberRoles?.userIds() || [];
        return this.removeMembers(workspace.id, workspace.groupId, memberIds);
    }

    static async updateMemberRole(
        workspaceId: WorkspaceId,
        userId: UserId,
        role: WorkspaceMemberRoleJSON
    ): Promise<boolean> {
        return new Promise((resolve) => {
            RefBuilder.ref(RTDBPath.Workspace.memberRolePath(workspaceId, userId)).set(role, (err) => {
                if (err) {
                    console.error(err);
                    resolve(false);
                } else {
                    resolve(true);
                }
            });
        });
    }

    /**
     * グループ内公開されている未参加の全てのワークスペースに参加します
     *
     * @param groupId
     * @param userId
     * @returns
     */
    static async joinInternalPublicWorkspaces(groupId: GroupId, userId: UserId): Promise<boolean> {
        const internalWorkspaces = (
            await RefBuilder.ref(RTDBPath.Group.internalPublicWorkspacesPath(groupId)).get()
        ).val() as Record<WorkspaceId, WorkspaceInternalPublicMemberRoleJSON> | null;
        // グループ内公開されているワークスペースが無ければ、その時点で処理終了
        if (internalWorkspaces == null) return true;

        // 自分が参加しているワークスペースの一覧を取得
        const joinedWorkspaceIds = ((
            await RefBuilder.ref(RTDBPath.Workspace.assignedGroupWorkspaceIndexPath(userId, groupId)).get()
        ).val() || {}) as Record<WorkspaceId, NoAssign>;

        // グループ内公開されているワークスペースそれぞれに対して、メンバー追加処理を実行する
        const results = await Promise.all(
            Object.entries(internalWorkspaces).map(async ([workspaceId, role]) => {
                if (workspaceId in joinedWorkspaceIds) {
                    return true; // 既に参加済みならば処理スキップ
                }
                return this.addMembers(workspaceId, groupId, [userId], WorkspaceMemberRole.load(role));
            })
        );

        return results.every((value) => value);
    }

    /**
     * 招待されているワークスペースに参加します
     *
     * @param groupId
     * @param userId
     * @param invitedWorkspaces
     * @returns
     */
    static async joinInvitedWorkspaces(
        groupId: GroupId,
        userId: UserId,
        invitedWorkspaces: Record<string, WorkspaceMemberRoleJSON> | null
    ): Promise<boolean> {
        if (!invitedWorkspaces || !Object.keys(invitedWorkspaces).length) return true;

        const results = await Promise.all(
            Object.entries(invitedWorkspaces).map(async ([workspaceId, role]) => {
                return this.addMembers(workspaceId, groupId, [userId], WorkspaceMemberRole.load(role));
            })
        );

        // 無事に全てのワークスペースに参加できたら、招待ワークスペースの一覧を削除する
        if (results.every((value) => value)) {
            const updates = {
                [RTDBPath.Group.memberInvitationWorkspacePath(groupId, userId)]: null,
            };

            return new Promise((resolve) => {
                RefBuilder.ref().update(updates, (err) => {
                    if (err) {
                        console.error('ERROR', err);
                    }
                    resolve(!err);
                });
            });
        }

        return results.every((value) => value);
    }
}
