import { useMemo } from 'react';
import { generateDefaultIcon } from '@framework/utils';
import { useUserPublicProfiles } from '@user/PublicProfile';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { Workspace } from '@workspace/domain/workspace/Workspace';
import { Link } from 'react-router-dom';
import { MemberIconList } from './MemberIconList';
import { RTDBPath, usePrimitiveValueListener } from '@framework/repository';
import { WorkspaceInternalPublicMemberRoleJSON } from '@schema-common/workspace';
import { WorkspacePublicStatusIcon } from '../WorkspacePublicStatusIcon';
import { useWorkspaceMemberRoles } from '@workspace/domain/workspace/useWorkspaceMemberRoles';

type WorkspaceListItemProps = {
    workspace: Workspace;
};

export const WorkspaceListItem: React.FC<WorkspaceListItemProps> = ({ workspace }: WorkspaceListItemProps) => {
    const name = workspace.name.toString();
    const imageUrl = workspace.imageUrl;
    const [internalPublicSetting] = usePrimitiveValueListener<WorkspaceInternalPublicMemberRoleJSON>(
        RTDBPath.Group.internalPublicWorkspacePath(workspace.groupId, workspace.id)
    );

    const roles = useWorkspaceMemberRoles(workspace.id);
    const userIds = useMemo(() => roles?.userIds() || [], [roles]);
    const userProfiles = useUserPublicProfiles(userIds);

    return (
        <Link className={'w-full'} to={UserPagePathBuilder.workspacePage(workspace.id)}>
            <div className="flex cursor-pointer flex-col justify-between rounded-lg border border-gray-500 p-4 hover:bg-gray-200">
                <div className="flex grow items-center justify-start">
                    <span className="inline-block size-10 overflow-hidden rounded-full">
                        {imageUrl ? (
                            <img
                                className={'inline-block size-10 overflow-hidden rounded-full'}
                                src={imageUrl}
                                alt={name}
                            />
                        ) : (
                            <div className={'inline-block size-10 overflow-hidden rounded-full'}>
                                {generateDefaultIcon({ id: workspace.id, name: workspace.name.toString(), size: 40 })}
                            </div>
                        )}
                    </span>
                    <div
                        className="truncate px-2 text-2xl font-bold leading-10"
                        style={{ maxWidth: 'calc(100% - 8rem)' }}
                    >
                        {name}
                    </div>
                    <div>
                        <WorkspacePublicStatusIcon
                            isPublicSpace={workspace.isPublicSpace}
                            isPersonal={workspace.isPersonal}
                            internalPublicSetting={internalPublicSetting}
                        />
                    </div>
                </div>
                {userProfiles && <MemberIconList profiles={userProfiles} numberOfDisplayIcons={10} />}
            </div>
        </Link>
    );
};
