import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';
import { useD3Drag, DragEvent } from '@view-model/models/common/hooks/useD3Drag';

const STROKE_WIDTH = 16;
const HIT_STROKE_WIDTH = 48; // 当たり判定の直線の太さ (ドラッグ時につかみやすいように)

type Props = {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
    onDragStart(): void;
    onDrag(event: DragEvent): void;
    onDragEnd(): void;
    getGuardProps: GetGuardProps;
};

export const SideLine: React.FC<Props> = ({ x1, y1, x2, y2, onDragStart, onDrag, onDragEnd, getGuardProps }: Props) => {
    const ref = useD3Drag<SVGLineElement>({ onDragStart, onDrag, onDragEnd });
    const cursor = x1 == x2 ? 'ew-resize' : y1 == y2 ? 'ns-resize' : undefined;

    return (
        <>
            {/* 見える直線 */}
            <line className="stroke-brand" x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth={STROKE_WIDTH} />
            {/* 見える直線よりも広い当たり判定の直線 */}
            <line
                ref={ref}
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                strokeWidth={HIT_STROKE_WIDTH}
                strokeOpacity={0}
                cursor={cursor}
                {...getGuardProps()}
            />
        </>
    );
};
