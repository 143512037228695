import { UserKey } from '@user/domain';
import { ViewModelClipboardPayload } from '@view-model/application/operation';
import { ThemeColor } from '@view-model/models/common/color';

// オリジナルのオンボーディング用ビューモデル→https://balus.app/view-models/qOsHwkS_MW1AuqwutZSkY
export const getOnboardingViewModelPayload = (userKey: UserKey): ViewModelClipboardPayload => {
    const userKeyString = userKey.toString();

    return {
        application: 'Balus/Beta',
        target: 'ViewModel',
        contentType: 'ViewModel',
        id: 'bGuy6hauc3SgHtppXlWNk',
        sourceViewModelId: 'qOsHwkS_MW1AuqwutZSkY',
        sourceWorkspaceId: 'rGZ8-DyrxOxOlm3aRgPUs',
        name: 'オンボーディングKATA',
        views: [
            {
                key: 'View:Y6cg4ghAx8Ficy3EaGybS',
                modelKey: 'Model:MLTGLKl6F9xHQ-qlTXPU4',
                name: '初めてのビュー（この枠をビューと呼びます）',
                size: {
                    width: 3584,
                    height: 3072,
                },
                setting: {
                    showNodeCreatedUser: false,
                    isLocked: false,
                    isPositionFixed: false,
                },
                order: 1677631595622,
                createdAt: 1677631595622,
                themeColor: ThemeColor.White,
            },
            {
                key: 'View:3EJMu1x4u2KQgSklR2rb9',
                modelKey: 'Model:GphHplHlWY8GH0ZLZ-TkN',
                name: '参考資料',
                size: {
                    width: 1536,
                    height: 1536,
                },
                setting: {
                    showNodeCreatedUser: false,
                    isLocked: false,
                    isPositionFixed: false,
                },
                order: 1677631610340,
                createdAt: 1677631610340,
                themeColor: ThemeColor.White,
            },
            {
                key: 'View:6B6DUlfKOm_-DFsxMegS2',
                modelKey: 'Model:wU_bCBzS5z4mqsQgwciXc',
                name: 'Balus の基本的な使い方',
                size: {
                    width: 4096,
                    height: 3072,
                },
                setting: {
                    showNodeCreatedUser: false,
                    isLocked: false,
                    isPositionFixed: false,
                },
                order: 1711418843669,
                createdAt: 1711418843669,
                themeColor: ThemeColor.White,
            },
        ],
        viewPositions: {
            '3EJMu1x4u2KQgSklR2rb9': {
                x: -3584,
                y: 0,
            },
            '6B6DUlfKOm_-DFsxMegS2': {
                x: -3584,
                y: 2048,
            },
            Y6cg4ghAx8Ficy3EaGybS: {
                x: 1024,
                y: 2048,
            },
        },
        consistencyLinks: {},
        models: [
            {
                key: 'Model:GphHplHlWY8GH0ZLZ-TkN',
                type: 'Sticky',
                namespace: 'Balus',
                nodes: [
                    {
                        key: 'Node:Maiu3Y3BG-p4k0UNDhhmz',
                        name: 'Balusをはじめよう：管理者編',
                        position: {
                            x: 448,
                            y: 320,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'White',
                        },
                        type: 'Sticky',
                        createdAt: '2023-03-01T00:46:50.339Z',
                        url: 'https://balus-help.levii.co.jp/hc/ja/articles/15468851519769',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:wflbQaraBm08v1sR_kfYK',
                        name: 'Balusをはじめよう：メンバー編',
                        position: {
                            x: 832,
                            y: 320,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'White',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:36.484Z',
                        url: 'https://balus-help.levii.co.jp/hc/ja/articles/17679448635673',
                        createdUserKey: userKeyString,
                    },
                ],
                links: [],
                zones: [
                    {
                        key: 'StickyZone:RfoXFh9_asqtTMtTL-5zB',
                        text: 'Balusのキホン',
                        size: {
                            width: 768,
                            height: 512,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'White',
                        },
                        createdUserKey: userKeyString,
                        createdAt: '2024-03-26T02:11:25.442Z',
                    },
                ],
                zonePositions: {
                    'RfoXFh9_asqtTMtTL-5zB': {
                        x: 384,
                        y: 128,
                    },
                },
                version: 3,
                comments: {
                    modelKey: 'Model:GphHplHlWY8GH0ZLZ-TkN',
                    threads: [],
                    positions: {},
                },
                descriptionPanels: {
                    panels: [
                        {
                            id: 'zsafkfv17vj7bo4kh6nz',
                            description: '# →「ガイドを表示」をクリックしてみてください',
                            size: {
                                width: 1280,
                                height: 768,
                            },
                            createdAt: 1711419393388,
                            fontSize: 'L',
                        },
                    ],
                    positions: {
                        zsafkfv17vj7bo4kh6nz: {
                            x: 128,
                            y: 704,
                        },
                    },
                },
                nodeDescriptions: {},
                zoneDescriptions: {},
                displayOrderTree: {
                    nodeIds: [],
                    zones: [
                        {
                            id: 'RfoXFh9_asqtTMtTL-5zB',
                            nodeIds: ['Maiu3Y3BG-p4k0UNDhhmz', 'wflbQaraBm08v1sR_kfYK'],
                            zones: [],
                        },
                    ],
                },
            },
            {
                key: 'Model:MLTGLKl6F9xHQ-qlTXPU4',
                type: 'Sticky',
                namespace: 'Balus',
                nodes: [],
                links: [],
                zones: [],
                zonePositions: {},
                version: 3,
                comments: {
                    modelKey: 'Model:MLTGLKl6F9xHQ-qlTXPU4',
                    threads: [],
                    positions: {},
                },
                descriptionPanels: {
                    panels: [
                        {
                            id: '5n6djw01pt64o75som65',
                            description: '# このビューのどこかをダブルクリックして、付箋を追加してみてください',
                            size: {
                                width: 1280,
                                height: 832,
                            },
                            createdAt: 1711419164625,
                            fontSize: 'L',
                        },
                    ],
                    positions: {
                        '5n6djw01pt64o75som65': {
                            x: 192,
                            y: 128,
                        },
                    },
                },
                nodeDescriptions: {},
                zoneDescriptions: {},
                displayOrderTree: {
                    nodeIds: [],
                    zones: [],
                },
            },
            {
                key: 'Model:wU_bCBzS5z4mqsQgwciXc',
                type: 'Sticky',
                namespace: 'Balus',
                nodes: [
                    {
                        key: 'Node:BZhaIULbvYevzpOpDV52J',
                        name: '味噌',
                        position: {
                            x: 2176,
                            y: 1664,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Orange',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:DjvokzVbP2kpELOZGb4tq',
                        name: '塩',
                        position: {
                            x: 1536,
                            y: 1280,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'YellowGreen',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:HKYtY_yrRWK6vTzLobPOK',
                        name: '付箋',
                        position: {
                            x: 2944,
                            y: 960,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Blue',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:LiWoyCAy-LEvx4QE8DQRA',
                        name: 'ラーメンと\n言えば？',
                        position: {
                            x: 1792,
                            y: 768,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Red',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:QGaATX6wi3sRj2owa8JYU',
                        name: 'あああああああああああああああああああああああ',
                        position: {
                            x: 1984,
                            y: 2112,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Yellow',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:SCIBXLPV_lHDZj5_JHa3_',
                        name: 'ノード',
                        position: {
                            x: 576,
                            y: 2176,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Yellow',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:USsmct6WGmPp8UF6DYXWW',
                        name: 'テキスト',
                        position: {
                            x: 2560,
                            y: 1984,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Yellow',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:aauq_TqZ8UU6ZnX_DL2NH',
                        name: '👍',
                        position: {
                            x: 896,
                            y: 1280,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'White',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:15:13.653Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:ereX2sQovEKGrIEp95zpT',
                        name: '',
                        position: {
                            x: 2112,
                            y: 2240,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Yellow',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:fea9YG7VsuLUeIsBOo1P4',
                        name: '',
                        position: {
                            x: 960,
                            y: 2240,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Yellow',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:16:09.924Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:iodL399J_qrIa5F_iXUg3',
                        name: '',
                        position: {
                            x: 2240,
                            y: 2368,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Yellow',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                    {
                        key: 'Node:uq2bzlMs_r9d2-Oj3xC_N',
                        name: '',
                        position: {
                            x: 2368,
                            y: 2496,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'Yellow',
                        },
                        type: 'Sticky',
                        createdAt: '2024-03-26T02:07:23.667Z',
                        createdUserKey: userKeyString,
                    },
                ],
                links: [
                    {
                        key: 'Link:1V-8868Hz80SWGziT4h2G',
                        fromKey: 'Node:LiWoyCAy-LEvx4QE8DQRA',
                        toKey: 'Node:DjvokzVbP2kpELOZGb4tq',
                        name: '',
                        bend: {
                            r: 0.5,
                            d: 0,
                        },
                        style: {
                            lineStyle: 'Solid',
                            markStyle: 'Arrow',
                            color: 'Gray',
                        },
                        multiplicity: {
                            isEnabled: false,
                            source: {
                                lower: 0,
                                upper: 1,
                            },
                            target: {
                                lower: 0,
                                upper: 1,
                            },
                        },
                    },
                    {
                        key: 'Link:7I81fVzl94BR51fznxoSz',
                        fromKey: 'Node:SCIBXLPV_lHDZj5_JHa3_',
                        toKey: 'Node:DjvokzVbP2kpELOZGb4tq',
                        name: 'リンクの名前も書けます',
                        bend: {
                            r: 0.5,
                            d: 0,
                        },
                        style: {
                            lineStyle: 'Solid',
                            markStyle: 'Diamond',
                            color: 'Gray',
                        },
                        multiplicity: {
                            isEnabled: false,
                            source: {
                                lower: 0,
                                upper: 1,
                            },
                            target: {
                                lower: 0,
                                upper: 1,
                            },
                        },
                    },
                    {
                        key: 'Link:D3r3tLeot884F6FGUJEE8',
                        fromKey: 'Node:aauq_TqZ8UU6ZnX_DL2NH',
                        toKey: 'Node:DjvokzVbP2kpELOZGb4tq',
                        name: '',
                        bend: {
                            r: 0.5,
                            d: 0,
                        },
                        style: {
                            lineStyle: 'Solid',
                            markStyle: 'Arrow',
                            color: 'Gray',
                        },
                        multiplicity: {
                            isEnabled: false,
                            source: {
                                lower: 1,
                                upper: 1,
                            },
                            target: {
                                lower: 1,
                                upper: 1,
                            },
                        },
                    },
                    {
                        key: 'Link:EOU1fsV5IAcNogGNbivtb',
                        fromKey: 'Node:USsmct6WGmPp8UF6DYXWW',
                        toKey: 'Node:LiWoyCAy-LEvx4QE8DQRA',
                        name: '',
                        bend: {
                            r: 0.6220847655444255,
                            d: 367.94167165174315,
                        },
                        style: {
                            lineStyle: 'Solid',
                            markStyle: 'Arrow',
                            color: 'Gray',
                        },
                        multiplicity: {
                            isEnabled: false,
                            source: {
                                lower: 0,
                                upper: 1,
                            },
                            target: {
                                lower: 0,
                                upper: 1,
                            },
                        },
                    },
                    {
                        key: 'Link:Qck6TGIeu8B_o-ws9zqVN',
                        fromKey: 'Node:QGaATX6wi3sRj2owa8JYU',
                        toKey: 'Node:DjvokzVbP2kpELOZGb4tq',
                        name: '',
                        bend: {
                            r: 0.5,
                            d: 0,
                        },
                        style: {
                            lineStyle: 'Solid',
                            markStyle: 'Diamond',
                            color: 'Gray',
                        },
                        multiplicity: {
                            isEnabled: true,
                            source: {
                                lower: 0,
                                upper: 1,
                            },
                            target: {
                                lower: 1,
                                upper: 1,
                            },
                        },
                    },
                    {
                        key: 'Link:_1cjTvpwY3BQKrQ2Hq7q4',
                        fromKey: 'Node:LiWoyCAy-LEvx4QE8DQRA',
                        toKey: 'Node:BZhaIULbvYevzpOpDV52J',
                        name: '',
                        bend: {
                            r: 0.5,
                            d: 0,
                        },
                        style: {
                            lineStyle: 'Solid',
                            markStyle: 'Arrow',
                            color: 'Gray',
                        },
                        multiplicity: {
                            isEnabled: false,
                            source: {
                                lower: 0,
                                upper: 1,
                            },
                            target: {
                                lower: 0,
                                upper: 1,
                            },
                        },
                    },
                ],
                zones: [
                    {
                        key: 'StickyZone:Jcr8fl69HB1ty9CQu8Y4c',
                        text: '',
                        size: {
                            width: 960,
                            height: 1344,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'White',
                        },
                        createdUserKey: userKeyString,
                        createdAt: '2024-03-26T02:07:23.667Z',
                    },
                    {
                        key: 'StickyZone:iOCIL9nZMDmeICM2o5hpF',
                        text: '',
                        size: {
                            width: 1088,
                            height: 768,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'White',
                        },
                        createdUserKey: userKeyString,
                        createdAt: '2024-03-26T02:07:23.667Z',
                    },
                    {
                        key: 'StickyZone:krlviZ2UDEmdhGt_Shv6U',
                        text: 'ゾーン',
                        size: {
                            width: 1280,
                            height: 704,
                        },
                        style: {
                            fontSize: 'L',
                            themeColor: 'YellowGreen',
                        },
                        createdUserKey: userKeyString,
                        createdAt: '2024-03-26T02:07:23.667Z',
                    },
                ],
                zonePositions: {
                    Jcr8fl69HB1ty9CQu8Y4c: {
                        x: 1920,
                        y: 1472,
                    },
                    iOCIL9nZMDmeICM2o5hpF: {
                        x: 448,
                        y: 1984,
                    },
                    krlviZ2UDEmdhGt_Shv6U: {
                        x: 2752,
                        y: 704,
                    },
                },
                version: 3,
                comments: {
                    modelKey: 'Model:wU_bCBzS5z4mqsQgwciXc',
                    threads: [
                        {
                            id: 'MCTv6kyxe5fd17f5h2kufch',
                            status: 'Open',
                            primaryComment: {
                                id: 'MCdw9c47to3g8vq8esqz8i',
                                author: {
                                    userKey: 'User:5639728506339328',
                                    name: 'Ryosuke Yoshizawa',
                                    imageURL: null,
                                },
                                body: 'コメントもかけます',
                                type: 'Regular',
                                createdAt: '2021-12-16T02:12:53.862Z',
                                updatedAt: '2021-12-16T02:12:53.862Z',
                            },
                            replyComments: [],
                        },
                    ],
                    positions: {
                        MCTv6kyxe5fd17f5h2kufch: {
                            x: 3392,
                            y: 192,
                        },
                    },
                },
                descriptionPanels: {
                    panels: [
                        {
                            id: 'qf65wlds04z412tbfm7p',
                            description:
                                '# Balusの基本操作\n\n- 画面の白い領域をダブルクリックすると付箋を作成できます。\n- 付箋をダブルクリックするとテキストが入力できます。\n- 付箋を選択した状態で、付箋のまわりに表示される矢印をクリックした後に他の付箋をクリックするとリンクを繋げることができます（ドラッグでもリンクが繋がります）\n- 「ctrl+c」で付箋をコピー、「ctrl+v」で貼り付けできます。\n- 「delete」「backspace」で付箋を削除できます。\n- 「ctrl+z」で操作を戻すことができます。\n- shiftキーを押しながら付箋・リンクをクリックすることで複数選択ができます。\n- shiftキーを押しながら、白い領域上でドラッグすると四角い範囲で複数選択ができます。',
                            size: {
                                width: 1280,
                                height: 960,
                            },
                            createdAt: 1711418843667,
                            fontSize: 'S',
                        },
                    ],
                    positions: {
                        qf65wlds04z412tbfm7p: {
                            x: 64,
                            y: 192,
                        },
                    },
                },
                nodeDescriptions: {
                    HKYtY_yrRWK6vTzLobPOK: {
                        elementId: 'HKYtY_yrRWK6vTzLobPOK',
                        content: 'こういうのもかけます',
                        relativePosition: {
                            x: 384,
                            y: 0,
                        },
                        visible: true,
                    },
                },
                zoneDescriptions: {
                    krlviZ2UDEmdhGt_Shv6U: {
                        elementId: 'krlviZ2UDEmdhGt_Shv6U',
                        content: 'ゾーンにも補足説明をつけられます',
                        relativePosition: {
                            x: 384,
                            y: 0,
                        },
                        visible: true,
                    },
                },
                displayOrderTree: {
                    nodeIds: ['LiWoyCAy-LEvx4QE8DQRA', 'DjvokzVbP2kpELOZGb4tq', 'aauq_TqZ8UU6ZnX_DL2NH'],
                    zones: [
                        {
                            id: 'krlviZ2UDEmdhGt_Shv6U',
                            nodeIds: ['HKYtY_yrRWK6vTzLobPOK'],
                            zones: [],
                        },
                        {
                            id: 'iOCIL9nZMDmeICM2o5hpF',
                            nodeIds: ['SCIBXLPV_lHDZj5_JHa3_', 'fea9YG7VsuLUeIsBOo1P4'],
                            zones: [],
                        },
                        {
                            id: 'Jcr8fl69HB1ty9CQu8Y4c',
                            nodeIds: [
                                'QGaATX6wi3sRj2owa8JYU',
                                'ereX2sQovEKGrIEp95zpT',
                                'BZhaIULbvYevzpOpDV52J',
                                'uq2bzlMs_r9d2-Oj3xC_N',
                                'iodL399J_qrIa5F_iXUg3',
                                'USsmct6WGmPp8UF6DYXWW',
                            ],
                            zones: [],
                        },
                    ],
                },
            },
        ],
    };
};
