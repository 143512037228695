export enum StickyZoneMenuId {
    FontSize = 'FontSize',
    ThemeColor = 'ThemeColor',
    HyperLink = 'HyperLink',
    ZoneDescription = 'ZoneDescription',
    Grouping = 'Grouping',
    ConvertToView = 'ConvertToView',
    Analysis = 'Analysis',
    DeleteZone = 'DeleteZone',
}
