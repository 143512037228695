import { useCallback, useMemo, memo } from 'react';
import { Position, Size } from '@view-model/models/common/types/ui';
import { DirectionName, LinkCreatorIcon } from './LinkCreatorIcon';
import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';

export const DEFAULT_ICON_CIRCLE_SIZE = 16;

type Props = {
    elementSize: Size;
    directions?: DirectionName[];
    iconOffset?: number;
    iconCircleSize?: number;
    onLinkerStart(startPosition: Position): void;
    onLinkerMove(currentPosition: Position): void;
    onLinkerEnd(currentPosition: Position): void;
    getGuardProps?: GetGuardProps;
};

type IconAttribute = {
    direction: DirectionName;
    position: Position;
};

const addPosition = ({ x: x1, y: y1 }: Position, { x: x2, y: y2 }: Position): Position => {
    return {
        x: x1 + x2,
        y: y1 + y2,
    };
};

const LinkCreator: React.FC<Props> = memo(
    ({
        elementSize,
        directions = ['down', 'left', 'right', 'up'],
        iconOffset = 32,
        iconCircleSize = DEFAULT_ICON_CIRCLE_SIZE,
        onLinkerStart,
        onLinkerMove,
        onLinkerEnd,
        getGuardProps,
    }: Props) => {
        const { width, height } = elementSize;

        const offset = useMemo<Position>(
            () => ({
                x: width / 2,
                y: height / 2,
            }),
            [width, height]
        );

        const iconAttributes = useMemo<IconAttribute[]>(() => {
            const [dx, dy] = [offset.x + iconOffset, offset.y + iconOffset];
            const attributes: Record<DirectionName, Position> = {
                down: { x: 0, y: dy },
                left: { x: -dx, y: 0 },
                right: { x: dx, y: 0 },
                up: { x: 0, y: -dy },
            };

            return directions.map((direction) => {
                const position = attributes[direction];

                return {
                    direction,
                    position,
                };
            });
        }, [offset, directions, iconOffset]);

        const handleClick = useCallback(
            (startPosition: Position) => {
                onLinkerStart(addPosition(startPosition, offset));
            },
            [onLinkerStart, offset]
        );

        const handleDragStart = useCallback(
            (currentPosition: Position) => {
                onLinkerStart(addPosition(currentPosition, offset));
            },
            [onLinkerStart, offset]
        );

        const handleDrag = useCallback(
            (currentPosition: Position) => {
                onLinkerMove(addPosition(currentPosition, offset));
            },
            [onLinkerMove, offset]
        );

        const handleDragEnd = useCallback(
            (currentPosition: Position) => {
                onLinkerEnd(addPosition(currentPosition, offset));
            },
            [onLinkerEnd, offset]
        );

        // LinkCreator表示対象の要素の中心に transform した後に、上下左右の指定位置にもう一度 transform する
        return (
            <g transform={`translate(${offset.x},${offset.y})`}>
                {iconAttributes.map(({ direction, position }) => (
                    <LinkCreatorIcon
                        key={direction}
                        direction={direction}
                        position={position}
                        onClick={handleClick}
                        onDragStart={handleDragStart}
                        onDrag={handleDrag}
                        onDragEnd={handleDragEnd}
                        iconCircleSize={iconCircleSize}
                        getGuardProps={getGuardProps}
                    />
                ))}
            </g>
        );
    }
);

LinkCreator.displayName = 'LinkCreator';
export { LinkCreator };
