import { CommandManager, CompositeCommand, ICommand } from '@model-framework/command';
import { StickyZoneId, UserId, ViewModelId } from '@schema-common/base';
import { UserKey } from '@user/domain';
import { PositionSet } from '@view-model/models/common/PositionSet';
import { Point, Rect } from '@view-model/models/common/basic';
import { Position, Size } from '@view-model/models/common/types/ui';
import { DisplayOrderRepository } from '@view-model/models/framework/display-order';
import { NodeCollection, StickyNode } from '@view-model/models/sticky/StickyNodeView';
import { ModelLayout } from '@view-model/models/sticky/layout';
import { StickyZoneChangeParentZoneCommand, StickyZoneCreateCommand } from '../command';
import { StickyZone, StickyZoneCollection, StickyZoneSize, StickyZoneStyle } from '../domain';
import { StickyZoneEntityOperation } from './StickyZoneEntityOperation';
import { StickyModel } from '@view-model/domain/model';

export class StickyZoneCreationOperation {
    private readonly offsetX: number;
    private readonly offsetY: number;
    private readonly viewPadding: number;

    constructor(
        private readonly viewModelId: ViewModelId,
        private readonly model: StickyModel,
        private readonly currentUserId: UserId,
        private readonly zoneEntityOperation: StickyZoneEntityOperation,
        private readonly commandManager: CommandManager,
        private readonly displayOrderRepository: DisplayOrderRepository
    ) {
        // TODO: 付箋ノード、付箋ゾーンのサイズ情報は、付箋モデルの設定情報として切り出す
        const { width, height } = StickyNode.size();
        this.offsetX = width / 2;
        this.offsetY = height / 2;

        // ビュー内のランダム位置に追加する際のパディング
        this.viewPadding = width > height ? width : height;
    }

    /**
     * 指定した位置にゾーンを作成する
     * @param position
     * @param style
     */
    createZone(position: Position, style: StickyZoneStyle): StickyZoneId {
        const snappedPosition = ModelLayout.snapPosition(position);
        const zone = StickyZone.buildNew({
            style,
            createdUserKey: UserKey.buildFromId(this.currentUserId),
        });
        const zonePositionSet = PositionSet.load({ [zone.id]: snappedPosition });
        const command = new StickyZoneCreateCommand(
            this.viewModelId,
            this.model,
            this.zoneEntityOperation,
            zone,
            zonePositionSet,
            this.displayOrderRepository
        );
        this.commandManager.execute(command);
        return zone.id;
    }

    /**
     * 指定の位置からオフセットした位置を返す
     * @param point
     */
    getOffsetPosition(point: Point): Point {
        return point.addXY(this.offsetX, this.offsetY);
    }

    /**
     * 指定されたビューの左上領域のランダムな位置を返す
     * @param width
     * @param height
     */
    getRandomPosition({ width, height }: Size): Point {
        const paddingInnerRect = Rect.fromSize(width, height).applyPaddingKeepingCenter(this.viewPadding);
        return Rect.fromPoints(paddingInnerRect.topLeft(), paddingInnerRect.getCenterPoint()).getRandomPoint();
    }

    /**
     * 指定されたノードを含むゾーンを作ります。
     * 作成できた場合は作成されたゾーンのIDを返し、そうでない場合はnullを返します。
     * @param nodes 作成されるゾーンに追加される子要素
     * @param zones 作成されるゾーンに追加される子要素
     * @param style ゾーンのスタイル
     */
    async createZoneFor(
        nodes: NodeCollection,
        zones: StickyZoneCollection,
        zonePositions: PositionSet,
        style: StickyZoneStyle
    ): Promise<StickyZoneId | null> {
        // 新たに作るゾーンの位置とサイズを計算する
        const newZoneInfo = this.buildZoneFor(nodes, zones, zonePositions, style);
        if (!newZoneInfo) return null;

        const newZone = newZoneInfo.zone;
        const newZonePosition = newZoneInfo.zonePosition;

        const createNewZoneCommand = this.buildCreateZoneCommand(newZone, newZonePosition);

        if (!createNewZoneCommand) return null;

        const orderTree = await this.displayOrderRepository.load();

        const minimizedElementIds = orderTree.minimizeElementIds([...nodes.ids(), ...zones.ids()]);

        const stickyZoneDisplayOrderCommand = new StickyZoneChangeParentZoneCommand(
            minimizedElementIds,
            newZone.id,
            this.displayOrderRepository
        );

        const ancestorZoneId = orderTree.findLowestCommonAncestorZoneId(minimizedElementIds);
        let commands: ICommand;
        if (ancestorZoneId) {
            const parentsStickyZoneDisplayOrderCommand = new StickyZoneChangeParentZoneCommand(
                [newZone.id],
                ancestorZoneId,
                this.displayOrderRepository
            );
            commands = new CompositeCommand(
                createNewZoneCommand,
                stickyZoneDisplayOrderCommand,
                parentsStickyZoneDisplayOrderCommand
            );
        } else {
            commands = new CompositeCommand(createNewZoneCommand, stickyZoneDisplayOrderCommand);
        }

        this.commandManager.execute(commands);

        return newZone.id;
    }

    /**
     * ゾーンを新規作成するコマンドを返します。
     * @param newZone
     * @param newZonePosition
     */
    private buildCreateZoneCommand(newZone: StickyZone, newZonePosition: Point): ICommand {
        return new StickyZoneCreateCommand(
            this.viewModelId,
            this.model,
            this.zoneEntityOperation,
            newZone,
            PositionSet.fromArray([[newZone.id, newZonePosition]]),
            this.displayOrderRepository
        );
    }

    private buildZoneFor(
        nodes: NodeCollection,
        zones: StickyZoneCollection,
        zonePositions: PositionSet,
        style: StickyZoneStyle
    ): { zone: StickyZone; zonePosition: Point } | null {
        const { GridSize } = ModelLayout;

        // ゾーンの上部はドラッグバーとテキストがあるので多めにマージンをとる
        const nodeBounds = nodes.getBounds();
        const zoneBounds = zones.getBounds(zonePositions);

        // rectsがnullを含まないようにする
        const rects = [nodeBounds, zoneBounds].filter(Boolean);
        const zoneRect = Rect.union(rects as Rect[])
            ?.applyMarginKeepingCenter(GridSize * 3, GridSize, GridSize)
            ?.snapTo(ModelLayout);

        if (!zoneRect) return null;

        const zonePosition = Point.load(zoneRect.topLeft());
        return {
            zone: StickyZone.buildNew({
                size: StickyZoneSize.load(zoneRect.size),
                style,
                createdUserKey: UserKey.buildFromId(this.currentUserId),
            }),
            zonePosition: zonePosition,
        };
    }
}
