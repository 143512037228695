import { IpRestrictionForm } from './IpRestrictionForm';
import { GroupConsoleTitle } from '@user/pages/GroupConsole/common';
import { useSnapshot } from '@framework/hooks';
import { RTDBPath, RefBuilder } from '@framework/repository';
import { GroupEntity } from '@group/domain';
import {
    IpRestrictionConfiguration,
    defaultIpRestrictionConfiguration,
} from '@schema-app/group/ip-restrictions/{groupId}/IpRestrictionConfiguration';
import { Redirect } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { useCurrentSessionId } from '@framework/auth/AuthContext';

interface Props {
    group: GroupEntity;
}

export const GroupConsoleIpRestrictionPage = ({ group }: Props) => {
    const [isEnabledIpRestrictionFeature, { loading }] = useSnapshot<boolean>({
        path: RTDBPath.Group.ipRestrictionEnabledPath(group.id),
        load({ snapshot }) {
            return !!snapshot.val();
        },
    });

    if (loading) {
        return null;
    }

    return isEnabledIpRestrictionFeature ? (
        <GroupConsoleIpRestrictionPageContent group={group} />
    ) : (
        <Redirect to={UserPagePathBuilder.groupConsolePage(group.id)} />
    );
};

const GroupConsoleIpRestrictionPageContent = ({ group }: Props) => {
    const [ipRestrictionConfiguration] = useSnapshot<IpRestrictionConfiguration>({
        path: RTDBPath.Group.ipRestrictionConfigurationPath(group.id),
        load({ snapshot }) {
            return (snapshot.val() as null | IpRestrictionConfiguration) ?? defaultIpRestrictionConfiguration;
        },
    });

    const saveIpRestrictionConfiguration = async (config: IpRestrictionConfiguration) => {
        const ref = RefBuilder.ref(RTDBPath.Group.ipRestrictionConfigurationPath(group.id));
        await ref.set(config);
    };

    const sessionId = useCurrentSessionId();
    const [currentIp] = useSnapshot({
        path: RTDBPath.User.activeAuthSessionCurrentIpPath(sessionId || ''),
        skip: !sessionId,
        load({ snapshot }) {
            return snapshot.val() as string | null;
        },
    });

    return (
        <div className="pb-8">
            <div className={'mb-4'}>
                <GroupConsoleTitle title="IPアドレス制限" />
            </div>
            <div className="mb-4 px-2">
                {ipRestrictionConfiguration && (
                    <IpRestrictionForm
                        ipRestrictionConfiguration={ipRestrictionConfiguration}
                        saveIpRestrictionConfiguration={saveIpRestrictionConfiguration}
                        currentIp={currentIp}
                    />
                )}
            </div>
        </div>
    );
};
