import { Random } from '@framework/Random';
import { Timestamp } from '@framework/Timestamp';
import { AnnouncementJSON } from '@schema-app/notification/announcements/{announcementId}/AnnouncementJSON';
import { AnnouncementId } from '@schema-common/base';

export class Announcement {
    constructor(
        readonly id: AnnouncementId,
        readonly title: string,
        readonly url: string | null,
        readonly notifiedAt: Timestamp
    ) {}

    dump(): AnnouncementJSON {
        const { id, title, url, notifiedAt } = this;
        return {
            id,
            title,
            url: url || null,
            notifiedAt: notifiedAt.toUnixTimestamp(),
        };
    }

    get notifiedAtAsString(): string {
        return this.notifiedAt.format('YYYY/MM/DD HH:mm');
    }

    static load(dump: AnnouncementJSON): Announcement {
        const { id, title, url, notifiedAt } = dump;
        return new this(id, title, url || null, new Timestamp(notifiedAt));
    }

    static buildNew(notifiedAt = Timestamp.now().startOf('hours')): Announcement {
        const id = Random.generateRandomID();
        return new this(id, '', '', notifiedAt);
    }

    /**
     * お知らせを日付降順で並び替える comparator
     */
    static compare(a: Announcement, b: Announcement): number {
        return b.notifiedAt.compareTo(a.notifiedAt);
    }

    withTitle(title: string): Announcement {
        return Announcement.load({ ...this.dump(), title });
    }

    withUrl(url: string): Announcement {
        return Announcement.load({ ...this.dump(), url });
    }

    withNotifiedAt(notifiedAt: Timestamp): Announcement {
        return Announcement.load({ ...this.dump(), notifiedAt: notifiedAt.toUnixTimestamp() });
    }

    isAfter(timestamp: Timestamp): boolean {
        return this.notifiedAt.isAfter(timestamp);
    }

    isValid(): boolean {
        return (
            !!this.title &&
            (!this.url || this.url.startsWith('http://') || this.url.startsWith('https://')) &&
            this.notifiedAt.isValid()
        );
    }
}
