import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// デバッグモードに指定するフラグのUnion型。 'example1' | 'example2' のように Union 型で指定する。
type DebugMode = 'example1' | 'ai_verification' | 'guest';

type DebugModeContextValues = {
    debugMode: string | null;
};

const DebugModeContext = createContext<DebugModeContextValues>({ debugMode: null });

type Props = {
    children: ReactElement;
};

/**
 * Balus の SPA を最初にロードした時点に設定されていた debug クエリパラメータをSPA内部のページ遷移時に付与し続けるコンテナコンポーネント。
 *
 * このコンテナコンポーネントを、 react-router-dom の Router 内部の最上位に追加することで、
 * SPA内部のページ移動をトリガーに移動後のURLに debug クエリパラメータの付与を行う。
 */
// ts-unused-exports:disable-next-line
export const WithDebugMode: React.FC<Props> = ({ children }: Props) => {
    const [initialDebugMode] = useState<string | null>(
        new URLSearchParams(window.location.search).get('debug') || null
    );

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!initialDebugMode) {
            return;
        }

        const params = new URLSearchParams(location.search);
        if (!params.has('debug')) {
            params.append('debug', initialDebugMode);
        }
        const newSearchString = '?' + params.toString();

        if (location.search === newSearchString) {
            return;
        }

        history.replace({ ...location, search: newSearchString });
    }, [history, initialDebugMode, location]);

    return <DebugModeContext.Provider value={{ debugMode: initialDebugMode }}>{children}</DebugModeContext.Provider>;
};

// ts-unused-exports:disable-next-line
export const useIsDebugMode = (mode: DebugMode): boolean => {
    const { debugMode } = useContext(DebugModeContext);

    return debugMode === mode;
};

// ts-unused-exports:disable-next-line
export const isDebugMode = (mode: DebugMode): boolean => {
    const param = new URLSearchParams(window.location.search).get('debug') || null;
    return param === mode;
};
