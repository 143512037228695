import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';

export enum WorkspaceMemberRole {
    admin = 'admin',
    editor = 'editor',
    viewer = 'viewer',
}

const WorkspaceRoleList = [WorkspaceMemberRole.admin, WorkspaceMemberRole.editor, WorkspaceMemberRole.viewer] as const;

const dict: Record<WorkspaceMemberRoleJSON, string> = {
    admin: '管理者',
    editor: '編集者',
    viewer: '閲覧者',
};

export namespace WorkspaceMemberRole {
    export function getLocalString(status: WorkspaceMemberRole): string {
        return dict[status];
    }

    export function values(): Readonly<WorkspaceMemberRole[]> {
        return WorkspaceRoleList;
    }

    export function fromString(value: string): WorkspaceMemberRole | undefined {
        return WorkspaceRoleList.find((role) => role.toString() === value);
    }

    export function load(value: string): WorkspaceMemberRole {
        return WorkspaceMemberRole.fromString(value) || WorkspaceMemberRole.viewer;
    }

    export function dump(role: WorkspaceMemberRole): string {
        return role.toString();
    }
}
