import { ViewEntity } from '@view-model/domain/view';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { MLAPIOperator } from '@view-model/application/ml-api/MLAPIOperator';
import { useViewLogSender } from '../ViewLogSender';
import { useIsDebugMode } from '../../../../../framework/utils';

type Props = {
    isOpen: boolean;
    viewModel: ViewModelEntity;
    view: ViewEntity;
    onStart(): void;
    onSuccess(payload: MLAPIPayload): void;
    onFailure(): void;
    onClose(): void;
    //ai_verification
    onChangeAiVerificationMode(mode: 'aiText' | 'aiViewModeling' | 'aiZoneComparison' | null): void;
};

export const ViewAnalysisSelectModal: React.FC<Props> = ({
    isOpen,
    viewModel,
    view,
    onStart,
    onSuccess,
    onFailure,
    onClose,
    onChangeAiVerificationMode,
}: Props) => {
    const logSender = useViewLogSender(view);
    const handleMLAPI = (promise: Promise<MLAPIPayload | null>) => {
        promise.then((payload) => {
            if (payload === null) {
                onFailure();
            } else {
                onSuccess(payload);
            }
        });

        onStart();
        onClose();
    };

    const handleOutlier = () => {
        handleMLAPI(MLAPIOperator.outlier(viewModel.id, view, logSender));
    };

    const handleCluster = () => {
        handleMLAPI(MLAPIOperator.cluster(viewModel.id, view, logSender));
    };

    const handleChatGPTCluster = () => {
        handleMLAPI(MLAPIOperator.chatGPTCluster(viewModel.id, view, logSender));
    };

    const handleChatGPTOutlier = () => {
        handleMLAPI(MLAPIOperator.chatGPTOutlier(viewModel.id, view, logSender));
    };

    const handleChatGPTQuestion = () => {
        handleMLAPI(MLAPIOperator.chatGPTQuestion(viewModel.id, view, logSender));
    };

    const handleChatGPTSummary = () => {
        handleMLAPI(MLAPIOperator.chatGPTSummary(viewModel.id, view, logSender));
    };

    const handleChatGPTModeling = () => {
        handleMLAPI(MLAPIOperator.chatGPTModeling(viewModel.id, view, logSender));
    };

    // ai_verification
    const isDebugMode = useIsDebugMode('ai_verification');

    return (
        <GeneralModal
            isOpen={isOpen}
            submitButton=""
            cancelButton={
                <Button color="secondary" onClick={onClose}>
                    閉じる | Cancel
                </Button>
            }
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            title={'実行する分析処理を選んでください | Select the analysis to perform'}
        >
            <div className="grid grid-cols-2 gap-2 p-4" style={{ width: '800px' }}>
                <Button color="brand" onClick={handleOutlier}>
                    外れ値のノードを探す | Find outlier nodes
                </Button>
                <Button color="brand" onClick={handleCluster}>
                    似たノードを色付けする | Color similar nodes
                </Button>
                <Button color="brand" onClick={handleChatGPTCluster}>
                    似たノードを色付けする (with ChatGPT) <br /> Color similar nodes (with ChatGPT)
                </Button>
                <Button color="brand" onClick={handleChatGPTOutlier}>
                    外れ値のノードを探す (with ChatGPT) <br /> Find outlier nodes (with ChatGPT)
                </Button>
                <Button color="brand" onClick={handleChatGPTQuestion}>
                    考えを深める質問を出す (with ChatGPT) <br /> Generate some questions (with ChatGPT)
                </Button>
                <Button color="brand" onClick={handleChatGPTSummary}>
                    要約テキストを生成する (with ChatGPT) <br /> Generate a summary text (with ChatGPT)
                </Button>
                <Button className="col-span-2" color="brand" onClick={handleChatGPTModeling}>
                    文章をモデリングする (with ChatGPT) | Create a model from text (with ChatGPT)
                    <br />
                    ※ビュー内の説明パネルのうち、最も長い文章からモデルを生成します
                </Button>
                {/* ai_verification */}
                {isDebugMode && (
                    <>
                        <Button
                            className="col-span-2 bg-orange-400"
                            onClick={() => {
                                onChangeAiVerificationMode('aiText');
                                onClose();
                            }}
                        >
                            ビューから文章（説明パネル）を生成する(検証用)
                        </Button>
                        <Button
                            className="col-span-2 bg-orange-400"
                            onClick={() => {
                                onChangeAiVerificationMode('aiViewModeling');
                                onClose();
                            }}
                        >
                            文章をモデリングする(検証用)
                        </Button>
                    </>
                )}
            </div>
        </GeneralModal>
    );
};
