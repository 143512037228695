import { useCurrentUserId } from '@framework/auth';
import { useSnapshot } from '@framework/hooks';
import { RefBuilder, RTDBPath } from '@framework/repository';
import { createContext, useContext } from 'react';
import { toast } from 'react-hot-toast';

const UserOperationModesContext = createContext<Tool | null>(null);

type Tool = {
    userOperationMode: UserOperationModes;
    modeSwitcher: (mode: UserOperationModes) => void;
};

type Props = {
    children: React.ReactNode;
};

export type UserOperationModes = 'map' | 'slide';

export const useUserOperationModes = () => {
    const ctx = useContext(UserOperationModesContext);

    if (ctx === null) {
        throw new Error('Use this hook within UserOperationModesProvider.');
    }

    return ctx;
};

export const UserOperationModesProvider: React.FC<Props> = ({ children }: Props) => {
    const currentUserId = useCurrentUserId();
    const path = RTDBPath.ViewModel.userOperationModePath(currentUserId || '');
    const [persistedMode] = useSnapshot<UserOperationModes>({
        path,
        skip: typeof currentUserId !== 'string',
        load: ({ snapshot }) => snapshot.val(),
        onError: () => {
            toast.error('読み込みに失敗しました');
        },
    });

    const switchUserOperationModes = (mode: UserOperationModes): void => {
        RefBuilder.ref(path).set(mode);
    };

    return (
        <UserOperationModesContext.Provider
            value={{
                userOperationMode: persistedMode || 'map',
                modeSwitcher: switchUserOperationModes,
            }}
        >
            {children}
        </UserOperationModesContext.Provider>
    );
};
