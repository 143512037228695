import { MLAPIConfigType } from './type';

const Localhost: { MLAPIConfig: MLAPIConfigType } = {
    MLAPIConfig: {
        outlier: 'http://localhost:8080/',
        cluster: 'http://localhost:8080/',
        chatgpt_cluster: 'http://localhost:8080/',
        chatgpt_outlier: 'http://localhost:8080/',
        chatgpt_question: 'http://localhost:8080/',
        chatgpt_summary: 'http://localhost:8080/',
        chatgpt_modeling: 'http://localhost:8080/',
        chatgpt_similar_node: 'http://localhost:8080/?key=',
        chatgpt_zone_title: 'http://localhost:8080/?key=',
        chatgpt_text: 'http://localhost:8080/',
        chatgpt_modeling_from_text: 'http://localhost:8080/',
        chatgpt_zone_comparison: 'http://localhost:8080/?zoneIds=',
    },
};

// 1. USE_ML_LOCALHOST が 'true' に設定されている場合には、ローカルホストの開発環境に接続する
// 2. levii-balus-app で動作している場合には、 levii-balus-ml-app に接続する
// 3. それ以外のプロジェクトで動作している場合には、 levii-balus-ml-dev に接続する
const config =
    process.env.USE_ML_LOCALHOST === 'true'
        ? Localhost
        : process.env.GOOGLE_CLOUD_PROJECT === 'levii-balus-app'
          ? require(`./levii-balus-ml-app.ts`)
          : require('./levii-balus-ml-dev.ts');

export const MLAPIConfig: MLAPIConfigType = config.MLAPIConfig;
