import { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useCurrentUserId } from '@framework/auth';
import { FlatButton } from '@framework/ui/atoms';
import { Loading } from '@framework/ui/atoms/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Props = {
    signUpToken: string;
};

type SignUpUserData = {
    id: string;
    name: string;
    mailAddress: string;
};

export const SignUpWithOAuthForm: React.FC<Props> = ({ signUpToken }: Props) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<SignUpUserData | null>(null);

    useEffect(() => {
        setLoading(true);
        setData(null);

        FunctionsUserActions.fetchSignUpUserData(signUpToken)
            .then((data) => {
                setData(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [signUpToken]);

    const currentUserId = useCurrentUserId();
    const history = useHistory();
    const location = useLocation();
    const moveToHome = useCallback(() => history.push('/'), [history]);

    // ユーザ登録画面をログイン済み状態で表示した場合には、 requestPath にリダイレクトする
    if (currentUserId) {
        const params = new URLSearchParams(location.search);
        const requestPath = params.get('requestPath');
        return <Redirect to={requestPath || '/'} />;
    }

    if (loading) return <Loading />;

    if (!data) {
        return (
            <>
                <div className="text-2xl font-bold">ユーザ登録のための情報が見つかりませんでした</div>
                <div className="pt-4">招待メールに記載のURLからもう一度操作を試してください。</div>
                <div className="flex flex-row justify-center pt-4">
                    <FlatButton color="brand" onClick={moveToHome}>
                        ホームに戻る
                    </FlatButton>
                </div>
            </>
        );
    }

    return <Content data={data} />;
};

type ContentProps = {
    data: SignUpUserData;
};

const Content: React.FC<ContentProps> = ({ data }: ContentProps) => {
    const [name, setName] = useState(data.name);
    const [processing, setProcessing] = useState(false);
    const isValid = name.length > 0;
    const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }, []);

    const handleSubmit = useCallback(() => {
        if (!isValid) return;
        setProcessing(true);

        FunctionsUserActions.signUp(data.id, name)
            .then((token) => {
                if (token) {
                    return FirebaseAuthenticationClient.signInWithCustomToken(token);
                } else {
                    alert('ユーザ登録処理に失敗しました。後でもう一度試してみてください。');
                }
            })
            .catch((err) => {
                console.error(err);
                alert('ユーザ登録処理に失敗しました。後でもう一度試してみてください。');
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [data.id, isValid, name]);

    return (
        <>
            <div className="text-2xl font-bold">新規ユーザ登録</div>

            <div className="pt-8 font-bold">名前</div>
            <input
                type="text"
                className="w-full rounded-sm border border-gray-500 p-2 focus:outline-none"
                value={name}
                onChange={handleChangeName}
            />

            <div className="pt-8 font-bold">メールアドレス</div>
            <input
                type="text"
                className="w-full cursor-not-allowed rounded-sm border border-gray-500 bg-gray-200 p-2 focus:outline-none"
                defaultValue={data.mailAddress}
                disabled
            />

            <div className="pt-8">
                続行することにより、Balus の
                <a
                    href="https://levii.co.jp/legal/terms-of-service/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700 underline hover:text-blue-800"
                >
                    サービス利用規約 <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
                と
                <a
                    href="https://levii.co.jp/legal/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-700 underline hover:text-blue-800"
                >
                    プライバシーポリシー <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a>
                に同意したものとみなされます。
            </div>

            <div className="flex flex-row justify-end pt-8">
                <FlatButton color="brand" onClick={handleSubmit} disabled={!isValid || processing} loading={processing}>
                    ユーザ登録する
                </FlatButton>
            </div>
        </>
    );
};
