import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from '@user/pages/RouteParams';
import { RTDBPath, TextRepository } from '@framework/repository';
import { isValidId } from '@schema-common/base';

export const useDetectGroupId = () => {
    const [groupIdState, setGroupIdState] = useState<string>('');
    const { groupId, workspaceId, viewModelId } = useParams<RouteParams>();

    useEffect(() => {
        const fetchGroupIdWithWorkspaceId = async (workspaceId: string): Promise<string | undefined> => {
            if (!isValidId(workspaceId)) return;

            const groupId = await new TextRepository(RTDBPath.Workspace.workspaceGroupIdPath(workspaceId)).get();
            if (!groupId) return;
            return groupId;
        };

        const fetchGroupIdWithViewModelId = async (viewModelId: string): Promise<string | undefined> => {
            if (!isValidId(viewModelId)) return;

            const groupId = await new TextRepository(RTDBPath.ViewModel.viewModelGroupIdPath(viewModelId)).get();
            if (!groupId) return;
            return groupId;
        };

        if (groupId) {
            setGroupIdState(groupId);
            return;
        }

        if (workspaceId) {
            fetchGroupIdWithWorkspaceId(workspaceId).then((value) => {
                if (!value) return;

                setGroupIdState(value);
            });
            return;
        }

        if (viewModelId) {
            fetchGroupIdWithViewModelId(viewModelId).then((value) => {
                if (!value) return;

                setGroupIdState(value);
            });
            return;
        }
    }, [groupId, workspaceId, viewModelId]);

    return groupIdState;
};
