import { useSnapshot } from '@framework/hooks';
import ReactModal from 'react-modal';
import { RTDBPath } from '@framework/repository';
import { GroupId } from '@schema-common/base';
import { GroupLicenseJSON } from '@schema-app/group/licenses/{groupId}/GroupLicenseJSON';
import { useEffect, useRef, useState } from 'react';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

interface Props {
    groupId?: GroupId;
}

/**
 * グループライセンスが切れていたらモーダルを表示する。RTDBに直接接続してライセンス情報を取得する
 */
export const CheckGroupLicenseExpirationViaRTDB = ({ groupId }: Props) => {
    const [isLicenseExpiration] = useSnapshot({
        path: RTDBPath.Group.groupLicensePath(groupId ?? ''),
        load({ snapshot }): boolean {
            const license = snapshot.val() as GroupLicenseJSON | null;

            if (!license) {
                console.error(`グループライセンスが見つかりませんでした: ${JSON.stringify({ groupId })}`);
                return false;
            }

            return Date.now() > license.expiresAt;
        },
        skip: typeof groupId !== 'string',
    });

    return isLicenseExpiration ? <ExpirationModalDialog /> : null;
};

/**
 * Cloud Functions を利用してグループライセンスが切れていたらモーダルを表示する
 * URL共有されたビューモデルに匿名ユーザーとしてアクセスする場合には、RTDBのライセンス情報にはアクセスできないのでCloud Functionsを利用する
 */
export const CheckGroupLicenseExpirationViaFunctions = ({ groupId }: Props) => {
    const [isLicenseExpiration, setIsLicenseExpiration] = useState<boolean>(false);
    const requestSentRef = useRef<boolean>(false);

    useEffect(() => {
        if (typeof groupId === 'string' && !requestSentRef.current) {
            requestSentRef.current = true;
            FunctionsUserActions.fetchGroupLicenseExpiration(groupId).then(
                (result) => {
                    setIsLicenseExpiration(!!result.isExpired);
                },
                () => {
                    requestSentRef.current = false;
                    console.error(
                        `グループライセンスが期限切れかどうかを取得できませんでした: ${JSON.stringify({ groupId })}`
                    );
                }
            );
        }
    }, [groupId]);

    return isLicenseExpiration ? <ExpirationModalDialog /> : null;
};

const style: ReactModal.Styles = {
    overlay: {
        zIndex: 100,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        padding: '0',
        transform: 'translate(-50%, -50%)',
        minWidth: '500px',
    },
};

const ExpirationModalDialog = () => {
    return (
        <ReactModal style={style} isOpen={true} ariaHideApp={false} shouldCloseOnOverlayClick={false}>
            <div className={'px-2'}>
                <div className={'m-3 text-xl'}>ライセンス期限切れ</div>
                <div className={'m-3'}>
                    グループのライセンス期限が終了しています。管理者または
                    <a
                        href="https://balus-help.levii.co.jp/hc/ja/requests/new"
                        className="text-blue-700 underline hover:no-underline"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ヘルプセンター
                    </a>
                    に連絡し、期限設定を行ってください。
                </div>
            </div>
        </ReactModal>
    );
};

ExpirationModalDialog.displayName = 'ExpirationModalDialog';
