import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { usePopupRef } from '@framework/hooks';
import { useCurrentUserEntity } from '@user/UserEntity';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { useUserPublicProfile } from '@user/PublicProfile';
import { classNames, generateDefaultUserIcon } from '@framework/utils';
import { AnonymousUserPopupMenu, RegularUserPopupMenu } from '@framework/ui/elements/TopNavigation';

type Props = {
    userId: string;
};

export const UserIconMenu: React.FC<Props> = ({ userId }: Props) => {
    const { isOpen, popupRef, handleOpen } = usePopupRef<HTMLDivElement>(false);
    const history = useHistory();

    const handleLogout = useCallback(() => {
        // 現在表示中のURLに対して logout パラメータを付与したURLにリダイレクトさせる
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('logout', '1');
        const currentUrl = window.location.pathname + '?' + urlParams.toString();

        const params = new URLSearchParams();
        params.set('requestPath', currentUrl);
        history.push(`${UserPagePathBuilder.logoutPage()}?${params.toString()}`);
    }, [history]);

    const profile = useUserPublicProfile(userId);
    const userEntity = useCurrentUserEntity();

    // profile が取得できないときには、アイコン・メニューを表示できないので null を返す
    if (!profile) return null;

    return (
        <div className="relative">
            <button
                className={classNames(
                    'flex h-12 w-12 items-center justify-center',
                    'cursor-pointer rounded-lg bg-white shadow-md hover:bg-gray-200 focus:outline-none'
                )}
                onClick={handleOpen}
                aria-label="ユーザメニューを開く | Open user menu"
            >
                {profile.imageUrl ? (
                    <img
                        className="block size-8 rounded-full border-2 border-gray-400"
                        src={profile.imageUrl}
                        alt={profile.name}
                    />
                ) : (
                    <div className="block size-8 overflow-hidden rounded-full border-2 border-gray-400">
                        {generateDefaultUserIcon({
                            id: userId,
                            name: profile.name,
                            size: 28,
                        })}
                    </div>
                )}
            </button>
            <div
                className={classNames(
                    'absolute right-0 z-50 mt-2 w-64 rounded-lg bg-white p-1 shadow-xl',
                    isOpen ? 'block' : 'hidden'
                )}
                ref={popupRef}
            >
                {/* UserEntity を取得できた場合には、通常ユーザ向けのメニューを表示する。それ以外は匿名ユーザ向けのメニューを表示する */}
                {userEntity ? (
                    <RegularUserPopupMenu userEntity={userEntity} onLogout={handleLogout} />
                ) : (
                    <AnonymousUserPopupMenu profile={profile} onLogout={handleLogout} />
                )}
            </div>
        </div>
    );
};
