import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { UserEntity } from '@user/UserEntity';
import { useCallback } from 'react';
import { Workspace } from '@workspace/domain/workspace';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { toast } from 'react-hot-toast';

type Props = {
    isOpen: boolean;
    memberData: UserEntity;
    workspace: Workspace;
    onClose(): void;
};

export const WorkspaceMemberDeleteConfirmModal: React.FC<Props> = ({
    isOpen,
    memberData,
    workspace,
    onClose,
}: Props) => {
    const handleSubmit = useCallback(async () => {
        if (await WorkspaceOperation.removeMembers(workspace.id, workspace.groupId, [memberData.id])) {
            toast.success(`ワークスペースメンバーから削除しました`);
        } else {
            toast.error(`ワークスペースメンバーからの削除に失敗しました`);
        }

        onClose();
    }, [memberData, workspace, onClose]);

    return (
        <GeneralModal
            isOpen={isOpen}
            submitButton={
                <Button color="danger" onClick={handleSubmit}>
                    削除 | Delete
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={onClose}>
                    閉じる | Cancel
                </Button>
            }
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            title={'ワークスペースメンバーから削除'}
        >
            <div className="p-4" style={{ width: '600px' }}>
                <p className={'pb-4'}>
                    ワークスペースからメンバーを削除すると、元に戻せません。
                    <br />
                    {memberData.name} さんを {workspace.name.value} から削除してよろしいですか？
                </p>
            </div>
        </GeneralModal>
    );
};
