import { useState } from 'react';
import { GeneralModal } from '@framework/ui/elements/GeneralModal';
import { Button } from '@framework/ui/atoms/Button';
import { useCurrentUserId } from '@framework/auth';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { toast } from 'react-hot-toast';
import { Workspace } from '@workspace/domain/workspace';
import { useHistory } from 'react-router-dom';

type Props = {
    isOpen: boolean;
    workspace: Workspace;
    onClose(): void;
    shouldMoveToGroupPage: boolean;
};

export const WorkspaceDeleteConfirmModal: React.FC<Props> = ({
    isOpen,
    workspace,
    onClose,
    shouldMoveToGroupPage,
}: Props) => {
    const currentUserId = useCurrentUserId();
    const history = useHistory();
    const [checked, setChecked] = useState<boolean>(false);

    const handleSubmit = async () => {
        if (!checked) return;

        if (!currentUserId) return;
        const result = await WorkspaceOperation.logicalDelete(workspace.id, currentUserId);

        if (result) {
            onClose();
            // サイドバーからワークスペースを削除する場合、削除対象のワークスペースを開いていない場合は
            // グループページへ遷移しなくて良いのでこの条件を入れている
            if (shouldMoveToGroupPage) {
                history.push(UserPagePathBuilder.groupPage(workspace.groupId));
            }
        } else {
            toast.error('ワークスペースの削除に失敗しました');
        }
    };

    return (
        <GeneralModal
            isOpen={isOpen}
            submitButton={
                <Button color="danger" onClick={handleSubmit} disabled={!checked}>
                    削除 | Delete
                </Button>
            }
            cancelButton={
                <Button color="secondary" onClick={onClose}>
                    閉じる | Cancel
                </Button>
            }
            onClose={onClose}
            shouldCloseOnOverlayClick={false}
            title={'ワークスペースを削除しますか？'}
        >
            <div className="p-4" style={{ width: '600px' }}>
                <p className={'pb-4'}>
                    ワークスペースを削除すると、ワークスペース内のすべてのビューモデルにアクセスできなくなります。
                    <br />
                    削除したワークスペースを復元することはできません。
                </p>
                <label className="block">
                    <input
                        type="checkbox"
                        className="form-checkbox mr-1 size-6 border-brand"
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                    />
                    チェックしてください | Check to delete
                </label>
            </div>
        </GeneralModal>
    );
};
