import { useCallback, useMemo, useState } from 'react';
import { UserPublicProfile, useUserPublicProfile, useUserPublicProfiles } from '@user/PublicProfile';
import { Loading } from '@framework/ui/atoms/Loading';
import { WorkspaceMemberRole, WorkspaceMemberRoles } from '@workspace/domain/workspace';
import { RoleSelect } from './RoleSelect';
import { generateDefaultUserIcon } from '@framework/utils';
import { WorkspaceId } from '@schema-common/base';
import { WorkspaceOperation } from '@workspace/domain/WorkspaceOperation';
import { toast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

type Props = {
    workspaceId: WorkspaceId;
    currentUserId: string;
    memberRoles: WorkspaceMemberRoles;
};

export const MemberEditList: React.FC<Props> = ({ workspaceId, currentUserId, memberRoles }: Props) => {
    const userIds = useMemo(() => {
        return memberRoles.userIds().filter((id) => id !== currentUserId);
    }, [memberRoles, currentUserId]);
    const myProfile = useUserPublicProfile(currentUserId);
    const myRole = memberRoles.getRoleOf(currentUserId);
    const profiles = useUserPublicProfiles(userIds);

    if (!myProfile || !myRole || !profiles) return <Loading />;

    return (
        <ul className="px-2">
            <MemberItem workspaceId={workspaceId} editable={false} profile={myProfile} role={myRole} />

            {profiles.map((profile) => {
                const role = memberRoles.getRoleOf(profile.id);
                if (!role) return null;

                return (
                    <MemberItem
                        key={profile.id}
                        workspaceId={workspaceId}
                        editable={true}
                        profile={profile}
                        role={role}
                    />
                );
            })}
        </ul>
    );
};

type ItemProps = {
    workspaceId: WorkspaceId;
    profile: UserPublicProfile;
    role: WorkspaceMemberRole;
    editable: boolean;
};

const MemberItem: React.FC<ItemProps> = ({ workspaceId, profile, role, editable }: ItemProps) => {
    const [saved, setSaved] = useState(false);

    const handleChange = useCallback(
        async (role: WorkspaceMemberRole) => {
            if (await WorkspaceOperation.updateMemberRole(workspaceId, profile.id, role)) {
                setSaved(true);
                setTimeout(() => setSaved(false), 3000);
            } else {
                toast.error('ロールの変更に失敗しました');
            }
        },
        [profile.id, workspaceId]
    );

    return (
        <li className="flex flex-row items-center px-3 py-2 hover:bg-gray-200">
            <span className="flex size-6 overflow-hidden rounded-full">
                {profile.imageUrl ? (
                    <img className="size-full object-cover" src={profile.imageUrl} alt={profile.name} />
                ) : (
                    <div className="size-full object-cover">
                        {generateDefaultUserIcon({
                            id: profile.id,
                            name: profile.name,
                            size: 24,
                        })}
                    </div>
                )}
            </span>
            <span className="flex h-6 grow truncate px-2 leading-6">{profile.name}</span>

            {editable ? (
                <div className="w-30 text-right">
                    {saved && (
                        <span className="mr-2 font-bold text-green-700">
                            <FontAwesomeIcon icon={faCheck} />
                        </span>
                    )}
                    <RoleSelect role={role} onChange={handleChange} disabled={false} />
                </div>
            ) : (
                <div className="w-30 text-center">{WorkspaceMemberRole.getLocalString(role)}</div>
            )}
        </li>
    );
};
