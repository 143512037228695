type Props = {
    title: string;
    subtitle?: string;
};

export const GroupConsoleTitle: React.FC<Props> = ({ title, subtitle }: Props) => {
    return (
        <h2 className="text-xl font-bold">
            {title}
            {subtitle && <span className="ml-2 text-sm">{subtitle}</span>}
        </h2>
    );
};
