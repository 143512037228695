import { memo, useCallback, useRef } from 'react';
import { Position } from '@view-model/models/common/types/ui';
import { DragContext, DraggableSVGGElement } from '@model-framework/ui';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';

const directionMapping: Record<string, IconDefinition> = {
    down: faArrowDown,
    left: faArrowLeft,
    right: faArrowRight,
    up: faArrowUp,
};

export type DirectionName = keyof typeof directionMapping;

type Props = {
    direction: DirectionName;
    position: Position;
    onClick(startPosition: Position): void;
    onDragStart(startPosition: Position): void;
    onDrag(currentPosition: Position): void;
    onDragEnd(currentPosition: Position): void;
    iconCircleSize: number;
    getGuardProps?: GetGuardProps;
};

const LinkCreatorIcon: React.FC<Props> = memo(
    ({ direction, position, onClick, onDragStart, onDrag, onDragEnd, iconCircleSize, getGuardProps }: Props) => {
        // ドラッグを完了させる最小移動距離
        // (これよりも短い距離の移動であれば、 LinkCreator をクリックしたのと同様の動きをする)
        const MIN_DISTANCE_TO_DRAG_END = 100;
        const currentPosition = useRef<Position>(position);

        const handleClick = useCallback(() => {
            onClick(position);
        }, [onClick, position]);

        const handleDragStart = useCallback(() => {
            onDragStart(position);
            currentPosition.current = position;
        }, [onDragStart, position]);

        const handleDrag = useCallback(
            ({ dx, dy }: DragContext) => {
                const { x, y } = currentPosition.current;
                currentPosition.current = {
                    x: x + dx,
                    y: y + dy,
                };
                onDrag(currentPosition.current);
            },
            [onDrag]
        );

        const handleDragEnd = useCallback(() => {
            const { x, y } = currentPosition.current;
            const { dx, dy } = {
                dx: position.x - x,
                dy: position.y - y,
            };
            const distance = Math.sqrt(dx ** 2 + dy ** 2);
            if (distance >= MIN_DISTANCE_TO_DRAG_END) {
                onDragEnd(currentPosition.current);
            }
        }, [onDragEnd, position]);

        return (
            <DraggableSVGGElement
                position={position}
                onDragMoveStarted={handleDragStart}
                onDrag={handleDrag}
                onDragMoveEnded={handleDragEnd}
            >
                <circle className="fill-white stroke-gray-600" r={iconCircleSize} cx={0} cy={0} strokeWidth={1} />
                <foreignObject
                    className="cursor-pointer text-center align-middle"
                    width={iconCircleSize * 2}
                    height={iconCircleSize * 2}
                    fontSize={iconCircleSize}
                    transform={`translate(${-iconCircleSize}, ${-iconCircleSize})`}
                    onClick={handleClick}
                    {...(getGuardProps ? getGuardProps() : undefined)}
                    style={{ lineHeight: `${iconCircleSize * 2}px` }}
                >
                    <FontAwesomeIcon icon={directionMapping[direction]} />
                </foreignObject>
            </DraggableSVGGElement>
        );
    }
);

LinkCreatorIcon.displayName = 'LinkCreatorIcon';
export { LinkCreatorIcon };
