import { StickyModelContentsOperation } from '@view-model/adapter';
import { toast } from 'react-hot-toast';
import { NullHandlers } from './NullHandlers';

export class ReadonlyHandlers extends NullHandlers {
    constructor(protected readonly operation: StickyModelContentsOperation) {
        super();
    }

    handleAllElementsSelect(): void {
        this.operation.selectAll();
    }

    handleAllElementsDeselect(): void {
        this.operation.deselectAll();
    }

    async handleCopySelectedElements(): Promise<void> {
        if (!this.operation.hasSelectedElements()) {
            return;
        }

        if (await this.operation.copySelectedElements()) {
            toast.success('コピーしました | Copied');
        } else {
            toast.error('コピーに失敗しました | Copy failed');
        }
    }
}
