import { useCallback, useState } from 'react';
import { Loading } from '@framework/ui/atoms/Loading';
import { GroupMemberInvitation, GroupMemberSignUpMethod } from '@group/domain';
import { LoginMethodMenu, LoginWithEmailFormContainer, ProviderName, useLoginWithOAuth } from '@user/Login';
import { SignUpMethodMenu } from './SignUpMethodMenu';

type Props = {
    invitation: GroupMemberInvitation;
    availableSignUpMethods: GroupMemberSignUpMethod;
};

/**
 * 未ログイン状態のユーザ向けの、グループメンバーへの招待確認メニュー
 * @returns
 */
export const UnauthenticatedMenu: React.FC<Props> = ({ availableSignUpMethods, invitation }: Props) => {
    const [processing, setProcessing] = useState(false);
    const loginWithOAuth = useLoginWithOAuth('login');

    const handleLoginWithOAuth = useCallback(
        (providerName: ProviderName) => {
            setProcessing(true);
            loginWithOAuth(providerName);
        },
        [loginWithOAuth]
    );

    const handleLoginWithGoogle = useCallback(() => handleLoginWithOAuth('google'), [handleLoginWithOAuth]);
    const handleLoginWithQuadcept = useCallback(() => handleLoginWithOAuth('quadcept'), [handleLoginWithOAuth]);
    const [showLoginWithEmailForm, setShowLoginWithEmailForm] = useState<boolean>(false);
    const handleLoginWithEmail = useCallback(() => setShowLoginWithEmailForm(true), []);
    const handleCloseLoginWithPassword = useCallback(() => setShowLoginWithEmailForm(false), []);

    const [showSignUpMenu, setShowSignUpMenu] = useState(true);
    const handleShowSignUpMenu = useCallback(() => setShowSignUpMenu(false), []);
    const handleCloseSignUpMenu = useCallback(() => setShowSignUpMenu(true), []);

    if (processing) {
        return (
            <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                <Loading message="ログインしています..." />
            </div>
        );
    }

    if (showLoginWithEmailForm) {
        return <LoginWithEmailFormContainer onClose={handleCloseLoginWithPassword} />;
    }

    if (showSignUpMenu) {
        return (
            <SignUpMethodMenu
                availableSignUpMethods={availableSignUpMethods}
                invitation={invitation}
                onLogin={handleShowSignUpMenu}
            />
        );
    }

    return (
        <>
            <div className="flex w-80 flex-col space-y-4 px-5 py-2">
                <div className="cursor-pointer font-bold text-brand hover:underline" onClick={handleCloseSignUpMenu}>
                    &lt; 戻る
                </div>
            </div>
            <LoginMethodMenu
                onLoginWithGoogle={handleLoginWithGoogle}
                onLoginWithQuadcept={handleLoginWithQuadcept}
                onLoginWithEmail={handleLoginWithEmail}
            />
        </>
    );
};
