import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ViewModelEntity } from '@view-model/domain/view-model';
import { Link, useParams } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { RouteParams } from '@user/pages/RouteParams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramProject, faGlobe, faStar, faPen, faLock } from '@fortawesome/free-solid-svg-icons';
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Workspace, WorkspaceSetting } from '@workspace/domain/workspace';
import { useSidebarTreeContext } from './SidebarTreeContext';
import { HighlightText } from './HighlightText';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuButton } from './SidebarMenuButton';
import { useCurrentUserId } from '@framework/auth';
import { useBookmark } from '@user/pages/ViewModelPage/hooks';
import { ViewModelDeleteConfirmModal, ViewModelSettingModal } from '@view-model/ui/components/ViewModel';
import { useViewModelCopy } from '@workspace/view-model-folder/hooks';
import { UserId } from '@schema-common/base';
import { useWorkspaceMemberRoles } from '@workspace/domain/workspace/useWorkspaceMemberRoles';

type Modals = 'ViewModelEdit' | 'ViewModelDelete';

const useHandleModals = () => {
    const [openModal, setOpenModal] = useState<Modals | null>(null);

    return {
        openModal,
        handleViewModelEditModalOpen: useCallback(() => {
            setOpenModal('ViewModelEdit');
        }, []),
        handleViewModelDeleteModalOpen: useCallback(() => {
            setOpenModal('ViewModelDelete');
        }, []),
        handleModalClose: useCallback(() => {
            setOpenModal(null);
        }, []),
    };
};

const useIsSidebarMenuButtonShowable = (workspace: Workspace, currentUserId: UserId | null) => {
    const roles = useWorkspaceMemberRoles(workspace.id);

    return useMemo(() => {
        if (!currentUserId)
            return {
                isMenuButtonShowable: false,
                isEditMenuShowable: false,
            };

        const currentUserMemberRole = roles?.getRoleOf(currentUserId);

        return {
            isMenuButtonShowable: !!(currentUserId && currentUserMemberRole),
            isEditMenuShowable: currentUserMemberRole === 'admin' || currentUserMemberRole === 'editor',
        };
    }, [currentUserId, roles]);
};

type Props = {
    viewModel: ViewModelEntity;
    workspaceSetting: WorkspaceSetting;
    shouldBeScrolled?: boolean;
    resetScrollViewModel?: () => void;
};

export const ViewModelItem: React.FC<Props> = ({
    viewModel,
    workspaceSetting,
    shouldBeScrolled = false,
    resetScrollViewModel = () => void 0,
}: Props) => {
    const currentUserId = useCurrentUserId();
    const { viewModelId: viewModelIdParam } = useParams<RouteParams>();
    const ref = useRef<HTMLDivElement | null>(null);
    const isSelected = useMemo(() => viewModel.id === viewModelIdParam, [viewModel.id, viewModelIdParam]);
    const { searchKeyword } = useSidebarTreeContext();
    const { isBookmarked, toggleBookmark } = useBookmark(viewModel.id);
    const {
        records: { workspaces },
    } = useSidebarTreeContext();
    const workspace = useMemo(() => workspaces[viewModel.workspaceId], [workspaces, viewModel.workspaceId]);

    const { openModal, handleViewModelEditModalOpen, handleViewModelDeleteModalOpen, handleModalClose } =
        useHandleModals();
    const { isMenuButtonShowable, isEditMenuShowable } = useIsSidebarMenuButtonShowable(workspace, currentUserId);

    const handleViewModelCopy = useViewModelCopy(viewModel);

    useEffect(() => {
        if (shouldBeScrolled && ref.current) {
            const rect = ref.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (rect.bottom > windowHeight || rect.top < 0) {
                ref.current.scrollIntoView();
            }
            resetScrollViewModel();
        }
    }, [ref, shouldBeScrolled, resetScrollViewModel]);

    return (
        <div className="relative" ref={ref}>
            <SidebarMenuItem
                isSelected={isSelected}
                isMenuButtonShowable={isMenuButtonShowable}
                popupMenu={
                    <>
                        <SidebarMenuButton
                            handleClick={toggleBookmark}
                            icon={faStar}
                            title={isBookmarked ? 'ブックマークを削除' : 'ブックマーク'}
                            iconClassName={isBookmarked ? 'text-yellow-400' : 'text-gray-400'}
                        />
                        {isEditMenuShowable && (
                            <>
                                <SidebarMenuButton
                                    handleClick={handleViewModelCopy}
                                    icon={faCopy}
                                    title={'ビューモデルをコピー'}
                                />
                                <SidebarMenuButton
                                    handleClick={handleViewModelEditModalOpen}
                                    icon={faPen}
                                    title={'編集'}
                                />
                                <SidebarMenuButton
                                    handleClick={handleViewModelDeleteModalOpen}
                                    icon={faTrashAlt}
                                    title={'削除'}
                                    className="text-red-700"
                                />
                            </>
                        )}
                    </>
                }
            >
                <Link to={UserPagePathBuilder.viewModelPage(viewModel.id)} className="flex min-w-0 items-center">
                    <span className="fa-layers fa-fw mr-1 shrink-0 text-brand">
                        {viewModel.isPublicReadable(workspaceSetting) ? (
                            <>
                                <FontAwesomeIcon icon={faDiagramProject} transform="shrink-2 top-3 left-1" />
                                <FontAwesomeIcon icon={faGlobe} transform="shrink-8 down-3 right-7" />
                            </>
                        ) : (
                            <FontAwesomeIcon icon={faDiagramProject} />
                        )}
                    </span>
                    {viewModel.isLocked && (
                        <span className="fa-layers fa-fw mr-1 shrink-0 text-brand">
                            <FontAwesomeIcon icon={faLock} />
                        </span>
                    )}
                    <HighlightText className="truncate" text={viewModel.name.toString()} keyword={searchKeyword} />
                </Link>
            </SidebarMenuItem>

            {openModal === 'ViewModelEdit' && (
                <ViewModelSettingModal
                    isOpen={openModal === 'ViewModelEdit'}
                    onClose={handleModalClose}
                    workspace={workspace}
                    viewModel={viewModel}
                />
            )}

            {openModal === 'ViewModelDelete' && (
                <ViewModelDeleteConfirmModal
                    viewModel={viewModel}
                    isOpen={openModal === 'ViewModelDelete'}
                    onClose={handleModalClose}
                />
            )}
        </div>
    );
};
