import { useState, useCallback } from 'react';
import { FlatButton } from '@framework/ui/atoms';
import { classNames } from '@framework/utils';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { Link } from 'react-router-dom';
import * as EmailValidator from 'email-validator';
import { FirebaseAuthenticationClient } from '@framework/firebase/auth';
import { FunctionsUserActions } from '@functions/FunctionsUserActions';

type Props = {
    setTemporaryId: (temporaryId: string) => void;
    onClose: () => void;
};

export const LoginWithEmailForm: React.FC<Props> = ({ setTemporaryId, onClose }: Props) => {
    const [mailAddress, setMailAddress] = useState('');
    const [mailAddressError, setMailAddressError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState('');
    const isSendable = mailAddress && password && mailAddressError === '' && passwordError === '';

    const handleChangeMailAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value;
        setMailAddress(email);
        if (EmailValidator.validate(email)) {
            setMailAddressError('');
        }
    }, []);
    const handleChangePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }, []);

    const handleBlurMailAddress = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        const email = event.target.value;

        if (EmailValidator.validate(email)) {
            setMailAddressError('');
        } else {
            setMailAddressError('メールアドレスを入力してください');
        }
    }, []);
    const handleBlurPassword = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '') {
            setPasswordError('パスワードを入力してください');
        } else {
            setPasswordError('');
        }
    }, []);

    const handleSubmit = useCallback(() => {
        if (mailAddress == '' || password == '') {
            return;
        }
        setProcessing(true);
        setError('');

        const onSuccess = (token: string) => {
            FirebaseAuthenticationClient.signInWithCustomToken(token).catch((error) => {
                // Firebase Authentication のログイン処理に失敗した時には、
                // processing を false に戻して、再試行可能にする。
                setProcessing(false);
                setError('システムエラーにより、ログインに失敗しました。もう一度やり直してください。');
                throw error;
            });
        };
        const onMultiFactorAuth = (temporaryId: string) => {
            setTemporaryId(temporaryId);
        };
        const onFailed = () => {
            setProcessing(false);
            setError('ログインに失敗しました。メールアドレスとパスワードに誤りが無いか、もう一度確認してください。');
        };

        FunctionsUserActions.loginWithEmailAndPassword(mailAddress, password, onSuccess, onMultiFactorAuth, onFailed)
            .catch((err) => {
                // Cloud Functions の呼び出しで例外が発生した場合
                console.error(err);
                setError('システムエラーにより、ログインに失敗しました。しばらく時間を置いてから再度お試しください。');
            })
            .finally(() => {
                setProcessing(false);
            });
    }, [mailAddress, password, setTemporaryId]);

    return (
        <>
            <div className="flex w-80 flex-col px-5 py-2">
                <div className="cursor-pointer font-bold text-brand hover:underline" onClick={onClose}>
                    &lt; 戻る
                </div>

                <div className="pb-2 pt-4 font-bold">メールアドレス</div>
                <input
                    type="text"
                    value={mailAddress}
                    onChange={handleChangeMailAddress}
                    onBlur={handleBlurMailAddress}
                    className={classNames('w-full rounded-sm border p-2', mailAddressError ? 'border-red-700' : '')}
                    autoFocus
                    disabled={processing}
                />
                {mailAddressError && <div className="text-sm text-red-700">{mailAddressError}</div>}

                <div className="pb-2 pt-4 font-bold">パスワード</div>
                <input
                    type="password"
                    value={password}
                    onChange={handleChangePassword}
                    onBlur={handleBlurPassword}
                    className={classNames('w-full rounded-sm border p-2', passwordError ? 'border-red-700' : '')}
                    disabled={processing}
                />
                {passwordError && <div className="text-sm text-red-700">{passwordError}</div>}

                {error && <div className="pt-4 text-sm text-red-700">{error}</div>}

                <div className="pt-4" />
                <FlatButton
                    color="brand"
                    onClick={handleSubmit}
                    disabled={!isSendable || processing}
                    loading={processing}
                >
                    ログインする
                </FlatButton>
            </div>

            <div className="mt-8">
                ※ ログインに一定回数失敗するとアカウントがロックされますのでご注意ください。
                <br />※ アカウントがロックされた場合は「
                <Link to={UserPagePathBuilder.passwordResetPage()} className="text-sm text-blue-700 hover:underline">
                    パスワードの再設定
                </Link>
                」を行なっていください。
            </div>
        </>
    );
};
