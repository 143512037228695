import { GroupEntity, GroupMemberRole } from '@group/domain';
import { GroupMemberInvitationBuilder } from '@group/domain/GroupMemberInvitation/GroupMemberInvitationBuilder';
import { UserEntity } from '@user/UserEntity';
import { RefBuilder } from '@framework/repository';
import { getRealtimeDatabaseCurrentTimestamp } from '@framework/firebase/rtdb';
import { SelectedWorkspaceItem } from './InvitePageWorkspaceList';
import { WorkspaceMemberRole } from '@workspace/domain/workspace';
import { WorkspaceMemberRoleJSON } from '@schema-common/workspace';
import { WorkspaceId } from '@schema-common/base';

export const MAX_INVITATION_COUNT = 100;

export const createGroupMemberInvitations = async (
    group: GroupEntity,
    sender: UserEntity,
    emails: string[],
    memberRole: GroupMemberRole,
    workspaces: SelectedWorkspaceItem[],
    message: string
): Promise<boolean> => {
    if (emails.length < 1 || emails.length > MAX_INVITATION_COUNT) {
        return false;
    }

    const now = await getRealtimeDatabaseCurrentTimestamp();
    const workspaceJSONs = convertWorkspaces(workspaces);

    const builder = new GroupMemberInvitationBuilder(group, sender, emails, memberRole, workspaceJSONs, message);
    const update = builder.buildUpdates(now);

    return new Promise((resolve) => {
        RefBuilder.ref().update(update, (err) => {
            if (err) console.error(err);
            resolve(!err);
        });
    });
};

const convertWorkspaces = (
    workspaces: SelectedWorkspaceItem[]
): Record<WorkspaceId, WorkspaceMemberRoleJSON> | null => {
    const result: Record<WorkspaceId, WorkspaceMemberRoleJSON> = {};
    workspaces.forEach((item) => {
        result[item.workspaceId] = WorkspaceMemberRole.dump(item.role) as WorkspaceMemberRoleJSON;
    });
    return Object.keys(result).length ? result : null;
};
