import { WorkspaceMemberRole } from '@workspace/domain/workspace';
import { useCallback } from 'react';

type Props = {
    value: WorkspaceMemberRole;
    onChange(role: WorkspaceMemberRole): void;
    disabled: boolean;
};

export const WorkspaceRoleSelect: React.FC<Props> = ({ value, onChange, disabled }: Props) => {
    const handleChangeRole = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(WorkspaceMemberRole.load(event.target.value));
        },
        [onChange]
    );

    return (
        <div>
            <select
                className="form-select m-1 py-1 text-sm"
                value={value.toString()}
                onChange={handleChangeRole}
                disabled={disabled}
            >
                {WorkspaceMemberRole.values().map((role) => (
                    <option key={role} value={role}>
                        {WorkspaceMemberRole.getLocalString(role)}
                    </option>
                ))}
            </select>
        </div>
    );
};
