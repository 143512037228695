import { Timestamp } from '@framework/Timestamp';
import { UserEntityJSON } from '@schema-app/user/entities/{userId}/UserEntityJSON';

export class UserEntity {
    constructor(
        readonly id: string,
        readonly jijiId: string | null,
        readonly name: string,
        readonly imageUrl: string | null,
        readonly createdAt: Timestamp,
        readonly updatedAt: Timestamp,
        readonly mailAddress: string,
        readonly hasPassword: boolean
    ) {}

    dump(): UserEntityJSON {
        const { id, jijiId, name, imageUrl, createdAt, updatedAt, mailAddress, hasPassword } = this;
        return {
            id,
            // jijiId が null の場合には、 jijiId のキー自体を含めない
            ...(jijiId ? { jijiId } : {}),
            name,
            imageUrl: imageUrl || null,
            createdAt: createdAt.toISOString(),
            updatedAt: updatedAt.toISOString(),
            mailAddress,
            hasPassword,
        };
    }

    static load(dump: UserEntityJSON): UserEntity {
        const { id, jijiId, name, imageUrl, createdAt, updatedAt, mailAddress, hasPassword } = dump;
        return new this(
            id,
            jijiId || null,
            name,
            imageUrl || null,
            Timestamp.fromISOString(createdAt),
            Timestamp.fromISOString(updatedAt),
            mailAddress,
            hasPassword || false
        );
    }

    static compare(a: UserEntity, b: UserEntity): number {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }

    static compareById(a: UserEntity, b: UserEntity): number {
        return a.id.localeCompare(b.id);
    }

    match(text: string): boolean {
        return [this.id, this.jijiId || '', this.name, this.mailAddress].some((value) =>
            value.toLocaleLowerCase().includes(text)
        );
    }

    isLeviiDomain(): boolean {
        return this.mailAddress.endsWith('@levii.co.jp');
    }

    static AdminConsoleUser = new UserEntity(
        'system-user',
        null,
        'Balusシステム管理者',
        'https://storage.googleapis.com/levii-balus-app-public-contents/noimage.png',
        new Timestamp('2024-01-01T00:00:00Z'),
        new Timestamp('2024-01-01T00:00:00Z'),
        'balus-support@levii.co.jp',
        false
    );
}
