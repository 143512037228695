import { GroupMemberSignUpMethod } from '@group/domain/GroupEntity/GroupMemberSignUpMethod';
import { SignUpMethodMenu } from '../GroupMemberInvitationPage/UnauthenticatedMenu/SignUpMethodMenu';
import { SimpleFooterLayout } from '@user/layouts/SimpleFooterLayout';
import BalusLogo from '@assets/balus-logo-brand-horizontal.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { UserPagePathBuilder } from '../UserPagePathBuilder';
import { useLoginErrorMessage } from '@user/Login';

export const Entry: React.FC<unknown> = () => {
    const availableSignUpMethods = GroupMemberSignUpMethod.buildNew();
    const history = useHistory();
    const loginErrorMessage = useLoginErrorMessage();

    const onLogin = () => {
        history.push(UserPagePathBuilder.loginPage());
    };

    return (
        <SimpleFooterLayout>
            <div className="flex w-screen flex-col items-center p-8">
                <img src={BalusLogo} className="w-64" />
                <div className="pb-4 pt-8 text-3xl font-bold">Balusにユーザ登録をする</div>
                <div className="pb-4 text-sm text-gray-700">
                    ユーザ登録・グループ作成は無料です。グループは2週間無料でご利用いただけます。
                </div>

                <div className="flex flex-col rounded-lg border border-gray-500 p-8" style={{ width: '600px' }}>
                    <div className="flex flex-col items-center py-4">
                        {loginErrorMessage && <div className="py-4 text-red-700">{loginErrorMessage}</div>}

                        <SignUpMethodMenu availableSignUpMethods={availableSignUpMethods} onLogin={onLogin} />

                        <div className="pt-8 text-sm text-gray-700">
                            続行することにより、Balus の
                            <a
                                href="https://levii.co.jp/legal/terms-of-service/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-700 underline hover:text-blue-800"
                            >
                                サービス利用規約 <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </a>
                            、
                            <a
                                href="https://levii.co.jp/legal/privacy-policy/"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-700 underline hover:text-blue-800"
                            >
                                プライバシーポリシー <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </a>
                            に同意したものとみなされます。
                        </div>
                    </div>
                </div>
            </div>
        </SimpleFooterLayout>
    );
};
