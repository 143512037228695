import { useEffect, useState } from 'react';
import { AuthSessionId, GroupId, GroupMemberInvitationId, UserId } from '@schema-common/base';
import { GroupMemberInvitation } from '@group/domain';
import { ObjectRepository, RTDBPath } from '@framework/repository';
import { GroupIpRestrictionCheckRequest } from '@group/AccessControl';
import { useCurrentUserId } from '@framework/auth';
import { useCurrentSessionId } from '@framework/auth/AuthContext';

type Result = {
    loading: boolean;
    invitation: GroupMemberInvitation | null;
    ipRestrictionBlocked: boolean;
};

const Loading: Result = { loading: true, invitation: null, ipRestrictionBlocked: false };
const NotFound: Result = { loading: false, invitation: null, ipRestrictionBlocked: false };
const IpRestrictionBlocked: Result = { loading: false, invitation: null, ipRestrictionBlocked: true };

type Cleanup = () => void;

const fetchGroupMemberInvitation = async (
    groupId: GroupId,
    invitationId: GroupMemberInvitationId,
    currentUserId: UserId | null,
    currentSessionId: AuthSessionId | null,
    setState: (state: Result) => void
): Promise<Cleanup> => {
    const cleanups: (() => void)[] = [];
    const cleanup = () => cleanups.forEach((func) => func());

    const repo = new ObjectRepository(
        GroupMemberInvitation,
        RTDBPath.Group.memberInvitationPath(groupId, invitationId)
    );

    // グループ単位のIPアドレス制限の可否チェックを行い、判定結果が出るまで待つ
    const ipRestrictionCheckResult = await GroupIpRestrictionCheckRequest.listen(
        groupId,
        currentUserId,
        currentSessionId,
        (status, prevStatus) => {
            if (prevStatus === 'Allow' && status === 'Deny') {
                // IPアドレス制限でブロックされた
                setState(IpRestrictionBlocked);
                return;
            }

            if (prevStatus === 'Deny' && status === 'Allow') {
                // アクセス禁止から許可に変化した時は、画面自体をリロードする
                window.location.reload();
            }
        },
        cleanups
    );

    if (ipRestrictionCheckResult === 'Deny') {
        // IPアドレス制限でブロックされた
        setState(IpRestrictionBlocked);
        return cleanup;
    }

    try {
        const invitation = await repo.get();
        setState({
            loading: false,
            ipRestrictionBlocked: false,
            invitation,
        });
    } catch {
        // permission denied の場合例外となりこのフローに来る(invitationIdが正しくないことやセキュリティルールに依存）。
        // permission 以外エラーも発生する可能性はあるが一律ここでキャッチして招待状が見つからなかったものとして処理を続行させる。
        setState(NotFound);
    }

    return cleanup;
};

export const useGroupMemberInvitation = (groupId: GroupId, invitationId: GroupMemberInvitationId): Result => {
    const [state, setState] = useState<Result>(Loading);

    const currentUserId = useCurrentUserId();
    const currentSessionId = useCurrentSessionId();

    useEffect(() => {
        let cleanup: null | (() => void) = null;

        fetchGroupMemberInvitation(groupId, invitationId, currentUserId, currentSessionId, setState).then(
            (f) => (cleanup = f)
        );

        return () => {
            cleanup?.();
        };
    }, [currentSessionId, currentUserId, groupId, invitationId]);

    return state;
};
