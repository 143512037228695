import { ViewBox } from '@view-model/domain/view-model';
import { ScaleExtent } from './domain/ScaleExtent';
import { MousePointerDetector } from './MousePointerDetector';
import { SVGZoomPanHandler, SVGZoomPanHandlerRef } from './SVGZoomPanHandler';
import { RefObject } from 'react';
import { GetGuardProps } from '@model-framework/drag-file/useDragFileGuard';

type Props = {
    children: React.ReactNode;
    svgZoomPanHandlerRef: RefObject<SVGZoomPanHandlerRef>;
    scaleExtent: ScaleExtent;
    viewBox: ViewBox;
    transition: string;
    deselectElements: () => void;
    onMousePointerDetected: (x: number, y: number) => void;
    getGuardProps: GetGuardProps;
};

export const GRID_AREA_SIZE = 204800;
const GRID_CELL_SIZE = 512;
const GRID_STROKE_WIDTH = 3;

export const GridArea: React.FC<Props> = (props: Props) => {
    const {
        children,
        svgZoomPanHandlerRef,
        scaleExtent,
        viewBox,
        transition,
        deselectElements,
        onMousePointerDetected,
        getGuardProps,
    } = props;

    return (
        <SVGZoomPanHandler
            ref={svgZoomPanHandlerRef}
            scaleExtent={scaleExtent}
            viewBox={viewBox}
            transition={transition}
        >
            <MousePointerDetector onMousePointerDetected={onMousePointerDetected}>
                <rect
                    className="cursor-grab fill-neutral-200 active:cursor-grabbing"
                    width={GRID_AREA_SIZE}
                    height={GRID_AREA_SIZE}
                    transform={`translate(${-GRID_AREA_SIZE / 2}, ${-GRID_AREA_SIZE / 2})`}
                    onClick={deselectElements}
                    {...getGuardProps()}
                />
                <GridPattern />
                {children}
            </MousePointerDetector>
        </SVGZoomPanHandler>
    );
};

/**
 * Grid（格子）の描画
 */
const GridPattern: React.FC<unknown> = () => {
    return (
        <g pointerEvents="none" opacity={1}>
            <defs>
                <pattern
                    id="small-grid"
                    patternUnits="userSpaceOnUse"
                    viewBox={`0 0 ${GRID_CELL_SIZE} ${GRID_CELL_SIZE}`}
                    x="0"
                    y="0"
                    width={GRID_CELL_SIZE}
                    height={GRID_CELL_SIZE}
                >
                    <rect width={GRID_CELL_SIZE} height={GRID_CELL_SIZE} style={{ fill: 'transparent' }} />
                    <line
                        className="stroke-gray-700"
                        x1="0"
                        y1="0"
                        x2={GRID_CELL_SIZE}
                        y2="0"
                        strokeWidth={GRID_STROKE_WIDTH}
                    />
                    <line
                        className="stroke-gray-700"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2={GRID_CELL_SIZE}
                        strokeWidth={GRID_STROKE_WIDTH}
                    />
                </pattern>
            </defs>
            <rect
                width={GRID_AREA_SIZE}
                height={GRID_AREA_SIZE}
                transform={`translate(${-GRID_AREA_SIZE / 2}, ${-GRID_AREA_SIZE / 2})`}
                style={{ fill: 'url(#small-grid)', stroke: 'none' }}
            />
            {/* Chrome最新版（89）でd3のズームが効かなくなった問題への対応（Chromeやd3の対応によって不要になるかも）
            d3 が rect 要素からのイベントを拾えなくなってしまったようなので透明なdivを置くことでd3がイベントを送れるようにする
            https://docs.google.com/document/d/1U2_GlDOYjCiogsfda6IXkkC3HsD_9CJSs7TXJx7yfpY/edit#heading=h.tnexj6jyzd9l
            */}
            <g transform={`translate(${-GRID_AREA_SIZE / 2}, ${-GRID_AREA_SIZE / 2})`}>
                <foreignObject width={GRID_AREA_SIZE} height={GRID_AREA_SIZE}>
                    <div className="size-full" />
                </foreignObject>
            </g>
        </g>
    );
};
