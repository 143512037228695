import { getClientId } from '@framework/app';
import { GetFunctionsClient } from '@framework/firebase';
import { DataSnapshot, RefBuilder, RTDBPath } from '@framework/repository';
import { AuthSessionId, ClientId, GroupId, UserId } from '@schema-common/base';

type IpRestrictionCheckRequest = {
    clientId: ClientId;
    sessionId: AuthSessionId | null;
    userId: UserId | null;
    // groupId または groupIds のいずれかが必要
} & ({ groupId: GroupId } | { groupIds: GroupId[] });

type Status = 'Allow' | 'Deny';

export class GroupIpRestrictionCheckRequest {
    /**
     * 指定されたグループIDに対するIPアドレス制限のアクセス可否の判定結果が Allow になるまで待つ。
     */
    static async waitAllow(groupId: GroupId, sessionId: AuthSessionId | null): Promise<void> {
        const clientId = getClientId();
        const ref = RefBuilder.ref(
            RTDBPath.AccessControl.groupIpRestrictionCheckRequestPath(groupId, sessionId, clientId)
        ).child('status');

        const snapshot = await ref.get();
        if ((snapshot.val() as Status) === 'Allow') {
            return;
        }

        return new Promise<void>((resolve) => {
            const onValueCallback = (snapshot: DataSnapshot) => {
                const status = snapshot.val() as Status;

                if (status === 'Allow') {
                    ref.off('value', onValueCallback);
                    resolve();
                }
            };

            ref.on('value', onValueCallback);
        });
    }

    /**
     * 1つのグループIDを指定して、対象グループに対するIPアドレス制限によるアクセス可否の判定を行い、継続的に監視を行う。
     * 既に判定済みの場合にはその値を返し、未判定の場合には判定用Cloud Functionsを呼び出す。
     */
    static async listen(
        groupId: GroupId,
        userId: UserId | null,
        sessionId: AuthSessionId | null,
        callback: (status: Status, prevStatus: Status | null) => void,
        cleanups: (() => void)[]
    ): Promise<Status> {
        const clientId = getClientId();
        const ref = RefBuilder.ref(
            RTDBPath.AccessControl.groupIpRestrictionCheckRequestPath(groupId, sessionId, clientId)
        );

        const snapshot = await ref.get();
        if (snapshot.val() === null) {
            // IPアドレス制限のアクセス可否判定の要求を登録する
            await GetFunctionsClient().httpsCallable('accessControl-ipRestrictionCheck')({
                groupId,
                clientId,
                sessionId,
                userId,
            } satisfies IpRestrictionCheckRequest);
        }

        const status = await new Promise<Status>((resolve) => {
            let firstCalled = false;
            let prevStatus: Status | null = null;

            const refStatus = ref.child('status');
            const onValueCallback = (snapshot: DataSnapshot) => {
                const status = snapshot.val() as Status;

                // 2回目以降のデータ変更時
                if (firstCalled) {
                    // IPアドレスによるアクセス可否の結果に差異が無ければ、何もしない
                    if (prevStatus === status) return;

                    callback(status, prevStatus);
                    prevStatus = status;
                    return;
                }

                if (status === null) {
                    return;
                }

                if (status === 'Allow' || status === 'Deny') {
                    firstCalled = true;
                    prevStatus = status;
                    resolve(status);
                }
            };

            refStatus.on('value', onValueCallback);
            cleanups.push(() => refStatus.off('value', onValueCallback));
        });

        return status;
    }

    /**
     * 複数のグループIDを引数に渡して、それらのグループに対するIPアドレス制限によるアクセス可否の判定結果を返す。
     */
    static async checkMany(
        groupIds: GroupId[],
        userId: UserId,
        sessionId: AuthSessionId
    ): Promise<Record<GroupId, Status>> {
        const clientId = getClientId();
        const results: Record<GroupId, Status> = {};

        // 既にRTDB上に保存されたIPアドレス制限のチェック結果があれば、それを取得する
        await Promise.all(
            groupIds.map(async (groupId) => {
                const ref = RefBuilder.ref(
                    RTDBPath.AccessControl.groupIpRestrictionCheckRequestPath(groupId, sessionId, clientId)
                );
                const status = (await ref.child('status').get()).val();

                if (status) {
                    results[groupId] = status;
                }
            })
        );

        // RTDB上にチェック結果が無いものについて、Cloud Functionsを呼び出して判定を行う
        const needCheckGroupIds = groupIds.filter((id) => !results[id]);
        if (needCheckGroupIds.length > 0) {
            const response = await GetFunctionsClient().httpsCallable('accessControl-ipRestrictionCheck')({
                groupIds: needCheckGroupIds,
                clientId,
                sessionId,
                userId,
            } satisfies IpRestrictionCheckRequest);

            Object.entries(response.data as Record<GroupId, Status>).forEach(([id, status]) => {
                results[id] = status;
            });
        }

        return results;
    }
}
