import React, { useState } from 'react';
import { Rect } from '../../../models/common/basic/Rect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MLAPIOperator } from '@view-model/application/ml-api/MLAPIOperator';
import { ViewEntity } from '@view-model/domain/view';
import { MLAPIPayload } from '@view-model/application/ml-api/MLAPIPayload';
import { toast } from 'react-hot-toast';
import { StickyZoneId } from '@schema-common/base';

type Props = {
    viewRect: Rect;
    aiVerificationMode: 'aiText' | 'aiViewModeling' | 'aiZoneComparison' | null;
    onChangeAiVerificationMode: (mode: 'aiText' | 'aiViewModeling' | 'aiZoneComparison' | null) => void;
    viewModelId: string;
    view: ViewEntity;
    zoneIds: StickyZoneId[];
    onSuccess: (result: MLAPIPayload) => void;
};

const getPlaceholder = (mode: Props['aiVerificationMode']) => {
    switch (mode) {
        case 'aiText':
            return `あなたは付箋を使ったディスカッションのファシリテーションをしています。あなたの役割は、優秀なファシリテーターとして参加者に気づきを与えることです。
以下のリストはとあるディスカッションにおいて付箋に書かれたトピックを列挙したものです。このディスカッションを抽象化し、200~300文字程度に要約した議事録を作成してください。`;
        case 'aiViewModeling':
            return `あなたは付箋を使ったディスカッションのファシリテーションをしています。
与えられた文章から重要なキーワードやフレーズを抜き出し、MermaidのGraph形式でその関係性を図示してください。`;
        default:
            return '';
    }
};

// ai_verification
// A機能検証用コンポーネント
export const AIVerificationInput: React.FC<Props> = ({
    viewRect,
    aiVerificationMode,
    onChangeAiVerificationMode,
    viewModelId,
    view,
    onSuccess,
    zoneIds,
}) => {
    const [prompt, setPrompt] = useState('');

    const isSendDisabled = prompt.trim() === '';

    const handleSend = async () => {
        if (isSendDisabled) return;
        toast('分析処理を開始しました | Started analysis');
        const result =
            aiVerificationMode === 'aiText'
                ? await MLAPIOperator.chatGPTText(viewModelId, view, prompt)
                : aiVerificationMode === 'aiViewModeling'
                  ? await MLAPIOperator.chatGPTModelingFromText(viewModelId, view, prompt)
                  : aiVerificationMode === 'aiZoneComparison' && zoneIds.length === 2
                    ? await MLAPIOperator.chatGPTZoneComparison(viewModelId, view, zoneIds, prompt)
                    : null;
        if (result) {
            onSuccess(result);
        }
    };

    if (!aiVerificationMode) return null;

    return (
        <foreignObject x={viewRect.getRight() + 50} y={viewRect.getTop()} width={1000} height={viewRect.height / 2}>
            <div className="flex h-full flex-col rounded-lg border-4 border-brand bg-white p-4 pt-8">
                <div className="flex items-center justify-between">
                    <div className="mb-2 text-6xl font-bold">
                        {aiVerificationMode === 'aiText' && 'テキスト生成'}
                        {aiVerificationMode === 'aiViewModeling' && 'モデリング'}
                        {aiVerificationMode === 'aiZoneComparison' && 'ゾーン比較'}
                    </div>
                    <button
                        onClick={() => {
                            onChangeAiVerificationMode(null);
                            setPrompt('');
                        }}
                        className="flex size-16 items-center justify-center rounded-full bg-gray-200 text-gray-700 hover:bg-gray-300"
                    >
                        <FontAwesomeIcon icon={faTimes} size="2x" />
                    </button>
                </div>
                <div className="text-4xl font-bold">プロンプト</div>
                <textarea
                    className="mb-4 flex-1 resize-none rounded border-4 border-black p-2 text-3xl"
                    placeholder={getPlaceholder(aiVerificationMode)}
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                />
                <button
                    onClick={handleSend}
                    className={`mt-4 rounded-md bg-blue-500 p-2 text-6xl text-white ${
                        isSendDisabled ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    disabled={isSendDisabled}
                >
                    送信
                </button>
            </div>
        </foreignObject>
    );
};
