import { useCallback } from 'react';
import { FolderId, ViewModelId } from '@schema-common/base';
import { Folder } from '../domain/Folder';
import { useCurrentUserId } from '@framework/auth';
import { Workspace } from '@workspace/domain/workspace';
import { useCreateFolderToRoot } from '@workspace/view-model-folder/hooks';
import { ViewModelClipboardPayload } from '@view-model/application/operation';
import { ViewModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ViewModelCascadeRepository';
import { ViewModelClipboardOperator } from '@view-model/application/clipboard';
import { toast } from 'react-hot-toast';
import { useActionLogSender } from '@framework/action-log';
import { ViewModelOperation } from '@view-model/domain/view-model';

type Props = {
    workspace: Workspace;
    beforeDuplicatingFunc?: (folderId: FolderId) => void;
    afterDuplicatingFunc?: (folderId: FolderId) => void;
};

export const useDuplicateFolder = ({ workspace, beforeDuplicatingFunc, afterDuplicatingFunc }: Props) => {
    const currentUserId = useCurrentUserId();
    const actionLogSender = useActionLogSender();
    const createFolderToRoot = useCreateFolderToRoot(workspace.id);

    const duplicateViewModelToFolder = useCallback(
        async (sourceViewModelId: ViewModelId, folderId: FolderId) => {
            if (!currentUserId) return;

            const start = new Date();
            // viewModelのコピー
            const repository = new ViewModelCascadeRepository(sourceViewModelId);
            const content = await repository.loadContent();
            if (!content) return new Error('複製元viewModelのcontent読み込みに失敗しました');

            const payload = ViewModelClipboardPayload.fromContent(content, workspace.id, sourceViewModelId);

            // 複製したフォルダの中に新しくビューモデルを作る
            const viewModelId = await ViewModelOperation.create(workspace.id, folderId, payload.name, () => void 0);

            // 作成したビューモデルに対して内容を貼り付ける
            const errorMessage = await ViewModelClipboardOperator.pasteWithoutCommand(
                workspace.groupId,
                viewModelId,
                currentUserId,
                payload
            );

            if (errorMessage) {
                toast.error(errorMessage);
            }

            // 行動ログを記録する
            actionLogSender('view_model:create_paste', {
                sourceViewModelId,
                viewModelId,
                viewModelName: payload.name,
                processingTimeSec: (new Date().getTime() - start.getTime()) / 1000.0,
            });
        },
        [actionLogSender, currentUserId, workspace.id, workspace.groupId]
    );

    return useCallback(
        async (folder: Folder, childViewModelIds: ViewModelId[]) => {
            const newFolder = await createFolderToRoot(`${folder.name}のコピー`);
            toast('フォルダを複製中です...', { id: newFolder.id, duration: Infinity });
            beforeDuplicatingFunc && beforeDuplicatingFunc(newFolder.id);
            try {
                await Promise.all(
                    childViewModelIds.map(async (viewModelId) => duplicateViewModelToFolder(viewModelId, newFolder.id))
                );
                actionLogSender('view_model_folder:duplicate', {
                    folderId: newFolder.id,
                });
                toast.success('フォルダを複製しました | Duplicate Succeeded');
            } catch (error) {
                toast.error('フォルダの複製に失敗しました | Duplicate Failed');
                throw error;
            } finally {
                toast.dismiss(newFolder.id);
                afterDuplicatingFunc && afterDuplicatingFunc(newFolder.id);
            }
        },
        [createFolderToRoot, actionLogSender, duplicateViewModelToFolder, beforeDuplicatingFunc, afterDuplicatingFunc]
    );
};
