import { useCallback, useState } from 'react';
import { faCaretDown, faCaretRight, faFolder, faPen, faPlus, faPaste } from '@fortawesome/free-solid-svg-icons';
import { faCopy, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Workspace } from '@workspace/domain/workspace';
import { Folder } from '@workspace/view-model-folder/domain/Folder';
import { ViewModelItem } from './ViewModelItem';
import { useSidebarFolder } from './useSidebarFolder';
import { SidebarMenuItem } from './SidebarMenuItem';
import {
    useCreateViewModelFromClipboardPayloadHandler,
    useDeleteFolder,
    useDuplicateFolder,
    useUpdateFolder,
} from '@workspace/view-model-folder/hooks';
import { ViewModelCreateModal } from '@workspace/view-model-folder/ui/ViewModelCreateModal';
import { Link } from 'react-router-dom';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { useSidebarTreeContext } from './SidebarTreeContext';
import { HighlightText } from './HighlightText';
import { FolderEditModal } from '@workspace/view-model-folder/ui/FolderEditModal';
import { SidebarMenuButton } from './SidebarMenuButton';
import { FolderDeleteModal } from '@workspace/view-model-folder/ui/FolderDeleteModal';
import { useSidebarTreeWorkspaceFolderTree } from './useSidebarTreeWorkspaceFolderTree';
import { ViewModelOperation } from '@view-model/domain/view-model';
import { useActionLogSender } from '@framework/action-log';

type Modals = 'ViewModelCreate' | 'FolderEdit' | 'FolderDelete';

const useHandleModals = () => {
    const [openModal, setOpenModal] = useState<Modals | null>(null);

    return {
        openModal,
        handleViewModelCreateModalOpen: useCallback(() => {
            setOpenModal('ViewModelCreate');
        }, []),
        handleFolderEditModalOpen: useCallback(() => {
            setOpenModal('FolderEdit');
        }, []),
        handleFolderDeleteModalOpen: useCallback(() => {
            setOpenModal('FolderDelete');
        }, []),
        handleModalClose: useCallback(() => {
            setOpenModal(null);
        }, []),
    };
};

type Props = {
    workspace: Workspace;
    folder: Folder;
    isMenuButtonShowable: boolean;
};

export const FolderItem: React.FC<Props> = ({ workspace, folder, isMenuButtonShowable }: Props) => {
    const actionLogSender = useActionLogSender();
    const { isOpen, toggleFolder, sortedViewModels, scrolledViewModelId, resetScrollViewModel } = useSidebarFolder(
        workspace.id,
        folder.id
    );

    const updateFolder = useUpdateFolder(folder);
    const deleteFolder = useDeleteFolder(workspace.id, folder);

    const {
        openModal,
        handleViewModelCreateModalOpen,
        handleFolderEditModalOpen,
        handleFolderDeleteModalOpen,
        handleModalClose,
    } = useHandleModals();

    const folderTree = useSidebarTreeWorkspaceFolderTree(workspace.id);
    const duplicateFolder = useDuplicateFolder({
        workspace,
    });
    const handleDuplicateFolder = useCallback(() => {
        duplicateFolder(folder, folderTree.getViewModelIdsOf(folder.id));
    }, [folder, folderTree, duplicateFolder]);

    const { searchKeyword } = useSidebarTreeContext();

    const handleFolderSubmit = useCallback(
        async (name: string) => {
            await ViewModelOperation.create(workspace.id, folder.id, name, actionLogSender);
            handleModalClose();
        },
        [workspace.id, folder.id, actionLogSender, handleModalClose]
    );

    const createViewModelFromClipboardPayload = useCreateViewModelFromClipboardPayloadHandler(
        workspace.groupId,
        workspace.id,
        folder.id
    );

    return (
        <div className="relative">
            <div className="flex items-center">
                <button className="mr-1 focus:outline-none" onClick={toggleFolder}>
                    <FontAwesomeIcon icon={isOpen ? faCaretDown : faCaretRight} className="size-4" />
                </button>
                <SidebarMenuItem
                    isSelected={false}
                    isMenuButtonShowable={isMenuButtonShowable}
                    popupMenu={
                        <>
                            <SidebarMenuButton
                                handleClick={handleViewModelCreateModalOpen}
                                icon={faPlus}
                                title={'新規ビューモデル'}
                            />
                            <SidebarMenuButton
                                handleClick={handleDuplicateFolder}
                                icon={faCopy}
                                title={'フォルダを複製'}
                            />
                            <SidebarMenuButton
                                handleClick={createViewModelFromClipboardPayload}
                                icon={faPaste}
                                title={'ビューモデルをペースト'}
                            />
                            <SidebarMenuButton handleClick={handleFolderEditModalOpen} icon={faPen} title={'編集'} />
                            <SidebarMenuButton
                                handleClick={handleFolderDeleteModalOpen}
                                icon={faTrashAlt}
                                title={'削除'}
                                className="text-red-700"
                            />
                        </>
                    }
                >
                    <Link
                        to={UserPagePathBuilder.workspacePage(workspace.id, folder.id)}
                        onClick={toggleFolder}
                        className="flex w-full items-center focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faFolder} className="mr-1 text-brand" />

                        <HighlightText className="truncate" text={folder.name} keyword={searchKeyword} />
                    </Link>
                </SidebarMenuItem>
            </div>

            {openModal === 'ViewModelCreate' && (
                <ViewModelCreateModal
                    isOpen={openModal === 'ViewModelCreate'}
                    onSubmit={handleFolderSubmit}
                    onClose={handleModalClose}
                />
            )}

            {openModal === 'FolderEdit' && (
                <FolderEditModal
                    folder={folder}
                    isOpen={openModal === 'FolderEdit'}
                    onSubmit={updateFolder}
                    onClose={handleModalClose}
                />
            )}

            {openModal === 'FolderDelete' && (
                <FolderDeleteModal
                    folder={folder}
                    isOpen={openModal === 'FolderDelete'}
                    onClose={handleModalClose}
                    onSubmit={deleteFolder}
                    hasChildren={folderTree.hasChildrenOf(folder.id)}
                />
            )}

            {isOpen && (
                <div className="ml-4">
                    {sortedViewModels.map((viewModel) => (
                        <ViewModelItem
                            key={viewModel.id}
                            viewModel={viewModel}
                            workspaceSetting={workspace.setting}
                            shouldBeScrolled={scrolledViewModelId === viewModel.id}
                            resetScrollViewModel={resetScrollViewModel}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};
