import { memo, useCallback, useMemo } from 'react';
import { DescriptionPanel, DescriptionPanelEditLockKey } from '../domain';
import { DescriptionPanelId, ModelId, ViewModelId } from '@schema-common/base';
import { Point, Size } from '@view-model/models/common/basic';
import { DraggableContainer } from '@view-model/models/common/components/DraggableContainer';
import { DraggableHeader } from './DraggableHeader';
import { RTDBPath, useObjectRepository, usePersistedObject } from '@framework/repository';
import { DescriptionContent } from './DescriptionContent';
import { DescriptionPanelResizer } from './DescriptionPanelResizer';
import { useResizeCommand } from '@view-model/models/common/components/RectResizer';
import { useExclusiveTextEditing } from '@model-framework/text/editing-user';
import { EditingUserIconView } from '@model-framework/text';
import { useTextEditingCommand } from '@model-framework/command';
import { useDescriptionPanelFontSizeCommand } from '../hook';
import { DragContext } from '@model-framework/ui';

const MemoizedDescriptionContent = memo(DescriptionContent);
const MemoizedDescriptionPanelResizer = memo(DescriptionPanelResizer);

const DraggableHeaderHeight = 32;

type Props = {
    viewModelId: ViewModelId;
    modelId: ModelId;
    id: DescriptionPanelId;
    position: Point;
    isSelected: boolean;
    readonly: boolean;
    onClick(id: DescriptionPanelId): void;
    onDragStart(id: DescriptionPanelId): void;
    onDrag(context: DragContext): void;
    onDragEnd(context: DragContext): void;
};

export const DescriptionPanelView = memo(
    ({ viewModelId, modelId, id, position, isSelected, readonly, onClick, onDragStart, onDrag, onDragEnd }: Props) => {
        const lineStrokeWidth = 6; // リサイズ用の枠線の太さ
        const path = RTDBPath.DescriptionPanel;

        const sizeRepository = useObjectRepository(Size, path.sizePath(viewModelId, modelId, id));
        const [size] = usePersistedObject(Size, path.sizePath(viewModelId, modelId, id));
        const positionRepository = useObjectRepository(Point, path.positionPath(viewModelId, modelId, id));
        const { onResizeConfirmed } = useResizeCommand(sizeRepository, positionRepository, readonly);
        const { fontSize, onFontSizeChange } = useDescriptionPanelFontSizeCommand(
            path.fontSizePath(viewModelId, modelId, id)
        );

        const handleClick = useCallback(() => onClick(id), [onClick, id]);
        const handleDragStart = useCallback(() => onDragStart(id), [onDragStart, id]);

        const { content, onStartEdit, onEdit, onEndEdit } = useTextEditingCommand(
            path.descriptionPath(viewModelId, modelId, id)
        );
        const editLockKey = useMemo(() => DescriptionPanelEditLockKey.fromId(id), [id]);
        const { editing, startEdit, endEdit, currentEditingUser } = useExclusiveTextEditing(
            viewModelId,
            editLockKey,
            content,
            readonly
        );
        const editingUserIconPosition = useMemo(() => new Point(0, size?.height || 0), [size?.height]);

        // 空文字はfalseなのでcontentだけ厳密にnullチェックする
        // sizeのheightとwidthがでないかチェックする
        if (!size || content === null || size.height == 0 || size.width == 0) return null;

        return (
            <DraggableContainer
                position={position}
                onClick={handleClick}
                onDragStart={handleDragStart}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                dragHandleContent={
                    // ドラッグハンドルは要素の選択時のみ表示する
                    isSelected ? (
                        // リサイズ用の枠線と、ヘッダーの縁が揃う様に、描画位置・横幅を調整する
                        <g transform={new Point(-lineStrokeWidth / 2, 0).toSVGTranslate()}>
                            <DraggableHeader
                                width={size.width + lineStrokeWidth}
                                height={DraggableHeaderHeight}
                                color="primary"
                            />
                        </g>
                    ) : null
                }
                hoveringDragHandleContent={
                    <DraggableHeader width={size.width} height={DraggableHeaderHeight} color="gray" />
                }
            >
                {/* ドロップシャドウのスタイルをタイトルバーやビュー領域と合わせるため、rectで背景を作成 */}
                <rect
                    width={size.width}
                    height={size.height}
                    fillOpacity="75%"
                    fill="white"
                    stroke="#e0e0e0"
                    strokeWidth="4"
                    rx="16"
                    ry="16"
                />

                <MemoizedDescriptionContent
                    content={content}
                    panelId={id}
                    editing={editing}
                    size={size}
                    fontSize={fontSize}
                    isSelected={isSelected}
                    readonly={readonly}
                    onClick={handleClick}
                    onStartEdit={onStartEdit}
                    onEdit={onEdit}
                    onEndEdit={onEndEdit}
                    onDblClick={startEdit}
                    onBlur={endEdit}
                    onFontSizeChange={onFontSizeChange}
                />

                {isSelected &&
                    (readonly ? (
                        <rect
                            width={size.width}
                            height={size.height}
                            fill="transparent"
                            rx="16"
                            ry="16"
                            className="stroke-brand stroke-[6px]"
                        />
                    ) : (
                        <MemoizedDescriptionPanelResizer
                            headerHeight={DraggableHeaderHeight}
                            size={size}
                            minSize={DescriptionPanel.MinimumSize}
                            lineStrokeWidth={lineStrokeWidth}
                            onResizeConfirmed={onResizeConfirmed}
                        />
                    ))}

                <EditingUserIconView
                    currentEditingUser={currentEditingUser}
                    position={editingUserIconPosition}
                    iconSize={48}
                />
            </DraggableContainer>
        );
    }
);
DescriptionPanelView.displayName = 'DescriptionPanelView';
