import { ModelCascadeRepository } from '@view-model/infrastructure/view-model/cascade/ModelCascadeRepository';
import { ViewCollection, ViewEntity } from '@view-model/domain/view';
import { ModelContentsCollection, StickyModelContents } from '@view-model/domain/model';
import { ViewsClipboardPayload } from './ViewsClipboardPayload';
import { PositionSet, PositionSetCreateCommand } from '@view-model/models/common/PositionSet';
import { GroupId, UserId, ViewId, ViewModelId } from '@schema-common/base';
import { Point } from '@view-model/models/common/basic';
import { CommandManager, CompositeCommand, CreateCommand } from '@view-model/models/framework/command';
import { ViewModelOperationLogSender } from '@view-model/models/framework/action-log';
import { ViewModelAssetCollectionForClone } from '@view-model/domain/view-model';

import { ObjectRepository, RTDBPath } from '@framework/repository';
import { ModelCreateCommand } from '@view-model/command/model/ModelCreateCommand';
import { ViewCollectionContents } from '@view-model/domain/view/ViewCollectionContents';
import { VIEW_GRID_SIZE, viewGridLayout } from '@view-model/ui/components/View/constants';
import { ConsistencyLink, ConsistencyLinkCollection } from '@view-model/domain/ConsistencyLink';
import { ClipboardUtils } from '@framework/ClipboardUtils';

export class ViewsClipboardOperator {
    static async copy(
        viewModelId: ViewModelId,
        views: ViewCollection,
        viewPositions: PositionSet,
        consistencyLinks: ConsistencyLinkCollection | null,
        logSender: ViewModelOperationLogSender
    ): Promise<boolean> {
        const modelCascadingRepository = new ModelCascadeRepository(viewModelId);

        const res = await Promise.all(views.map((v) => modelCascadingRepository.load(v.modelId)));
        const modelContents = res.filter((r): r is StickyModelContents => !!r);

        const validConsistencyLinks = consistencyLinks
            ? consistencyLinks.validLinksBy(views)
            : ConsistencyLinkCollection.buildEmpty();

        const payload = ViewsClipboardPayload.fromViewsAndModelContents({
            sourceViewModelId: viewModelId,
            views,
            viewPositions,
            modelContents: new ModelContentsCollection(modelContents),
            consistencyLinks: validConsistencyLinks,
        });

        try {
            await ClipboardUtils.copyWithCustomFormat({
                json: JSON.stringify(payload),
            });

            // 複数ビューコピーの時は、１つ目のビューのみログに残す
            // https://www.notion.so/levii/2674861813664ef299a2e8df8b2023db
            const view = views.map((v) => v)[0];
            logSender('view:copy', {
                clipboardPayloadId: payload.id,
                viewId: view.id,
                viewName: view.name.value,
            });

            return true;
        } catch (e) {
            console.warn('クリップボードへのコピーに失敗しました', e);
            return false;
        }
    }

    static async paste(
        payload: ViewsClipboardPayload,
        visibleAreaCenterPoint: Point,
        groupId: GroupId,
        viewModelId: ViewModelId,
        currentUserId: UserId,
        commandManager: CommandManager,
        logSender: ViewModelOperationLogSender
    ): Promise<[Set<ViewId>, string | null]> {
        const originalModelContentsCollection = ModelContentsCollection.load(payload.modelContents);
        // アセットの複製
        // 復元したペイロード中に含まれる ViewModelAssets の情報を取得して複製する
        const assets = await ViewModelAssetCollectionForClone.fetchByUrlList(
            originalModelContentsCollection.assetUrls()
        );
        const baseUrl = location.href;
        const [newAssetUrlMap, errorMessage] = await assets.cloneNew(groupId, viewModelId, currentUserId, baseUrl);

        // モデルの複製
        const modelCascadingRepository = new ModelCascadeRepository(viewModelId);
        const [newModelContentsCollection, newModelContentsKeyMap] =
            originalModelContentsCollection.cloneNew(newAssetUrlMap);

        // ビューの複製
        const [newViews, newViewKeyMap, newViewIdMap] = ViewCollection.load(payload.views).cloneNew(
            newModelContentsKeyMap
        );

        // ビューポジションの複製と移動
        const originalViewPositions = PositionSet.load(payload.viewPositions);
        const clonedViewPositions = originalViewPositions.cloneNew(newViewIdMap);
        const clonedViewsRect = new ViewCollectionContents(newViews, clonedViewPositions).getUnionRect();
        if (!clonedViewsRect) return [new Set(), 'ペーストするビューがありません'];

        const originalViewRectCenterPoint = clonedViewsRect.getCenterPoint();
        const subtraction = visibleAreaCenterPoint.subtract(originalViewRectCenterPoint);
        const movedViewPositions = clonedViewPositions
            .moveAll(subtraction.x, subtraction.y)
            .snapToLayout(viewGridLayout);

        // 貼り付けるビューの位置が全て元のビューの一と同じ場合は
        // ペーストしたビューが元のビューに完全に被ってしまうのでグリッド１つ分だけずらす
        const newViewPositions = originalViewPositions
            .all()
            .every(
                ([originalViewId, p]) =>
                    newViewIdMap[originalViewId] && movedViewPositions.find(newViewIdMap[originalViewId])?.isEqual(p)
            )
            ? movedViewPositions.moveAll(VIEW_GRID_SIZE, VIEW_GRID_SIZE).snapToLayout(viewGridLayout)
            : movedViewPositions;

        // 整合性リンクの複製
        const newConsistencyLinks = ConsistencyLinkCollection.load(payload.consistencyLinks).cloneNew(newViewKeyMap);

        const commands = newViews
            .map((newView) => {
                const newModelContents = newModelContentsCollection.list().find((c) => c.key.isEqual(newView.modelKey));
                if (!newModelContents) return null;

                const repository = new ObjectRepository(ViewEntity, RTDBPath.View.viewPath(viewModelId, newView.id));
                const viewCreateCommand = new CreateCommand(newView, repository);
                const modelCreateCommand = new ModelCreateCommand(modelCascadingRepository, newModelContents);

                return new CompositeCommand(modelCreateCommand, viewCreateCommand);
            })
            .filter((command): command is CompositeCommand => !!command);

        const newViewsPositionSetCreateCommand = new PositionSetCreateCommand(
            newViewPositions,
            RTDBPath.View.positionsPath(viewModelId)
        );

        const newConsistencyLinksCreateCommands = newConsistencyLinks.map((consistencyLink) => {
            const repository = new ObjectRepository(
                ConsistencyLink,
                RTDBPath.ConsistencyLink.linkPath(viewModelId, consistencyLink.id)
            );
            return new CreateCommand(consistencyLink, repository);
        });

        commandManager.execute(
            new CompositeCommand(...commands, newViewsPositionSetCreateCommand, ...newConsistencyLinksCreateCommands)
        );

        // 複数ビューのペーストの時は、１つ目のビューのみログに残す
        // https://www.notion.so/levii/2674861813664ef299a2e8df8b2023db
        const newView = newViews.map((v) => v)[0];
        logSender('view:paste', {
            clipboardPayloadId: payload.id,
            viewId: newView.id,
            viewName: newView.name.value,
        });

        return [new Set(Object.values(newViews.map((v) => v.id))), errorMessage];
    }
}
