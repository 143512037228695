import { GroupEntity, GroupMemberRole } from '@group/domain';
import { GroupConsoleTitle } from '../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@framework/ui/atoms/Button';
import { UserEntity } from '@user/UserEntity';
import { useCurrentSessionId, useCurrentUserId } from '@framework/auth/AuthContext';
import { KeysRepository, ObjectRepository, RTDBPath } from '@framework/repository';
import { Link, useHistory } from 'react-router-dom';
import { UserIcon } from '@framework/ui/atoms/UserIcon';
import { GroupId, UserId } from '@schema-common/base';
import { ItemMenuButton } from './ItemMenuButton';
import { GroupMemberRemoveModal } from './GroupMemberRemoveModal';
import { FullScreenLoading } from '@framework/ui';
import { UserPagePathBuilder } from '@user/pages/UserPagePathBuilder';
import { getMemberDataViaGroupConsoleUsing } from '../common/getMemberDataViaGroupConsoleUsing';
import { RoleSelect } from './RoleSelect';
import { useSnapshot } from '@framework/hooks';
import { GroupMemberRoleJSON } from '@schema-common/group';

type MemberItem = {
    memberData: UserEntity;
    role: GroupMemberRole;
    workspacesCount: number;
};

const getAssignedWorkspaceCount = async (memberId: UserId, groupId: GroupId): Promise<{ [key: UserId]: number }> => {
    const repo = new KeysRepository(RTDBPath.Workspace.assignedGroupWorkspaceIndexPath(memberId, groupId));
    const result = await repo.get();
    return { [memberId]: result.length };
};

type Props = {
    group: GroupEntity;
};

export const GroupConsoleMembersPage: React.FC<Props> = ({ group }: Props) => {
    const currentUserId = useCurrentUserId();
    const sessionId = useCurrentSessionId();
    const [keyword, setKeyword] = useState<string>('');
    const [allMemberItems, setAllMemberItems] = useState<MemberItem[]>([]);
    const [removableMemberData, setRemovableMemberData] = useState<UserEntity | null>(null);
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    const [groupMemberRoles] = useSnapshot<Record<UserId, GroupMemberRoleJSON>>({
        path: RTDBPath.Group.memberRolesPath(group.id),
        load: ({ snapshot }) => snapshot.val() || {},
    });

    useEffect(() => {
        if (!sessionId) return;

        const getMemberItems = async () => {
            const memberIds = Object.keys(groupMemberRoles || {});
            const userEntityRepos = memberIds.map(
                (id) => new ObjectRepository(UserEntity, RTDBPath.User.userEntityPath(id))
            );

            // UserEntityを取得
            const membersData = (
                await getMemberDataViaGroupConsoleUsing(group.id, sessionId, () =>
                    Promise.all(userEntityRepos.map((repo) => repo.get()))
                )
            ).filter((d) => !!d);

            // 所属ワークスペース数の取得
            const assignedWorkspaceCounts = (
                await Promise.all(memberIds.map((memberId) => getAssignedWorkspaceCount(memberId, group.id)))
            ).reduce((result, i) => {
                return {
                    ...result,
                    ...i,
                };
            }, {});

            const memberItems = memberIds
                .map((memberId) => {
                    return {
                        memberData: membersData.find((d) => d.id === memberId) || null,
                        role: groupMemberRoles?.[memberId] || null,
                        workspacesCount: assignedWorkspaceCounts[memberId] || 0,
                    };
                })
                .filter((item): item is MemberItem => !!item.memberData && !!item.role);

            return [
                ...memberItems
                    .filter(({ role }) => role === 'admin')
                    .sort((i, j) => i.memberData.name.localeCompare(j.memberData.name)),
                ...memberItems
                    .filter(({ role }) => role === 'member')
                    .sort((i, j) => i.memberData.name.localeCompare(j.memberData.name)),
                ...memberItems
                    .filter(({ role }) => role === 'guest')
                    .sort((i, j) => i.memberData.name.localeCompare(j.memberData.name)),
            ];
        };

        getMemberItems()
            .then((memberItems) => {
                setAllMemberItems(memberItems);
            })
            .finally(() => setLoading(false));
    }, [group, groupMemberRoles, sessionId]);

    const handleChangeKeyword = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
        if (!e.target.value) {
            setKeyword('');
        }
    }, []);

    const handleCloseGroupMemberRemoveModal = useCallback(() => {
        setRemovableMemberData(null);
    }, []);

    const memberItems = useMemo(() => {
        if (!keyword) return allMemberItems;

        return allMemberItems.filter(
            (i) =>
                i.memberData.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) ||
                i.memberData.mailAddress.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
        );
    }, [allMemberItems, keyword]);

    const handleClickAddMemberButton = useCallback(() => {
        history.push(UserPagePathBuilder.groupConsoleInvitePage(group.id));
    }, [history, group.id]);

    const guestCount = allMemberItems.filter(({ role }) => role === 'guest').length;

    if (loading) return <FullScreenLoading />;

    return (
        <>
            <div className="mb-4 flex items-center justify-between">
                <GroupConsoleTitle
                    title="メンバー"
                    subtitle={
                        allMemberItems.length
                            ? `(${allMemberItems.length}人${guestCount ? ` 内ゲスト${guestCount}人` : ''})`
                            : ''
                    }
                />
                <div className="relative flex w-3/5">
                    <div className="mr-4 flex min-w-0 flex-1">
                        <input
                            value={keyword}
                            onChange={handleChangeKeyword}
                            type="text"
                            className="min-w-0 flex-1 rounded-md border-2 border-gray-400 py-1 pr-2 placeholder:text-sm focus:outline-none"
                            placeholder="検索"
                            style={{ paddingInlineStart: '24px' }}
                        />
                    </div>
                    <Button color="brand" onClick={handleClickAddMemberButton}>
                        メンバーを追加する
                    </Button>
                    <FontAwesomeIcon
                        icon={faSearch}
                        className="absolute flex-none text-gray-600"
                        style={{ top: '12px', left: '6px' }}
                    />
                </div>
            </div>
            <div className="overflow-y-scroll pb-8" style={{ maxHeight: 'calc(100vh - 138px)' }}>
                <table className="w-full border-separate">
                    <thead className="sticky top-0 z-10 bg-white">
                        <tr className="text-left">
                            <th className="border-y-2 p-2">ユーザ</th>
                            <th className="border-y-2 p-2">ロール</th>
                            <th className="border-y-2 p-2">参加ワークスペース</th>
                            <th className="border-y-2 p-2" />
                        </tr>
                    </thead>
                    <tbody>
                        {memberItems.length <= 0 ? (
                            <tr>
                                <td>メンバーが見つかりません</td>
                            </tr>
                        ) : (
                            memberItems.map((i) => (
                                <tr key={i.memberData.id} className="border-b-2">
                                    <td className="border-b-2 border-gray-400 py-4 align-middle">
                                        <div className="flex items-center">
                                            <div className="mr-1 flex size-10 items-center justify-center">
                                                <UserIcon
                                                    name={i.memberData.name}
                                                    id={i.memberData.id}
                                                    imageUrl={i.memberData.imageUrl}
                                                    size="large"
                                                    className="grow-0"
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <Link
                                                    to={UserPagePathBuilder.groupConsoleMemberPage(
                                                        group.id,
                                                        i.memberData.id
                                                    )}
                                                    className="text-lg font-bold text-brand underline"
                                                >
                                                    {i.memberData.name}
                                                </Link>
                                                <span className="text-sm text-gray-600">
                                                    {i.memberData.mailAddress}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="border-b-2 border-gray-400 py-4 align-middle">
                                        {i.role === 'guest' ? (
                                            <span>ゲスト</span>
                                        ) : (
                                            <RoleSelect
                                                groupId={group.id}
                                                userId={i.memberData.id}
                                                defaultRole={i.role}
                                                disabled={currentUserId === i.memberData.id}
                                            />
                                        )}
                                    </td>
                                    <td className="border-b-2 border-gray-400 py-4 align-middle">
                                        {i.workspacesCount}
                                    </td>
                                    <td className="relative border-b-2 border-gray-400 py-4 align-middle">
                                        {/* 自分は削除できない */}
                                        <ItemMenuButton
                                            memberData={i.memberData}
                                            setRemovableMemberData={setRemovableMemberData}
                                            disabled={currentUserId === i.memberData.id}
                                        />
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {removableMemberData && (
                <GroupMemberRemoveModal
                    isOpen={!!removableMemberData}
                    onClose={handleCloseGroupMemberRemoveModal}
                    groupId={group.id}
                    selectedMemberData={removableMemberData}
                />
            )}
        </>
    );
};
